<template>
  <v-card class="d-flex flex-column">
    <v-card-title class="text-h5 text-lg-h4 justify-center flex-column pa-8">
      <v-row class="mx-auto text-center justify-center"
        ><span class="red--text pr-2"> OH NO! </span> Test Time up.</v-row
      >
      <v-row class="justify-center">
        Press
        <div class="accent--text px-2">Continue</div>
        to end the test.
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row align="center" justify="center">
        <v-col cols="6" class="d-flex justify-center">
          <v-img
            max-width="400px"
            height="400px"
            :src="require('../../assets/images/TestTimeOver.png')"
          >
          </v-img>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-center flex-end pb-12">
      <v-btn
        class="text-none px-3 py-2 text-body-1"
        color="primary"
        @click="continueToTestEnd"
      >
        Continue
        <v-icon right>
          {{ mdiChevronRight }}
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mdiChevronRight } from "@mdi/js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      mdiChevronRight,
    };
  },
  methods: {
    ...mapMutations("candidate", ["setTestEndedLocally"]),
    continueToTestEnd() {
      // set test end state
      this.setTestEndedLocally(true);
    },
  },
};
</script>
