import firebase from "firebase/app";
import "firebase/auth";
import user from "codedrills_proto/io/codedrills/proto/user/user_service_grpc_web_pb";
import team from "codedrills_proto/io/codedrills/proto/user/team_service_grpc_web_pb";
import { ApiCall } from "@/utils/api.js";
const userService = new user.UserServicePromiseClient(process.env.VUE_APP_USER_API_URL + "/user", null, null);
const teamService = new team.TeamServicePromiseClient(process.env.VUE_APP_USER_API_URL + "/user", null, null);
var user_proto = proto.io.codedrills.proto.user;
var content_proto = proto.io.codedrills.proto.content;


const state = {
	user: null,
	loginError: null,
	userToken: null,
	team: null,
	teams: null,
	userContestTeams: null,
	deleteTeamStatus: null,
	teamView: null,
	teamAcceptStatus: null,
	createTeamStatus: 0,
	lastTokenRrefreshEpochMs: null,
	profileImageUrl: null,
	verifyCpProfileStatus: null,
	initialized: false,
	userPreference: new proto.io.codedrills.proto.user.Preference()
		.setEditorConfig(new proto.io.codedrills.proto.user.EditorConfig().setLanguage(proto.io.codedrills.proto.judge.CPP_17)),
	dashboardView: null,
	profile: null,
	publicProfile: null,
	userActivity: null,
	ssoPayload: null,
	ssoSig: null,
	subscriptionToken: null,
	userMailSubscriptions: null,
	getUserPreferenceStatus: 0,
	saveUserPreferenceStatus: 0,
	getDashboardStatus: 0,
	getProfileStatus: 0,
	getPublicProfileStatus: 0,
	updateUserStatus: 0,
	fetchUserActivityStatus: 0,
	setTrackingStatusStatus: 0,
	validateSSOStatus: 0,
	editProfileStatus: 0,
	getTeamStatus: 0,
	getTeamsStatus: 0,
	updateTeamInviteStatus: 0,
	getUserContentTeamsStatus: 0,
	registerUserStatus: 0,
	updateMailSubscriptionsStatus: 0,
	getMailSubscriptionsStatus: 0,
};

const getters = {
	user: (state) => {
		return state.user;
	},
	loginError: (state) => {
		return state.loginError;
	},
	userToken: (state) => {
		return state.userToken;
	},
	lastTokenRrefreshEpochMs: (state) => {
		return state.lastTokenRrefreshEpochMs;
	},
	initialized: (state) => {
		return state.initialized;
	},
	subscriptionKeys() {
		return user_proto.SubscribeGroup;
	},
	languageKeys: () => {
		var ret = {};
		for (var key in proto.io.codedrills.proto.judge.Language) {
			ret[proto.io.codedrills.proto.judge.Language[key]] = key;
		}
		return ret;
	},
	site(state, getters) {
		return user_proto.CompetitiveSite;
		var ret = {}
		for (var s in getters.siteKeys) {
			ret[s] = user_proto.CompetitiveSite[s];
		}

		return ret;
	},
	siteKeys() {
		return Object.keys(user_proto.CompetitiveSite);
		var keys = ['CODEFORCES', 'CODECHEF', 'SPOJ']
		console.log("Keys are ....", keys)
		return keys;
	},
	userId: (state) => state.user && state.user.uid,
	teamMembership(state, getters) {
		return user_proto.MembershipState;
	},
	teamMembershipKeys() {
		return Object.keys(user_proto.MembershipState);
	},
	memberRoleKeys() {
		return Object.keys(user_proto.MemberRole);
	}
};

const actions = {
	async logout({ commit, state }) {
		return firebase.auth().signOut();
	},
	async fetchUserToken({ commit, state }) {
		if (state.user) {
			//console.log("Fetching user token");
			return firebase.auth().currentUser.getIdToken().then(
				token => {
					// console.log("userToken", token);
					commit("setUserToken", token);
					return token;
				}
			);
		}
	},
	async ensureUserToken({ commit, dispatch, getters }) {
		//if (!state.userToken || state.lastTokenRrefreshEpochMs < Date.now() - 10 * 60 * 1000) {
			return dispatch("fetchUserToken");
		//} else {
			//return new Promise((resolve, __) => resolve(getters.userToken));
		//}
	},
	async optionalUserToken({ commit, dispatch, getters }) {
		if (!state.user) {
			return new Promise((resolve, __) => resolve(""));
		} else {
			return dispatch("ensureUserToken");
		}
	},
	async userLoggedIn({ commit, dispatch }, data) {
		commit("setUser", data.user);
		return dispatch("getUserPreference");
	},
	async reloadUser({ commit, dispatch }) {
		if (this.user) {
			return this.user.reload().then(__ => commit("setUser", this.user));
		} else {
			return new Promise((resolve, __) => resolve(null));
		}
	},
	userLoggedOut({ commit }) {
		commit("setUser", null);
		commit("setUserToken", null);
	},
	async sendVerificationEmail() {
		return firebase.auth().currentUser.sendEmailVerification();
	},


	register: new ApiCall("registerUser")
			.cached({
				cacheName: "registerUser",
				keyFn: (__, {state, getters}) => getters.userId + ":" + state.subscriptionToken,
				cachedMessage: proto.io.codedrills.proto.user.RegisterUserResponse,
				ttlInSeconds: 2 * 24 * 60 * 60,
			})
			.authRequired()
			.withServiceCall((r, h) => userService.registerUser(r, h))
			.withRequest(() => new user_proto.RegisterUserRequest()
					.setToken(state.subscriptionToken)
			)
			.onSuccess()
			.build(),

	verifyCpProfile: new ApiCall("verifyCpProfile")
		.authRequired()
		.withServiceCall((r, h) => userService.verifyCpProfile(r, h))
		.withRequest((site) => new proto.io.codedrills.proto.user.VerifyCpProfileRequest()
			.setCompetitiveSite(
				user_proto.CompetitiveSite[site]
			)
		)
		.onSuccess(({ commit }, res) => commit("setProfile", res.getProfileResponse()))
		.build(),

	getUserPreference: new ApiCall("getUserPreference")
	  .cached({
			cacheName: "userPreference",
			keyFn: (__, {getters}) => getters.userId,
			cachedMessage: proto.io.codedrills.proto.user.GetUpdateUserPreferenceResponse,
			ttlInSeconds: 6 * 60 * 60,
		})
		.authRequired()
		.withServiceCall((r, h) => userService.getUserPreference(r, h))
		.withRequest(() => new proto.io.codedrills.proto.user.GetUserPreferenceRequest())
		.onSuccess(({ commit }, res) => commit("setUserPreference", res.getPreference()))
		.build(),
	saveUserPreference: new ApiCall("saveUserPreference")
	  .invalidatingCache({
			cacheName: "userPreference",
			keyFn: (__, {getters}) => getters.userId,
		})
		.authRequired()
		.withServiceCall((r, h) => userService.updateUserPreference(r, h))
		.withRequest((_, __, { state }) => new proto.io.codedrills.proto.user.UpdateUserPreferenceRequest()
			.setPreference(state.userPreference)
		)
		.build(),
	getDashboard: new ApiCall("getDashboard")
		.authRequired()
		.withServiceCall((r, h) => userService.getDashboard(r, h))
		.withRequest(() => new proto.io.codedrills.proto.user.GetDashboardRequest())
		.onSuccess(({ commit }, res) => commit("setDashboardView", res.getDashboardView()))
		.build(),

	getProfile: new ApiCall("getProfile")
		.authRequired()
		.withServiceCall((r, h) => userService.getProfile(r, h))
		.withRequest(() => new proto.io.codedrills.proto.user.GetProfileRequest())
		.onSuccess(({ commit }, res) => commit("setProfile", res.getProfileResponse()))
		.build(),

	getPublicProfile: new ApiCall("getPublicProfile")
		.withServiceCall((r, h) => userService.getPublicProfile(r, h))
		.withRequest(({handle}) => new proto.io.codedrills.proto.user.GetPublicProfileRequest().setHandle(handle))
		.onSuccess(({ commit }, res) => {
			console.log("PP", res);
		    commit("setPublicProfile", res);
		    return res;
		})
		.build(),

	editProfile: new ApiCall("editProfile")
		.authRequired()
		.withServiceCall((r, h) => userService.editProfile(r, h))
		.withRequest(() => new proto.io.codedrills.proto.user.EditProfileRequest()
			.setId(state.profile.getId())
			.setName(state.profile.getName())
			.setHandle(state.profile.getHandle())
			.setPhoneNo(state.profile.getPhoneNo())
			.setProfile(state.profile.getProfile())
		)
		.onSuccess(({ commit }, res) => commit("setProfile", res.getProfileResponse()))
		.build(),

	uploadUserImage: new ApiCall("uploadUserImage")
		.authRequired()
		.withServiceCall((r, h) => userService.uploadUserImage(r, h))
		.withRequest(({ image }) => {
			// console.log('Data at API', image);
			var req = new user_proto.UploadUserImageRequest()
				.setData(image)
				.setType("image/png");
			return req;
		})
		.onSuccess(({commit}, res) => commit("setProfileImage", res.getImageUrl()))
		.build(),

	fetchUserActivity: new ApiCall("fetchUserActivity")
		.authRequired()
		.withServiceCall((r, h) => userService.getUserActivity(r, h))
		.withRequest(({ id }) => {
			var contentId = new content_proto.ContentId().setId(id);
			return new user_proto.GetUserActivityRequest()
				.setContentIdList([contentId])
		})
		.onSuccess(({ commit }, res) => {
			var list = res.getActivityList();
			if (list.length > 0) commit("setUserActivity", list[0]);
			else commit("setUserActivity", null);
		})
		.build(),

	setTrackingStatus: new ApiCall("setTrackingStatus")
		.authRequired()
		.withServiceCall((r, h) => userService.setTrackingStatus(r, h))
		.withRequest(({ id, isTracking }) => {
			return new user_proto.SetTrackingStatusRequest()
				.setContentId(new content_proto.ContentId().setId(id))
				.setIsTracking(isTracking)
		})
		.onSuccess(({ commit }, res) => commit("setUserActivity", res.getActivity()))
		.build(),

	validateSSO: new ApiCall("validateSSO")
		.authRequired()
		.withServiceCall((r, h) => userService.validateSSO(r, h))
		.withRequest(({ payload, sig }) => {
			return new user_proto.ValidateSSORequest()
				.setPayload(payload)
				.setSig(sig)
		})
		.build(),

	async checkSSO({ state, commit, dispatch, getters }) {
		if (state.ssoPayload) {
			console.log("sso in action", state.ssoPayload, state.ssoSig);
			dispatch("validateSSO", { "payload": state.ssoPayload, "sig": state.ssoSig })
				.then(res => window.location.href = res.getRedirectUrl());
			return;
		}
	},
	createTeam: new ApiCall("createTeam")
		.authRequired()
		.withServiceCall((r,h) => teamService.createTeam(r, h))
		.withRequest(({teamDetails}) => new user_proto.CreateTeamRequest()
			.setTeamDetails(teamDetails)
		)
		.onSuccess(({commit}, res) => commit("setTeam", res))
		.build(),

	updateTeamInvite: new ApiCall("updateTeamInvite")
		.authRequired()
		.withServiceCall((r, h) => teamService.updateTeamInvite(r, h))
		.withRequest(({ teamId, membershipState }) =>
		new user_proto.UpdateTeamInviteRequest()
			.setTeamId(teamId)
			.setMembershipState(membershipState)
		)
		.onSuccess(({ commit }, res) => {
			commit("setTeamView", res.getTeamView());
			commit("setTeamViewInTeamList", res.getTeamView());
		})
		.build(),
	getTeam: new ApiCall("getTeam")
		.withServiceCall((r, h) => teamService.getTeam(r, h))
		.withRequest(({ teamId }) =>
		new user_proto.GetTeamRequest()
			.setTeamId(teamId)
		)
		.onSuccess(({ commit }, res) => commit("setTeamView", res.getTeamView()))
		.build(),
	getTeams: new ApiCall("getTeams")
		.withServiceCall((r, h) => teamService.getTeams(r, h))
		.withRequest(({ id, paginationQuery}) =>
		new user_proto.GetTeamsRequest()
				.setContestId(
					new content_proto.ContentId()
					.setId(id)
				)
				.setPaginationQuery(
							new content_proto.PaginationQuery()
							.setItemsPerPage(paginationQuery.itemsPerPage)
							.setPageIndex(paginationQuery.page-1)
				)
		)
		.onSuccess(({ commit }, res) => commit("setTeams", res))
		.build(),
	getUserContestTeams: new ApiCall("getUserContentTeams")
		.withServiceCall((r, h) => teamService.getUserContentTeams(r, h))
		.withRequest(({contestId}) =>
			new user_proto.GetUserContentTeamsRequest()
				.setContestId(
					new content_proto.ContentId()
					.setId(contestId)
				)
		)
		.onSuccess(({commit}, res) => commit("setUserContestTeams", res))
		.build(),
	deleteTeam: new ApiCall("deleteTeam")
		.authRequired()
		.withServiceCall((r, h) => teamService.deleteTeam(r, h))
		.withRequest(({ teamId }) =>
		new user_proto.DeleteTeamRequest()
			.setTeamId(teamId)
		)
		.onSuccess(({commit}, res) => console.log("Delete team ", res))
		.build(),

	getMailSubscriptions: new ApiCall("getMailSubscriptions")
		.withServiceCall((r, h) => userService.getUserSubscriptionSettings(r, h))
		.withRequest(() =>
			new user_proto.GetSubscriptionSettingsRequest()
		)
		.onSuccess(({commit}, res) => {
			commit("setUserMailSubscriptions", res.getSubscriptionSettings())
			return res.getSubscriptionSettings();
		})
		.build(),

	updateMailSubscriptions: new ApiCall("updateMailSubscriptions")
		.authRequired()
		.withServiceCall((r, h) => userService.updateUserSubscriptionSettings(r, h))
		.withRequest(() =>
			new user_proto.UpdateSubscriptionSettingsRequest()
			.setSubscriptionSettings(state.userMailSubscriptions)
		)
		.onSuccess(({commit}, res) => {
			commit("setUserMailSubscriptions", res.getSubscriptionSettings());
			return res.getSubscriptionSettings();
		})
		.build(),
};

const mutations = {
	setUser(state, user) {
		state.user = JSON.parse(JSON.stringify(user)); //https://stackoverflow.com/questions/38365075/vuex-vuejs-do-not-mutate-vuex-store-state-outside-mutation-handlers
	},
	//fix this from API status
	deleteTeamStatus(state, res) {
		state.deleteTeamStatus = true;
	},
	setTeamView(state, res) {
		state.teamView = res;
		if(state.user) var member = state.teamView
                      .getMembersList()
                      .find(u => u.getEmail() === state.user.email);
		if(member) state.teamAcceptStatus = member.getMembershipState();
	},

	setTeamViewInTeamList(state, res) {
		state.teamView = res;
		if(state.userContestTeams) var idx = state.userContestTeams.find(uct => uct.getId() == res.getId());
		if (state.userContestTeams && idx != -1) {
			state.userContestTeams.splice(idx, 1, res);
		}
	},
	setTeams(state, res) {
		console.log("teams .../", res);
		state.teams = res.getTeamPreviewList();
	},
	clearTeams(state) {
		state.teams = [];
	},
	setLoginError(state, error) {
		state.loginError = error;
	},
	setUserToken(state, token) {
		state.userToken = token;
		state.lastTokenRrefreshEpochMs = Date.now();
	},
	setUserPreference(state, up) {
		state.userPreference = up;
	},
	initialized(state) {
		state.initialized = true;
	},
	setTeam(state, res) {
		state.team = res;
	},
	createTeamStatus(state, status) {
		state.createTeamStatus = status;
	},
	setEditorConfig(state, config) {
		if (!state.userPreference.getEditorConfig()) {
			state.userPreference.setEditorConfig(new proto.io.codedrills.proto.user.EditorConfig().setLanguage(proto.io.codedrills.proto.judge.CPP_17));
		}
		var lang = proto.io.codedrills.proto.judge.Language[config.language];
		if (config.language) state.userPreference.getEditorConfig().setLanguage(lang);
		if (config.tabSize) state.userPreference.getEditorConfig().setTabSize(config.tabSize);
		if (config.theme) state.userPreference.getEditorConfig().setTheme(config.theme);
		if (config.keyMap) state.userPreference.getEditorConfig().setKeyMap(config.keyMap);
		if (config.fontSize) state.userPreference.getEditorConfig().setFontSize(config.fontSize);
		console.log("Saving editor config", state.userPreference.toObject());
	},
	setDashboardView(state, dashboardView) {
		state.dashboardView = dashboardView;
	},
	setUserContestTeams(state, res) {
		state.userContestTeams = res.getTeamViewList();
	},
	getUserPreferenceStatus(state, s) {
		state.getUserPreferenceStatus = s;
	},
	saveUserPreferenceStatus(state, s) {
		state.saveUserPreferenceStatus = s;
	},
	setProfile(state, res) {
		console.log("profile ....", res.toObject());
		state.profile = res;
	},
	setPublicProfile(state, res) {
		console.log("PublicProfile ....", res.toObject());
		state.publicProfile = res;
	},
	getProfileStatus(state, status) {
		state.getProfileStatus = status;
	},
	getPublicProfileStatus(state, status) {
		state.getPublicProfileStatus = status;
	},
	getDashboardStatus(state, s) {
		state.getDashboardStatus = s;
	},
	setAbout(state, about) {
		state.profile.getProfile().setAbout(about);
	},
	addExperience(state, experience) {
		if (!state.profile.getProfile().getCareer()) {
			state.profile.getProfile().setCareer(new user_proto.Career());
		}
		state.profile.getProfile().getCareer().addExperiences(experience);
	},
	updateCPProfile(state, cpProfile) {
		state.profile.getProfile().getCompetitiveProfilesList().push(cpProfile);
	},
	removeCPProfile(state, index) {
		state.profile.getProfile().getCompetitiveProfilesList().splice(index, 1);
	},
	updateTitle(state, { title, index }) {
		state.profile.getProfile().getCareer().getExperiencesList()[index].setTitle(title);
	},
	updateCompany(state, { company, index }) {
		state.profile.getProfile().getCareer().getExperiencesList()[index].setCompany(company);
	},
	updateLocation(state, { location, index }) {
		state.profile.getProfile().getCareer().getExperiencesList()[index].setLocation(location);
	},
	setHandle(state, handle) {
		state.profile.setHandle(handle);
	},
	setName(state, name) {
		state.profile.setName(name);
	},
	setSSOPayload(state, payload) {
		state.ssoPayload = payload;
	},
	setSSOSig(state, sig) {
		state.ssoSig = sig;
	},
	updateUserStatus(status) {
		state.updateUserStatus = status;
	},
	setUserActivity(state, userActivity) {
		state.userActivity = userActivity;
	},
	fetchUserActivityStatus(state, status) {
		state.fetchUserActivityStatus = status;
	},
	setTrackingStatusStatus(state, status) {
		state.setTrackingStatusStatus = status;
	},
	validateSSOStatus(state, status) {
		state.validateSSOStatus = status;
	},
	editProfileStatus(state, status) {
		state.editProfileStatus = status;
	},
	verifyCpProfileStatus(state, status) {
		state.verifyCpProfileStatus = status;
	},
	setProfileImage(state, image) {
		console.log("Profile image is ", image);
		state.profile.getProfile().setImageUrl(image);
		state.profileImageUrl = image;
	},
	updateTeamInviteStatus(state, status) {
		state.updateTeamInviteStatus = status;
	},
	getTeamStatus(state, status) {
		state.getTeamStatus = status;
	},
	getTeamsStatus(state, status) {
		state.getTeamsStatus = status;
	},
	getUserContentTeamsStatus(state, status) {
		state.getUserContentTeamsStatus = status;
	},
	registerUserStatus(state, status) {
		state.registerUserStatus = status;
	},
	updateMailSubscriptionsStatus(state, status) {
		state.updateMailSubscriptionsStatus = status;
	},
	getMailSubscriptionsStatus(state, status) {
		state.getMailSubscriptionsStatus = status;
	},
	setSubscriptionToken(state, subscriptionToken) {
		state.subscriptionToken = subscriptionToken;
	},
	setUserMailSubscriptions(state, res) {
		console.log("Res...", res);
		state.userMailSubscriptions = res;
	},
	setSubscription(state, {subscription, isSet}) {
		console.log("Setting .. ", subscription, isSet, state.userMailSubscriptions.toObject());
		if(!state.userMailSubscriptions) {
			state.userMailSubscriptions = new user_proto.SubscriptionSettings();
		}
		if(isSet) {
			state.userMailSubscriptions.addSubscription(subscription);
		} else {
			var idx = state.userMailSubscriptions.getSubscriptionList().findIndex(uct => uct === subscription);
			if (idx != -1) {
				state.userMailSubscriptions.getSubscriptionList().splice(idx, 1);
			}
		}
		console.log("After ..", state.userMailSubscriptions.toObject());
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
