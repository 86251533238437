var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-app-bar', {
    attrs: {
      "flat": "",
      "color": "white",
      "height": "75"
    }
  }, [_vm.$vuetify.breakpoint.smAndDown ? _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "xs": 1
    }
  }, [_c('v-app-bar-nav-icon', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.drawer = !_vm.drawer;
      }
    }
  })], 1), _c('v-spacer'), _c('v-col', [_c('router-link', {
    attrs: {
      "to": "/community"
    }
  }, [_c('img', {
    attrs: {
      "alt": "[C]",
      "height": "25",
      "width": "50",
      "src": require('../assets/images/CodeDrillsLight.svg')
    }
  })])], 1)], 1) : _c('v-row', [_c('v-col', {
    attrs: {
      "md": 1,
      "lg": 1
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    attrs: {
      "alt": "[C]",
      "height": "35",
      "width": "50",
      "src": require('../assets/images/CodeDrillsLight.svg')
    }
  })])], 1), _c('v-spacer'), _c('v-col', {
    staticClass: "d-none d-md-flex",
    attrs: {
      "md": 1
    }
  }, [!_vm.isProd ? _c('span', [_vm._v(" [" + _vm._s(_vm.env) + "] ")]) : _vm._e(), _vm.user && !_vm.isEmailVerified ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error"
    }
  }, [_c('span', {
    staticClass: "font-weight-light text-color-white"
  }, [_c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.sendVerifyEmail.apply(null, arguments);
      }
    }
  }, [_vm._v("Verify your email")])])]) : _vm._e()], 1), _c('v-col', {
    staticClass: "d-none d-md-flex",
    attrs: {
      "md": 1
    }
  }, [_c('span', [_c('router-link', {
    staticClass: "mr-5 menu_item title text-md-subtitle-1",
    attrs: {
      "to": "/"
    }
  }, [_vm._v("Companies")])], 1), _vm.user && !_vm.isEmailVerified ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error"
    }
  }, [_c('span', {
    staticClass: "font-weight-light text-color-white"
  }, [_c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.sendVerifyEmail.apply(null, arguments);
      }
    }
  }, [_vm._v("Verify your email")])])]) : _vm._e()], 1), _c('v-spacer'), _c('v-col', {
    staticClass: "menu_item d-flex justify-center",
    attrs: {
      "md": 8,
      "lg": 8,
      "xl": 6
    }
  }, [_c('router-link', {
    staticClass: "mr-5 menu_item title text-md-subtitle-1",
    attrs: {
      "to": "/problems"
    }
  }, [_vm._v("Interview Problems")]), _c('router-link', {
    staticClass: "mr-5 menu_item title text-md-subtitle-1",
    attrs: {
      "to": "/drills"
    }
  }, [_vm._v("Drills")]), _c('router-link', {
    staticClass: "mr-5 menu_item title text-md-subtitle-1",
    attrs: {
      "to": "/competitive"
    }
  }, [_vm._v("Competitive Problems")]), _c('router-link', {
    staticClass: "mr-5 menu_item title text-md-subtitle-1",
    attrs: {
      "to": "/contests"
    }
  }, [_vm._v("Contests")]), _c('v-menu', {
    attrs: {
      "offset-y": "",
      "auto": "",
      "bottom": "",
      "nudge-top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('span', _vm._g(_vm._b({
          staticClass: "mr-5 menu_item title text-md-subtitle-1"
        }, 'span', attrs, false), on), [_vm._v(" ICPC "), _c('v-icon', [_vm._v(_vm._s(_vm.mdiMenuDown))])], 1)];
      }
    }])
  }, [_c('v-list', {
    staticClass: "title text-md-subtitle-1"
  }, [_c('v-list-item', [_c('v-list-item-title', [_c('router-link', {
    staticClass: "mr-5 menu_item title text-md-subtitle-1",
    attrs: {
      "to": "/amritaicpc"
    }
  }, [_vm._v("ICPC Amritapuri")])], 1)], 1), _c('v-list-item', [_c('v-list-item-title', [_c('router-link', {
    staticClass: "mr-5 menu_item title text-md-subtitle-1",
    attrs: {
      "to": "/gwalioricpc"
    }
  }, [_vm._v("ICPC Gwalior-Pune")])], 1)], 1)], 1)], 1), _c('router-link', {
    staticClass: "mr-5 menu_item title text-md-subtitle-1",
    attrs: {
      "to": "/community/jobs"
    }
  }, [_vm._v("Jobs")])], 1), _vm.user ? _c('v-col', {
    attrs: {
      "md": 1
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-menu', {
    attrs: {
      "offset-y": "",
      "auto": "",
      "bottom": "",
      "nudge-top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-avatar', _vm._g(_vm._b({
          attrs: {
            "color": "accent",
            "dark": ""
          }
        }, 'v-avatar', attrs, false), on), [_vm.getProfileImage ? _c('img', {
          attrs: {
            "src": _vm.getProfileImage,
            "alt": "User"
          }
        }) : _c('span', {
          staticClass: "white--text text-h5"
        }, [_vm._v(_vm._s(_vm.profileText))])]), _c('v-icon', _vm._g(_vm._b({}, 'v-icon', attrs, false), on), [_vm._v(_vm._s(_vm.mdiMenuDown))])];
      }
    }], null, false, 3865210599)
  }, [_c('v-list', [_c('v-list-item', [_c('v-list-item-title', [_c('router-link', {
    staticClass: "mr-5 menu_item text-uppercase subtitle",
    attrs: {
      "to": "/profile"
    }
  }, [_vm._v("Profile")])], 1)], 1), _c('v-list-item', [_c('v-list-item-title', [_c('router-link', {
    staticClass: "mr-5 menu_item text-uppercase subtitle",
    attrs: {
      "to": "/subscription"
    }
  }, [_vm._v("Settings")])], 1)], 1), _c('v-list-item', [_c('v-list-item-title', [_c('router-link', {
    staticClass: "mr-5 menu_item text-uppercase subtitle",
    attrs: {
      "to": "/logout"
    }
  }, [_vm._v("Logout")])], 1)], 1)], 1)], 1)], 1)]) : _vm._e(), !_vm.user || _vm.user.isAnonymous ? _c('v-col', {
    attrs: {
      "col": 1
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.login
    }
  }, [_vm._v("Login")])], 1) : _vm._e()], 1)], 1), _c('v-navigation-drawer', {
    staticClass: "grey lighten-5 px-3 py-3",
    staticStyle: {},
    attrs: {
      "absolute": "",
      "left": "",
      "permanent": false
    },
    model: {
      value: _vm.drawer,
      callback: function callback($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('v-list', {
    staticClass: "pt-0",
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("CodeDrills")])], 1)], 1), _vm.user && !_vm.isEmailVerified ? _c('v-list-item', [_c('span', {
    staticClass: "font-weight-light"
  }, [_c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.sendVerificationEmail.apply(null, arguments);
      }
    }
  }, [_vm._v("Verify your email")])])]) : _vm._e(), _c('v-divider'), _c('v-list-item', [_c('v-list-item-title', [_c('router-link', {
    staticClass: "mr-3 menu_item title",
    attrs: {
      "to": "/profile"
    }
  }, [_vm._v("Profile")])], 1)], 1), _c('v-list-item', [_c('v-list-item-title', [_c('router-link', {
    staticClass: "mr-3 menu_item title",
    attrs: {
      "to": "/problems"
    }
  }, [_vm._v("Interview")])], 1)], 1), _c('v-list-item', [_c('v-list-item-title', [_c('router-link', {
    staticClass: "mr-3 menu_item title",
    attrs: {
      "to": "/competitive"
    }
  }, [_vm._v("Competitive")])], 1)], 1), _c('v-list-item', [_c('v-list-item-title', [_c('router-link', {
    staticClass: "mr-3 menu_item title",
    attrs: {
      "to": "/drills"
    }
  }, [_vm._v("Drills")])], 1)], 1), _c('v-list-item', [_c('v-list-item-title', [_c('router-link', {
    staticClass: "mr-3 menu_item title",
    attrs: {
      "to": "/amritaicpc"
    }
  }, [_vm._v("ICPC Amritapuri")])], 1)], 1), _c('v-list-item', [_c('v-list-item-title', [_c('router-link', {
    staticClass: "mr-3 menu_item title",
    attrs: {
      "to": "/gwalioricpc"
    }
  }, [_vm._v("ICPC Gwalior")])], 1)], 1), _c('v-list-item', [_c('v-list-item-title', [_c('router-link', {
    staticClass: "mr-3 menu_item title",
    attrs: {
      "to": "/contests"
    }
  }, [_vm._v("Contests")])], 1)], 1), _c('v-list-item', [_c('v-list-item-title', [_c('router-link', {
    staticClass: "mr-3 menu_item title",
    attrs: {
      "to": "/"
    }
  }, [_vm._v("Companies")])], 1)], 1), _c('v-list-item', [_c('v-list-item-title', [_c('router-link', {
    staticClass: "mr-3 menu_item title",
    attrs: {
      "to": "/community/jobs"
    }
  }, [_vm._v("Jobs")])], 1)], 1)], 1), _c('v-divider'), _c('v-list', {
    staticClass: "pt-0",
    attrs: {
      "dense": "",
      "justify": "end"
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_c('router-link', {
    staticClass: "mr-3 menu_item title",
    attrs: {
      "to": "mailto:hello@codedrills.io"
    }
  }, [_vm._v("Contact Us")])], 1)], 1), _c('v-list-item', [_c('v-list-item-title', [_c('router-link', {
    staticClass: "mr-3 menu_item title",
    attrs: {
      "to": "/blogs"
    }
  }, [_vm._v("Blog")])], 1)], 1), _c('v-list-item', [_c('v-list-item-title', [_c('router-link', {
    staticClass: "mr-3 menu_item title",
    attrs: {
      "to": "/career"
    }
  }, [_vm._v("Career")])], 1)], 1)], 1), _c('v-divider'), _c('v-list-item', [_c('v-list-item-title', [_vm.user && !_vm.user.isAnonymous ? _c('v-btn', {
    attrs: {
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.logoutAction
    }
  }, [_vm._v("Logout")]) : _c('v-btn', {
    attrs: {
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.login
    }
  }, [_vm._v("Login")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }