<template>
  <page minimal title="Contests" :apiStatusList="[fetchUserContentPreviewsStatus]">
		<div v-if="liveContestPreviewsList && liveContestPreviewsList.length > 0">
			<div class="headline mt-10">Live Contests</div>
			<contest-list :contestList="liveContestPreviewsList"></contest-list>
		</div>
		<div v-if="pastContestPreviewsList && pastContestPreviewsList.length > 0">
			<div class="headline mt-10">Upcoming Contests</div>
			<contest-list :contestList="pastContestPreviewsList"></contest-list>
		</div>
		<div v-if="upcomingContestPreviewsList && upcomingContestPreviewsList.length > 0">
			<div class="headline mt-10">Past Contests</div>
			<contest-list :contestList="upcomingContestPreviewsList" reverse></contest-list>
		</div>
  </page>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import ContestList from "../components/ContestList";
import Page from "../components/Page";

export default {
  props: {
  },
  computed: {
    ...mapState("content", ["fetchUserContentPreviewsStatus", "userContentPreviews"]),
    ...mapState("timesync", ["serverTime"]),
    liveContestPreviewsList() {
      return this.userContentPreviews &&
        this.userContentPreviews
        .filter(c => c.getContentPreview().getMetaData().getContestMeta()&&
										 c.getContentPreview().getMetaData().getContestMeta().getStartAt() <= this.serverTime &&
                     c.getContentPreview().getMetaData().getContestMeta().getEndAt() > this.serverTime)
				.map(c => c.getContentPreview());
    },
    pastContestPreviewsList() {
      return this.userContentPreviews &&
        this.userContentPreviews
        .filter(c => c.getContentPreview().getMetaData().getContestMeta() &&
										c.getContentPreview().getMetaData().getContestMeta().getStartAt() > this.serverTime &&
                    c.getContentPreview().getMetaData().getContestMeta().getEndAt() > this.serverTime)
				.map(c => c.getContentPreview());
    },
    upcomingContestPreviewsList() {
      return this.userContentPreviews &&
        this.userContentPreviews
        .filter(c => c.getContentPreview().getMetaData().getContestMeta() &&
										c.getContentPreview().getMetaData().getContestMeta().getStartAt() < this.serverTime &&
                    c.getContentPreview().getMetaData().getContestMeta().getEndAt() < this.serverTime)
				.map(c => c.getContentPreview());
    },
  },
  methods: {
		...mapGetters("content", ["getContentType"]),
    ...mapActions("content", ["fetchUserContentPreviews"]),
  },
  created() {
    this.fetchUserContentPreviews({"contentTypes": [this.getContentType()['CONTEST']]});
  },
  components: {
		ContestList,
		Page,
  }
};
</script>

