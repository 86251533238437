<template>
  <v-row align="center" justify="center" class="py-16">
    <v-col v-if="!submittedFeedback" cols="12" md="10">
      <v-card class="px-4 px-lg-10 py-16">
        <v-card-title
          class="text-h2 danger--text justify-center font-weight-bold pb-8"
        >
          Congrats!
        </v-card-title>
        <v-card-subtitle class="text-h4 text-center black--text">
          You have completed the Assessment.
        </v-card-subtitle>
        <v-card-text class="">
          <v-row align="center" justify="center">
            <v-col cols="4">
              <v-img
                :src="require('../assets/images/TestEnd.png')"
                height="100%"
                width="auto"
                contain
              >
              </v-img>
            </v-col>
            <v-col cols="8" class="text-body-1">
              <v-row class="pt-5 justify-center">
                <v-col class="mt-3 text-center text-sm-left" cols="10" lg="8">
                  How would you rate your experience with Assessment?
                </v-col>
                <v-col cols="auto">
                  <v-rating
                    v-model="assessmentRating"
                    color="yellow darken-2"
                    size="42"
                  ></v-rating>
                </v-col>
              </v-row>
              <v-row class="rating justify-center">
                <v-col class="mt-3 text-center text-sm-left" cols="10" lg="8">
                  How would you rate your experience with CodeDrills?
                </v-col>
                <v-col cols="auto">
                  <v-rating
                    v-model="codedrillsRating"
                    color="yellow darken-2"
                    size="42"
                  >
                  </v-rating>
                </v-col>
              </v-row>
              <v-textarea
                class="mt-12"
                outlined
                placeholder="Share your experience with us"
                v-model="writtenFeedback"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="finishTest" color="accent" class="px-5">
            Finish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12" md="10" v-else>
      <v-card class="px-4 px-lg-8 py-16">
        <v-card-title
          class="text-h3 text-center justify-center font-weight-medium pb-8"
        >
          Thanks for submitting your feedback!
        </v-card-title>
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col cols="6">
              <v-img :src="require('../assets/images/feedback.png')"> </v-img>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            to="/"
            color="primary"
            class="px-5 py-2 text-none text-body-1 font-weight-medium"
          >
            Continue
            <v-icon right>
              {{ mdiChevronRight }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { mdiChevronRight } from "@mdi/js";
import { closeFullscreen } from "../utils/helper";

export default {
  props: {
    testView: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      codedrillsRating: 0,
      assessmentRating: 0,
      writtenFeedback: "",
      submittedFeedback: false,
      mdiChevronRight,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    ...mapActions("candidate", ["sendCandidateFeedback", "endTest"]),
    finishTest() {
      this.sendCandidateFeedback({
        test_id: this.testView.getTestView()?.getTestPreview()?.getId(),
        test_stars: this.assessmentRating,
        cd_stars: this.codedrillsRating,
        written: this.writtenFeedback,
      }).then((_) => {
        this.submittedFeedback = true;
      });
      closeFullscreen();
    },
  },
  created() {
    // call only if test not ended
    this.testView?.getTestView()?.getTestTimeInfo()?.getEndedAt() === 0 &&
      this.endTest({
        test_id: this.testView.getTestView()?.getTestPreview()?.getId(),
      });
    
  },
  components: {},
};
</script>
