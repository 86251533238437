<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="!rejection && cancel"
  >
    <v-card class="pa-5">
      <v-card-text v-show="!!message" class="pa-4 text-h6 text-center">
        {{ message }}
      </v-card-text>
      <v-card-actions class="py-4 px-8 justify-center">
        <v-btn
          v-if="rejection"
          large
          @click.native="cancel"
          elevation="0"
          class="text-none text-subtitle-1 font-weight-medium px-2"
        >
          Cancel
        </v-btn>
        <v-spacer v-if="rejection"></v-spacer>
        <v-btn
          large
          :color="options.color"
          @click.native="agree"
          elevation="0"
          class="text-none text-subtitle-1 font-weight-medium px-2"
        >
          <span v-if="rejection"> OK </span>
          <span v-else> Yes </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    rejection: {
      default: true,
      required: false,
    },
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    // title: null,
    options: {
      color: "primary",
      width: 600,
      zIndex: 200,
    },
  }),
  methods: {
    open(message, options) {
      this.dialog = true;
      //   this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
