var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-carousel', {
    attrs: {
      "hide-delimiters": "",
      "cycle": "",
      "show-arrows-on-hover": "",
      "height": "400px"
    }
  }, _vm._l(_vm.items, function (item, i) {
    return _c('v-carousel-item', {
      key: i
    }, [_c('v-card', {
      staticClass: "pa-8",
      attrs: {
        "elevation": "0",
        "rounded": "lg",
        "dark": "",
        "color": "black",
        "height": "100%"
      }
    }, [_c('v-card-title', {
      staticClass: "font-weight-bold text-h5"
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "3",
        "md": "2"
      }
    }, [_c('v-avatar', [_c('img', {
      attrs: {
        "src": item.src
      }
    })])], 1), _c('v-col', [_c('div', [_vm._v(" " + _vm._s(item.name) + " ")]), _c('div', {
      staticClass: "text-caption"
    }, [_vm._v(" " + _vm._s(item.subtitle) + " ")])])], 1)], 1), _c('v-card-subtitle', {}, [_c('v-rating', {
      staticClass: "pt-5",
      attrs: {
        "value": item.rating,
        "size": "32",
        "color": "primary",
        "dense": "",
        "readonly": ""
      }
    })], 1), _c('v-card-text', {
      staticClass: "white--text text-body-1 mt-8"
    }, [_vm._v(" " + _vm._s(item.review) + " ")])], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }