<template>
  <div class="px-4 px-md-16 pt-16">
    <div class="mb-6">
      <p>The fastest and easiest way to hire tech experts</p>
      <v-btn
        rounded
        color="white"
        dark
        outlined
        class="text-none text-body-1"
        to="/signup"
      >
        Enroll Now
      </v-btn>
    </div>
    <v-row class="mx-auto mb-5">
      <v-col cols="7" md="2" v-for="part in footerCols" :key="part.title">
        <div class="text-subtitle-1 font-weight-medium">
          {{ part.title }}
        </div>
        <ul class="link-col text-caption text-md-left">
          <li v-for="link in part.links" :key="link.text">
            <a :href="link.href" class="footer-link">
              {{ link.text }}
            </a>
          </li>
        </ul>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp">
        <v-img src="@/assets/images/recruiter/footer.png" max-width="300px">
        </v-img>
      </v-col>
    </v-row>
    <hr />
    <v-row class="py-8 px-4 px-md-8" justify="center" align="center">
      <v-col
        cols="12"
        md="5"
        class="d-flex flex-row justify-space-around justify-md-start"
      >
        <div class="text-body-1 font-weight-medium pr-5">Follow Us</div>
        <div class="icons">
          <a
            v-for="social in socials"
            :key="social.link"
            :href="social.link"
            target="_blank"
            rel="noopener noreferrer"
            class="pr-2"
          >
            <v-icon color="white">
              {{ social.icon }}
            </v-icon>
          </a>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="7"
        class="footer-actions d-flex flex-row justify-center justify-md-end"
      >
        <v-btn
          to="/signUpCustomer"
          class="text-none text-body-2"
          rounded
          color="primary"
          small
        >
          Sign Up
        </v-btn>
        <v-btn
          href="mailto:hello@codedrills.io"
          class="text-none text-body-2 ml-5"
          rounded
          color="white"
          small
        >
          Contact Us
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mdiFacebook, mdiTwitter, mdiLinkedin, mdiInstagram } from "@mdi/js";
export default {
  data() {
    return {
      mdiFacebook,
      mdiTwitter,
      mdiLinkedin,
      mdiInstagram,
      socials: [
        {
          icon: mdiFacebook,
          link: "https://www.facebook.com/CodeDrills-102324484754529",
        },
        {
          icon: mdiTwitter,
          link: "https://twitter.com/CodeDrills",
        },
        {
          icon: mdiLinkedin,
          link: "https://www.linkedin.com/company/codedrills/",
        },
        {
          icon: mdiInstagram,
          link: "https://www.instagram.com/codedrills/",
        },
      ],
      footerCols: [
        {
          title: "Product",
          links: [
            {
              text: "Hiring for Recruiters",
              href: "/signup",
            },
            // {
            //   text: "Assessments for Recruiters",
            //   href: "/assessments",
            // },

            {
              text: "Contests for Candidates",
              href: "/contests",
            },

            {
              text: "Problems for Candidates",
              href: "/problems",
            },
          ],
        },
        {
          title: "For Candidates",
          links: [
            {
              text: "Interview Problems",
              href: "/problems",
            },
            {
              text: "Drills",
              href: "/drills",
            },
            {
              text: "Competitive Problems",
              href: "/competitive",
            },
            {
              text: "Contests",
              href: "/contests",
            },
            {
              text: "ICPC",
              href: "/amritaicpc",
            },
            {
              text: "For Businesses",
              href: "/",
            },
          ],
        },
        {
          title: "For Recruiters",
          links: [
            {
              text: "CodeDrills Recruiter",
              href: "/",
            },
          ],
        },
        {
          title: "Company",
          links: [
            {
              text: "Blog",
              href: "https://info.codedrills.io/blog",
            },
            // {
            //   text: "Careers",
            //   href: "",
            // },
            {
              text: "FAQs",
              href: "#faqs",
            },
            // {
            //   text: "Team",
            //   href: "",
            // },
            {
              text: "Contact",
              href: "mailto:hello@codedrills.io",
            },
          ],
        },
      ],
    };
  },
};
</script>
<style scoped>
.link-col {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}
.footer-link {
  text-decoration: none;
  color: white;
}
.footer-link:hover {
  color: #2eac68;
}
</style>