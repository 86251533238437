<template>
<span>
  <v-chip outlined color="grey lighten-2" text-color="grey" class="mr-2 mt-2"
  v-for="t in getTopicTags" label :key="t" >
  {{t}}
  </v-chip>
  <v-row>
    <!-- <v-col :cols="6" :md="3" :lg="2" class="font-weight-medium">
      <stats-col title="Solved">
        {{metadata.getProblemStats().getSolved()}}
      </stats-col>
    </v-col>
    <v-col :cols="6" :md="3" :lg="2" class="font-weight-medium">
      <stats-col title="Accuracy">
        {{accuracy}}
      </stats-col>
    </v-col> -->
     <v-col v-if="companyTags.length" :cols="6" :md="3" :lg="6"  class="font-weight-medium">
      <stats-col title="Companies"  >
       <v-chip outlined color="grey lighten-2" text-color="grey" class="mr-2"
          v-for="t in companyTags.slice(0,3)" label :key="t" >
           {{t}}
        </v-chip>
        <v-btn class="grey--text button_style" outlined  height="32" v-if="companyTags.length>3" @click="showMore = true">
					...
				</v-btn>
        <v-dialog  v-model="showMore" @keydown.esc="showMore = false" max-width= "610">
		  	<v-card>
        <v-card-title>Companies</v-card-title>
				 <v-card-text>
         <v-chip outlined color="grey lighten-2" text-color="grey" class="mr-2 mt-6"
          v-for="t in companyTags" label :key="t" >
           {{t}}
        </v-chip>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="showMore = false">CLOSE</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
      </stats-col>
    </v-col>
  </v-row>
</span>
</template>

<script>
import HtmlView from "../components/HtmlView";
import StatsCol from "../components/StatsCol";

export default {
  props: {
    "contentView": {
      type: Object,
      required: true,
    },
  },
  data()
  {
     return{
       showMore:false,
     };
  },
  name: "articleView",
  computed: {
    view() {
      return this.contentView.getDataView().getProblemView();
    },
    metadata() {
        return this.contentView.getMetaData().getProblemMeta();
    },
    accuracy() {
        return (Math.round(100 * 100 * this.metadata.getProblemStats().getAccuracy()) / 100).toFixed(2)
    },
    getTopicTags()
    {
     return  this.contentView.getMetaData().getTagsList().filter(t => t.startsWith("topics/"))
          .map(t => t.substring(7));
    },
    companyTags()
    {
      var arr=this.contentView.getMetaData().getTagsList().filter(t => t.startsWith("companies/"))
          .map(t => t.substring(10));
          return arr;
    }
  },
  components: {
    HtmlView,
    StatsCol,
  }
};
</script>
<style scoped>
.button_style{
  background-color: transparent !important;
  border-color: #e0e0e0 !important;
}
</style>
