<template>
	<v-row v-if="teams">
		<v-col>
			<v-card class="mt-5" flat outlined>
				<v-data-table
					:server-items-length="totalItems"
					no-data-text="No teams ceated."
					class="mt-5 px-5"
					:headers="headers"
					:items="teams"
					:footer-props.sync=footerOptions
					:loading="pageLoading"
					:options.sync="pagination"
				>
					<template v-slot:item="{ item }">
						<tr>
							<td>
								<router-link class="text-decoration-none router_link"
									:to="`/contests/${contestUrl}/teams/${item.getId()}`"
								>
									{{ item.getName() }}
								</router-link>
							</td>
							<td>
								<v-chip outlined color="grey accent" pill class="ma-1" v-for="(member, index) in item.getMemberPreviewsList()" :key="index">
								<router-link v-if="member.getHandle()" class="text-decoration-none router_link"
									:to="`/profiles/${member.getHandle()}`">
									{{ member.getHandle() }}
								</router-link>
								<span v-else-if="member.getName()"> {{ member.getName() }}  </span>
								<span v-else> Unknown  </span>
								</v-chip>
							</td>
						</tr>
					</template>
				</v-data-table>
			</v-card>
		</v-col>
	</v-row>
</template>
<script>
import { mapActions, mapMutations, mapState} from 'vuex';

export default {
	props: {
		contestId: {
			type: Number,
			required: true,
		},
		contestUrl: {
			type: String,
			required: true,
		},
	},
  data: function() {
    return {
			footerOptions: {
        itemsPerPageOptions: [25, 50, 100],
      },
	   ex: null,
		 totalItems: -1,
		 pagination: {
			 itemsPerPage: 25,
			 page: 1,
		 },
	  pageLoading: false,
		editDone: false,
		teamCreationFailed: false,
		search: "",
		headers: [
			{
				text: 'Team Name',
				align: 'left',
				width: '20%',
			},
			{
				text: 'Members',
				align: 'left'
			},
			]
    };
  },
  methods: {
    ...mapActions('user', ['getTeams']),
		...mapMutations('user', ['setTeams', 'clearTeams']),
		async loadTeams() {
			this.pageLoading = true;
			return this.getTeams({"id": this.contestId, paginationQuery: this.pagination})
			.then(res => {
					//this.pagination.total = (res && res.getPaginationResponse())? res.getPaginationResponse().getTotal(): 0;
					this.totalItems  = (res && res.getPaginationResponse())? res.getPaginationResponse().getTotal(): 0;
					this.pageLoading = false;
			});
		}
  },
	computed: {
    ...mapState('user', ['teams']),
	},
  mounted() {
		if(this.teamsList) {
			this.setTeams(this.teamsList);
		} else{
			this.clearTeams();
		}
	},
	watch: {
		pagination: {
			handler(np) {
				console.log("Fetching", np);
				this.loadTeams(np);
			},
			deep: true
		},
	},
};
</script>
<style scoped>
.router_link{
	color:inherit;
}
</style>
