<template>
  <v-row>
    <v-col :cols="11">
			<router-link :to="'/contests/'+contentPreview.getUrl()" class="text-decoration-none">
				<v-card flat outlined class="pl-5 py-3">
					<div class="d-flex flex-no-wrap">
						<div class="d-flex align-center">
							<v-avatar class="d-flex align-center" size="70" tile>
								<v-img :src="contentPreview.getMetaData().getImageUrl()"></v-img>
							</v-avatar>
						</div>
						<div class="mt-2">
							<v-card-title class="text-caption-1" v-text="contentPreview.getTitle()"></v-card-title>
							<v-card-subtitle>
								<v-icon small>{{mdiCalendar}}</v-icon>
								{{formatDate(contentPreview.getMetaData().getContestMeta().getStartAt())}}
								<v-icon class="ml-2" small>{{mdiClock}}</v-icon>
								{{contestDuration(contentPreview.getMetaData().getContestMeta())}}
							</v-card-subtitle>
						</div>
					</div>
				</v-card>
			</router-link>
    </v-col>
  </v-row>
</template>

<script>
import moment, { duration } from 'moment';
import { mdiCalendar, mdiClock } from '@mdi/js'

export default {
	data() {
		return {
			"mdiCalendar": mdiCalendar,
			"mdiClock": mdiClock,
		};
	},
  props: {
    contentPreview: {
      type: Object,
      required: true
    }
  },

	methods: {
		formatDate(value) {
			if(value) {
				console.log("Date ...", value);
				return moment.unix(value).format('D MMM YYYY, ddd HH:mm z');
			}
		},
		contestDuration(contestMeta) {
			var start = contestMeta.getStartAt();
			var end = contestMeta.getEndAt();
			var diff = end - start;
			return Math.floor(diff / (60 * 60)) + " hr " + Math.floor(diff % (60 * 60) / 60) + " mins";
		},
	},

  name: "ContestCard",
};
</script>
<style scoped>
</style>
