var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 10,
      "offset": 1
    }
  }, [_c('p', [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "danger"
    }
  }, [_vm._v(_vm._s(_vm.mdiAlert))]), _vm._t("default")], 2)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }