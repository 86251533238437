<template>
    <v-list-item two-line>
        <v-list-item-content>
        <v-list-item-title class="subtitle-2">{{title}}</v-list-item-title>
        <v-list-item-subtitle class="body-1"><slot name="default"></slot></v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</template>

<script>

export default {
  props: {
    "title": {
      type: String,
      required: true,
    },
  }
};
</script>
<style scoped>
 .v-list-item__subtitle {
     white-space: normal;
}
</style>
