<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card>
          <v-card-title class="body-1 accent white--text">Login / Signup</v-card-title>
          <v-card-text class="pt-2">
            <div id="firebase-ui"></div>
          </v-card-text>
        </v-card>
     </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
import * as firebaseui from "firebaseui";
import auth from "../utils/auth";

export default {
  data: function() {
    return {
      prev: "/community",
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log("route", to, from);
    next(vm => {
      if (from) vm.prev = from.path
      vm.checkLogin();
    });
  },
  computed: {
    ...mapState("user", ["loginError", "user", "authInstance", "validateSSOStatus"]),
  },
  methods: {
		...mapActions("user", ["validateSSO", "reloadUser"]),
		...mapMutations("user", ["setSSOPayload", "setSSOSig"]),
    redirect() {
      return firebaseui.auth.AuthUI.getInstance().isPendingRedirect();
    },
    checkLogin() {
      console.log("Login", this.user, this.redirect());
      console.log("query", this.$route.query);
      var mode = this.$route.query.mode;
      var payload = this.$route.query.sso;
      var sig = this.$route.query.sig;
      var successUrl = this.prev || "/community";
      console.log("SuccessUrl: ", this.prev, successUrl);

      if(this.user && !this.user.isAnonymous && !this.redirect() && mode !== "verifyEmail") {
        if(payload) {
          console.log("sso", payload, sig);
          this.validateSSO({"payload": payload, "sig": sig})
          .then(res => window.location.href = res.getRedirectUrl());
          return;
        }
        console.log("Redirecting home...");
        this.$router.replace("/community/");
        return;
      } else {
        if(payload) {
          console.log("sso", payload, sig);
          this.setSSOPayload(payload);
          this.setSSOSig(sig);
          var successUrl = "/community/login?sso=" + encodeURIComponent(payload) + "&sig=" + encodeURIComponent(sig);
        } else {
          console.log("For verifyEmail");
        }
      }
      var ui = this.$firebaseUI;
      ui.reset();
      ui.start('#firebase-ui', {
        signInSuccessUrl: successUrl,
        signInFlow: 'popup',
        signInOptions: [
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
          signInSuccessWithAuthResult: auth.signInSuccess,
        },
        tosUrl: 'terms-of-service',
        privacyPolicyUrl: 'privacy-policy'
      });
    },
  },
  name: "login",

};
</script>
<style scoped>
@import "./../../node_modules/firebaseui/dist/firebaseui.css"
</style>
