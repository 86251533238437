<template>
  <span>
    <v-row>
        <v-col class="px-1">
            <v-card outlined>
            <v-list-item two-line>
                <v-list-item-content>
                <v-list-item-title class="text-caption-1" v-bind:class="{ 'green--text':status===13 , 'red--text': status>=10 && status!=13 }">{{title}}</v-list-item-title>
                <v-list-item-subtitle class="subtitle-2"> {{subtitle}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar v-if="imageUrl" right :min-height="100" :min-width="100">
                <v-img :src="require('./../assets/images/' + this.imageUrl)" :min-height="100" contain></v-img>
                </v-list-item-avatar>
            </v-list-item>
            <div class="px-2">
              <slot></slot>
            </div>
            </v-card>
        </v-col>
    </v-row>
  </span>
</template>

<script>
export default {
  props: {
    "title": {
      type: String,
      required: true,
    },
    "subtitle": {
        type: String,
        required: true,
    },
    "imageUrl": {
        type: String,
        required: false,
    },
    "status": {
        type: Number,
        required: false,
    },
  },
};
</script>
<style scoped>
</style>
