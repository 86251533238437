const state = {
  notifications: [],
};

const mutations = {
  PUSH: (state, notification) =>
    state.notifications.push({
      ...notification,
      id: Math.random().toString(36) + Date.now().toString(36),
    }),
  REMOVE(state, remove) {
    const removed = state.notifications.filter((notif) => notif.id !== remove);
    state.notifications = [...removed];
  },
};

const actions = {
  addNotif: ({ commit }, notification) => {
    commit("PUSH", notification);
  },
  removeNotif: ({ commit }, notifId) => {
    commit("REMOVE", notifId);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
