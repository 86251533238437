<template>
  <v-snackbar :value="visible" :color="type" elevation="0">
    <div class="d-flex">
      <v-icon large class="mr-5">
        {{ notifIcon }}
      </v-icon>
      <div class="text-h6 font-weight-medium text-center">
        {{ text }}
      </div>
    </div>
  </v-snackbar>
</template>
<script>
import { mapActions } from "vuex";
import { mdiCheck, mdiAlertCircle, mdiInformationOutline } from "@mdi/js";
/**
 * notification has
 * - id
 * - text
 * - type: 'success' | 'error' | 'info'
 * - duration?: optional
 */
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      validator: (value) => ["success", "error", "info"].includes(value),
      default: "info",
      required: true,
    },
    duration: {
      type: Number,
      required: false,
      default: 4000,
    },
  },
  data() {
    return {
      visible: false,
      timeout: null,
      mdiCheck,
      mdiAlertCircle,
      mdiInformationOutline,
      notifIcon: "",
    };
  },
  created() {
    this.visible = true;
    if (this.type === "success") {
      this.notifIcon = this.mdiCheck;
    } else if (this.type === "info") {
      this.notifIcon = this.mdiInformationOutline;
    } else {
      this.notifIcon = this.mdiAlertCircle;
    }
    this.timeout = setTimeout(() => {
      this.removeNotif(this.id);
      this.visible = false;
    }, this.duration);
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: {
    ...mapActions("notifs", ["removeNotif"]),
  },
};
</script>
