<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col v-for="(item, i) in this.sortedContestList" :key="i" cols="12">
        <contest-card :contentPreview="item"></contest-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ContestCard from "../components/ContestCard";

export default {
  props: {
    contestList: {
      type: Array,
      required: true
    },
		reverse: {
			type: Boolean,
			required: false,
			default: false,
		},
  },
	computed: {
		sortedContestList() {
			if(!this.reverse) {
				return this.contestList.sort((a, b) => a.getMetaData().getContestMeta().getEndAt()
				- b.getMetaData().getContestMeta().getEndAt());

			} else {
			return this.contestList &&
				this.contestList.sort((a, b) => b.getMetaData().getContestMeta().getEndAt()
				- a.getMetaData().getContestMeta().getEndAt());
			}
		}
	},
  components: {
    ContestCard
  }
};
</script>
<style scoped>
</style>
