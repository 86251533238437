var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "fill-height",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "8",
      "md": "4"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "body-1 accent white--text"
  }, [_vm._v("Basic profile info")]), _c('v-card-text', {
    staticClass: "pt-2"
  }, [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "placeholder": "Name or handle",
      "outlined": "",
      "rules": [_vm.rules.required]
    },
    model: {
      value: _vm.displayName,
      callback: function callback($$v) {
        _vm.displayName = $$v;
      },
      expression: "displayName"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "d-flex justify-end",
    "class": {
      'text-center': _vm.$vuetify.breakpoint.mobile,
      'text-end': !_vm.$vuetify.breakpoint.mobile
    }
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": 12
    }
  }, [_c('v-btn', {
    attrs: {
      "disabled": !_vm.displayName,
      "color": "accent",
      "height": "35",
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 75 : 90
    },
    on: {
      "click": _vm.saveProfile
    }
  }, [_vm._v("Save")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }