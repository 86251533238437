var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.resultViews ? _c('v-container', {
    attrs: {
      "multiple": ""
    }
  }, [_vm.rv.getInputView().getArgInputViewsList().length > 0 ? _c('v-container', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-2"
  }, [_vm._v(" Input ")])], 1), _vm._l(_vm.rv.getInputView().getArgInputViewsList(), function (input) {
    return _c('v-row', {
      key: input.getName()
    }, [_c('v-col', {
      attrs: {
        "cols": "4"
      }
    }, [_c('v-text-field', {
      attrs: {
        "single": "",
        "outlined": "",
        "readonly": "",
        "label": input.getName(),
        "value": input.getValue()
      }
    })], 1)], 1);
  }), _c('v-row', [_c('v-col', {
    staticClass: "subtitle-2"
  }, [_vm._v(" Output ")])], 1), _c('v-row', [_c('v-col', [_vm.rv.getActualView() && _vm.rv.getActualView().getArgOutputView() ? _c('text-block', [_c('pre', [_vm._v(_vm._s(_vm.rv.getActualView().getArgOutputView().getValue()))])]) : _vm._e()], 1)], 1), _vm.rv.getExpectedView() ? _c('v-row', [_c('v-col', {
    staticClass: "subtitle-2"
  }, [_vm._v(" Expected ")])], 1) : _vm._e(), _vm.rv.getExpectedView() ? _c('v-row', [_c('v-col', [_c('text-block', [_c('pre', [_vm._v(_vm._s(_vm.rv.getExpectedView().getArgOutputView().getValue()))])])], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', {
    staticClass: "subtitle-2"
  }, [_vm._v(" Logs ")])], 1), _c('v-row', [_c('v-col', [_c('text-block', [_c('pre', [_vm._v(_vm._s(_vm.rv.getLogs()))])])], 1)], 1)], 2) : _c('v-container', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-2"
  }, [_vm._v(" Input ")])], 1), _c('v-row', [_c('v-col', [_c('text-block', [_c('pre', [_vm._v(_vm._s(_vm.rv.getInputView().getRawInput()))])])], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "subtitle-2"
  }, [_vm._v(" Output ")])], 1), _c('v-row', [_c('v-col', [_vm.rv.getActualView() ? _c('text-block', [_c('pre', [_vm._v(_vm._s(_vm.rv.getActualView().getRawOutput()))])]) : _vm._e()], 1)], 1), _vm.rv.getExpectedView() ? _c('v-row', [_c('v-col', {
    staticClass: "subtitle-2"
  }, [_vm._v(" Expected ")])], 1) : _vm._e(), _vm.rv.getExpectedView() ? _c('v-row', [_c('v-col', [_c('text-block', [_c('pre', [_vm._v(_vm._s(_vm.rv.getExpectedView().getRawOutput()))])])], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', {
    staticClass: "subtitle-2"
  }, [_vm._v(" Logs ")])], 1), _c('v-row', [_c('v-col', [_c('text-block', [_c('pre', [_vm._v(_vm._s(_vm.rv.getLogs()))])])], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }