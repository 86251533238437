<template>
  <v-app v-if="initialized">
    <List />
    <BaseLayout>
      <router-view :key="$route.fullPath" />
    </BaseLayout>
  </v-app>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import List from "./components/notifs/List.vue";
import BaseLayout from "./layouts/BaseLayout.vue";

export default {
  name: "app",
  data() {
    return {};
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from, next) {
        document.title = to.meta.title || "CodeDrills";
      },
    },
  },
  computed: {
    ...mapState("user", ["initialized"]),
  },
  methods: {
    ...mapMutations("user", ["setSubscriptionToken"]),
    redirect() {
      //console.log("Redirecting?", redirect);
      return redirect;
    },
  },
  components: {
    BaseLayout,
    List,
  },
  created() {
    if (this.$messaging) {
      this.$messaging
        .requestPermission()
        .then(() => this.$messaging.getToken())
        .then((token) => {
          console.log("Token: ", token);
          this.setSubscriptionToken(token);

          this.$messaging.onMessage((payload) => {
            console.log("Message received. ...", payload);
            alert(payload.notification.body);
          });
          console.log("Registered onMessage listener");
        })
        .catch((err) => {
          console.log("Unable to notify", err);
        });
    }
  },
};
</script>

<style>
html,
body {
  background-color: #fbfbfb;
}
</style>
