<template>
  <v-tabs-items v-model="currProblemTab">
    <v-tab-item>
      <div v-if="questionType === 'PROBLEM'">
        <v-card elevation="0" height="650px" class="">
          <v-card-text style="overflow: auto; height: 100%;">
            <div class="pt-5" v-html="questionStatement"></div>
            <v-row>
              <v-col :cols="6">
                <stats-col title="Time Limit">
                  {{
                    currentQuestion
                      ?.getMetaData()
                      ?.getProblemMeta()
                      .getConstraints()
                      .getTimeLimitInMs()
                  }}
                  ms
                </stats-col>
              </v-col>
              <v-col :cols="6">
                <stats-col title="Memory Limit">
                  {{
                    currentQuestion
                      ?.getMetaData()
                      ?.getProblemMeta()
                      .getConstraints()
                      .getMemoryLimitInKb()
                  }}
                  KiB
                </stats-col>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>

      <v-card v-else elevation="0" height="650px" class="">
        <v-card-text style="overflow: auto; height: 100%">
          <div class="text-body-1 pt-8" v-html="questionStatement" />
        </v-card-text>
      </v-card>
    </v-tab-item>
    <v-tab-item v-if="currentQuestion.getMetaData()?.getProblemMeta()?.getEvaluationType() === 1">
      <code-view :value="currentQuestion?.getDataView()?.getProblemView().getSqlInitView()" lang="MY_SQL_8" :availableLanguages="availableLanguages"></code-view> 
    </v-tab-item>
    <v-tab-item>
      <TestProblemSubmission
        :id="currentQuestion?.getId()"
        :userId="userId"
      ></TestProblemSubmission>
    </v-tab-item>
  </v-tabs-items>
</template>
<script>
import TestProblemSubmission from "./TestProblemSubmissions.vue";
import StatsCol from "../../StatsCol.vue";
import CodeView from "../../CodeView.vue";
import { mapGetters } from "vuex";
export default {
  components: { StatsCol, TestProblemSubmission, CodeView},
  props: {
    currProblemTab: {
      type: Number,
      required: true,
    },
    questionType: {
      type: String,
      required: true,
    },
    currentQuestion: {
      type: Object,
      required: true,
    },
    questionStatement: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      problemTab: null,
    };
  },
  computed: {
    ...mapGetters("user", ["userId"]),
    availableLanguages() {
      return  [
        { text: 'MySQL 8', value: 'MY_SQL_8' },
      ]
    },
  },
};
</script>
<style scoped>
.problem-tabs {
  border: 0.5px solid #ececec;
  border-radius: 0.5rem;
  padding-top: 16px;
}
</style>
