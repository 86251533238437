var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pt-2",
    attrs: {
      "elevation": "0"
    }
  }, [_c('HiringCodeEditor', {
    attrs: {
      "codeAction": _vm.codeAction,
      "allowedLanguages": _vm.allowedLanguages,
      "evaluationType": _vm.evaluationType
    },
    on: {
      "setLanguage": _vm.setLanguage,
      "resetCode": _vm.removeOldCode
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        var _vm$currentQuestion$g, _vm$currentQuestion$g2, _vm$currentQuestion$g3, _vm$currentQuestion$g4, _vm$currentQuestion$g5, _vm$currentQuestion$g6, _vm$currentQuestion$g7, _vm$currentQuestion$g8;

        return [_c('div', {
          staticClass: "code-actions mt-2"
        }, [_c('v-row', {
          staticClass: "code-action-buttons d-flex",
          attrs: {
            "text": "",
            "outlined": ""
          }
        }, [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "ml-auto mr-2 test-run",
                attrs: {
                  "text": "",
                  "outlined": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.problemAction('TEST_RUN');
                  }
                }
              }, 'v-btn', attrs, false), on), [_vm._v(" Run Code ")])];
            }
          }])
        }, [_c('span', [_vm._v(" Runs only for sample test cases. This submission won't be considered for scoring. ")])]), _c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "full-run",
                attrs: {
                  "text": "",
                  "outlined": "",
                  "color": "success"
                },
                on: {
                  "click": function click($event) {
                    return _vm.problemAction('FULL_RUN');
                  }
                }
              }, 'v-btn', attrs, false), on), [_vm._v(" Submit ")])];
            }
          }])
        }, [_c('span', [_vm._v(" Runs on all the test cases including hidden ones. On success, it shows the score based on how many test cases passed. ")])]), _vm.codeAction !== 'HIDDEN' ? _c('v-btn', {
          attrs: {
            "icon": "",
            "outlined": "",
            "color": "#C4C4C4"
          },
          on: {
            "click": function click($event) {
              return _vm.problemAction('HIDDEN');
            }
          }
        }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.mdiChevronDown) + " ")])], 1) : _vm._e()], 1), _c('div', {
          staticClass: "code-action-content",
          attrs: {
            "transition": "slide-y-transition"
          }
        }, [_vm.codeAction === 'CUSTOM_RUN' ? _c('v-row', [_c('v-col', {
          attrs: {
            "cols": "8"
          }
        }, [_vm.hasWrappedIo || ((_vm$currentQuestion$g = _vm.currentQuestion.getMetaData()) === null || _vm$currentQuestion$g === void 0 ? void 0 : (_vm$currentQuestion$g2 = _vm$currentQuestion$g.getProblemMeta()) === null || _vm$currentQuestion$g2 === void 0 ? void 0 : _vm$currentQuestion$g2.getEvaluationType()) === 1 ? _c('span', [_vm._v(" Custom Input disabled for this problem. ")]) : _vm._e(), _c('v-textarea', {
          attrs: {
            "clearable": "",
            "rows": "3",
            "disabled": _vm.hasWrappedIo || ((_vm$currentQuestion$g3 = _vm.currentQuestion.getMetaData()) === null || _vm$currentQuestion$g3 === void 0 ? void 0 : (_vm$currentQuestion$g4 = _vm$currentQuestion$g3.getProblemMeta()) === null || _vm$currentQuestion$g4 === void 0 ? void 0 : _vm$currentQuestion$g4.getEvaluationType()) === 1,
            "outlined": ""
          },
          model: {
            value: _vm.userInput,
            callback: function callback($$v) {
              _vm.userInput = $$v;
            },
            expression: "userInput"
          }
        })], 1), _c('v-col', {
          staticClass: "d-flex flex-column justify-end",
          attrs: {
            "cols": "4"
          }
        }, [_c('v-btn', {
          attrs: {
            "disabled": _vm.hasWrappedIo || ((_vm$currentQuestion$g5 = _vm.currentQuestion.getMetaData()) === null || _vm$currentQuestion$g5 === void 0 ? void 0 : (_vm$currentQuestion$g6 = _vm$currentQuestion$g5.getProblemMeta()) === null || _vm$currentQuestion$g6 === void 0 ? void 0 : _vm$currentQuestion$g6.getEvaluationType()) === 1
          },
          on: {
            "click": function click($event) {
              _vm.userInput = '';
            }
          }
        }, [_vm._v(" Clear ")]), _c('v-btn', {
          staticClass: "mt-2 mb-8",
          attrs: {
            "disabled": _vm.hasWrappedIo || ((_vm$currentQuestion$g7 = _vm.currentQuestion.getMetaData()) === null || _vm$currentQuestion$g7 === void 0 ? void 0 : (_vm$currentQuestion$g8 = _vm$currentQuestion$g7.getProblemMeta()) === null || _vm$currentQuestion$g8 === void 0 ? void 0 : _vm$currentQuestion$g8.getEvaluationType()) === 1,
            "color": "success"
          },
          on: {
            "click": function click($event) {
              return _vm.testRun();
            }
          }
        }, [_vm._v(" Custom Run ")])], 1)], 1) : _vm._e(), _vm.codeAction === 'CUSTOM_RUN' && !_vm.hideStatus ? _c('div', {
          staticClass: "mt-5 px-2 custom-submission-overflow"
        }, [_c('CustomTestProblemSubmissionResults')], 1) : _vm.codeAction === 'TEST_RUN' ? _c('div', {
          staticClass: "mt-5 px-2 submission-overflow"
        }, [_c('TestProblemSubmissionResults')], 1) : _vm.codeAction === 'FULL_RUN' ? _c('div', {
          staticClass: "mt-5 submission-overflow px-2"
        }, [_c('TestProblemSubmissionResults')], 1) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.problemSolCode,
      callback: function callback($$v) {
        _vm.problemSolCode = $$v;
      },
      expression: "problemSolCode"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }