<template>
    <v-row>
        <v-col cols="6">
        </v-col>
        <v-col cols="1">
            <v-progress-circular  v-if="showLoader" color="accent" indeterminate size="70" width="10"></v-progress-circular>
        </v-col>
    </v-row>
</template>

<script>
export default {
  data() {
      return {
        "showLoader": false,
      };
  },
  name: "loader",
  mounted() {
      setTimeout(() => {
          this.showLoader = true;
      }, 1000);
  },
};
</script>
