<template>
<span>
		<v-row v-if="completed >= 1">
			<v-col>
				<status-chart :dashboardView="dashboardView"></status-chart>
			</v-col>
		</v-row>
		<v-row v-else>
			<v-col>
				<status-card startNow="/problems" :height=83 :width=131 title="Progress" cardText="You need to solve atleast 1 problem to start seeing status here" imageName="Analyze"></status-card>
			</v-col>
		</v-row>
</span>
</template>
<script>
import StatusCard from "../components/StatusCard";
import StatusChart from "../components/StatusChart";

export default {
	props: {
		dashboardView: {
			type: Object,
			required: true,
		},
	},
	computed: {
		completed() {
			if(this.dashboardView)
				return this.dashboardView.getOverAllStatusView().getEasy() + this.dashboardView.getOverAllStatusView().getMedium() + this.dashboardView.getOverAllStatusView().getHard();
		},
	},
	components: {
		StatusCard,
		StatusChart,
	},
}
</script>