var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page', {
    attrs: {
      "title": _vm.title,
      "apiStatusList": [_vm.getDashboardStatus],
      "loginRequired": "",
      "minimal": ""
    }
  }, [_c('v-row', {
    staticClass: "ml-4"
  }, [_c('v-col', {
    attrs: {
      "cols": 12
    }
  }, [_c('LiveContestList'), _c('v-row', {
    staticClass: "mt-12"
  }, [_c('v-col', {
    staticClass: "font-weight-bold text-truncate text-h5"
  }, [_vm._v(" Status ")])], 1), _c('over-all-status', {
    attrs: {
      "dashboardView": _vm.dashboardView
    }
  }), _c('v-row', {
    staticClass: "mt-10"
  }, [_c('v-col', {
    staticClass: "font-weight-bold text-truncate text-h5"
  }, [_vm._v(" Problems ")])], 1), _c('v-row', [_c('v-col', [_vm._v(" Solve problems that have been recommended just for you. You can also check out "), _c('a', {
    staticClass: "dashboard_link",
    attrs: {
      "href": "/problems"
    }
  }, [_vm._v(" all problems ")]), _vm._v(". ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 10
    }
  }, [_vm.dashboardView ? _c('problem-list', {
    attrs: {
      "problems": _vm.dashboardView.getRecommendedProblemPreviewsList(),
      "autopick": false,
      "showOption": false,
      "hideFooter": true
    }
  }) : _vm._e()], 1)], 1), _c('v-row', {
    staticClass: "mt-12 "
  }, [_c('v-col', [_c('span', {
    staticClass: "font-weight-bold text-truncate text-h5"
  }, [_vm._v(" Drills Status ")])])], 1), _c('drill-status', {
    attrs: {
      "dashboardView": _vm.dashboardView
    }
  }), _c('v-row', {
    staticClass: "mt-12"
  }, [_c('v-col', {
    staticClass: "font-weight-bold text-truncate text-h5"
  }, [_vm._v(" Skill Insights ")])], 1), _c('skill-analysis', {
    attrs: {
      "dashboardView": _vm.dashboardView
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }