import user from "codedrills_proto/io/codedrills/proto/user/user_service_grpc_web_pb";
import hire from "codedrills_proto/io/codedrills/proto/user/hiring_service_grpc_web_pb";
import builder from "codedrills_proto/io/codedrills/proto/builder/content_service_grpc_web_pb";
import site from "codedrills_proto/io/codedrills/proto/site/content_view_service_grpc_web_pb";

import "codedrills_proto/io/codedrills/proto/site/content_view_service_pb";
import { ApiCall } from "@/utils/api.js";
import customer from "codedrills_proto/io/codedrills/proto/user/customer_service_grpc_web_pb";

const userService = new user.UserServicePromiseClient(
  process.env.VUE_APP_USER_API_URL + "/user",
  null,
  null
);

const customerService = new customer.CustomerServicePromiseClient(
  process.env.VUE_APP_USER_API_URL + "/user",
  null,
  null
);

const hiringService = new hire.HiringServicePromiseClient(
  process.env.VUE_APP_USER_API_URL + "/user",
  null,
  null
);

const builderService = new builder.ContentServicePromiseClient(
  process.env.VUE_APP_BUILDER_API_URL + "/builder",
  null,
  null
);
const siteService = new site.ContentViewServicePromiseClient(
  process.env.VUE_APP_SITE_API_URL + "/site",
  null,
  null
);

var site_proto = proto.io.codedrills.proto.site;
var user_proto = proto.io.codedrills.proto.user;
var builder_proto = proto.io.codedrills.proto.builder;
var content_proto = proto.io.codedrills.proto.content;
var customer_proto = proto.io.codedrills.proto.user;

const getDefaultState = () => ({
  allJobs: null,
  fetchAllJobsStatus: null,
  currentlyViewingJob: null,
  createJobResponse: null,
  jobSkills: null,
  job: null,
  fetchSingleJobStatus: null,
  jobView: null,
  allJobCandidates: null,
  getContentStatus: 0,
});

const state = getDefaultState();

const getters = {
  jobTypeKeys() {
    return Object.keys(content_proto.JobType);
  },
  jobPrefKeys() {
    return Object.keys(content_proto.JobPreference);
  },
};

const actions = {
  fetchCustomerAllJobs: new ApiCall("fetchCustomerAllJobs")
    .authRequired()
    .withServiceCall((r, h) => hiringService.fetchCustomerJobs(r, h))
    .withRequest((_, __, { commit }) => {
      return new user_proto.FetchCustomerJobsRequest();
    })
    .onSuccess(({ commit }, res) =>
      commit("setAllJobs", res?.getJobPreviewList())
    )
    .build(),
  fetchJobSkills: new ApiCall("fetchJobSkills")
    .authRequired()
    .withServiceCall((r, h) => hiringService.getAllJobSkills(r, h))
    .withRequest((_, __, { commit }) => {
      return new user_proto.GetAllJobSkillsRequest();
    })
    .onSuccess(({ commit }, res) =>
      commit("setAllJobSkills", res?.getJobSkillList())
    )
    .build(),
  createJob: new ApiCall("createJob")
    .withServiceCall((r, h) => builderService.createContent(r, h))
    .withRequest(({ title }) => {
      return new builder_proto.CreateContentRequest()
        .setTitle(title)
        .setType(content_proto.ContentType.JOB)
        .setAutoPublish(true);
    })
    .onSuccess(({ commit }, res) => {
      commit("setJobResponse", res?.getContent());
    })
    .build(),

    //this is used in create
  updateJob: new ApiCall("updateJob")
    .withServiceCall((r, h) => builderService.updateContent(r, h))
    .withRequest(
      ({
        about,
        skills,
        role,
        preference,
        minCtc,
        maxCtc,
        minEquity,
        maxEquity,
        benefitsList,
        currency,
        jobType,
        minExp,
        maxExp,
        locationList,
        expiry,
      }) => {
        const skillList = [];
        skills.forEach((skillText) => {
          skillList.push(new content_proto.JobSkill().setSkill(skillText));
        });
        const preferenceList = [];
        preference.forEach((pref) => {
          preferenceList.push(content_proto.JobPreference[pref]);
        });
        const jobData = new content_proto.Job()
          .setAbout(about)
          .setRole(role)
          .setSkillsList(skillList);

        const jobMeta = new content_proto.JobMeta()
          .setCtc(
            new content_proto.Ctc()
              .setMinCtc(minCtc)
              .setMaxCtc(maxCtc)
              .setMinEquity(minEquity)
              .setMaxEquity(maxEquity)
              .setOtherBenefitsList(benefitsList)
              .setCurrency(currency)
          )
          .setJobType(content_proto.JobType[jobType])
          .setMinExperience(minExp)
          .setMaxExperience(maxExp)
          .setLocationList(locationList)
          .setPreferenceList(preferenceList)
          .setExpiryDate(new Date(expiry).getTime());

        // console.log(`jobData`, jobData.toObject());
        return new builder_proto.UpdateContentRequest()
          .setId(state?.createJobResponse?.getId())
          .setTitle(state?.createJobResponse?.getTitle())
          .setVersion(state?.createJobResponse?.getVersion())
          .setData(new content_proto.Data().setJob(jobData))
          .setMetaData(new content_proto.MetaData().setJobMeta(jobMeta))
          .setContentType(content_proto.ContentType.JOB)
          .setPublishType(content_proto.PublishType.PUBLIC)
          .setAutoPublish(true);
      }
    )
    .onSuccess(({ commit }, res) => {
      console.log("Res ..", res)
      commit("setJobView", res.getContentView());
      commit("setJob", res.getContent());
    })
    .build(),
    //this is in edit 
    //TODO: merge with updateJob
  saveUpdatedJob: new ApiCall("saveUpdatedJob")
    .withServiceCall((r, h) => builderService.updateContent(r, h))
    .withRequest(
      (
        {
          about,
          skills,
          role,
          preference,
          minCtc,
          maxCtc,
          minEquity,
          maxEquity,
          benefitsList,
          currency,
          jobType,
          minExp,
          maxExp,
          locationList,
          expiry,
        },
        _,
        { commit }
      ) => {
        const skillList = [];
        skills.forEach((skillText) => {
          skillList.push(new content_proto.JobSkill().setSkill(skillText));
        });
        const preferenceList = [];
        preference.forEach((pref) => {
          preferenceList.push(content_proto.JobPreference[pref]);
        });
        const jobData = new content_proto.Job()
          .setAbout(about)
          .setRole(role)
          .setSkillsList(skillList)
          .setTestId(state?.jobView?.getDataView()?.getJobView()?.getTestId());


        console.log("JobType ..../", jobType)
        console.log("JobTypeproto ..../", content_proto.JobType[jobType])
        
        const jobMeta = new content_proto.JobMeta()
          .setCandidatesApplied(
            state?.jobView?.getMetaData()?.getJobMeta()?.getCandidatesApplied()
          )
          .setCtc(
            new content_proto.Ctc()
              .setMinCtc(minCtc)
              .setMaxCtc(maxCtc)
              .setMinEquity(minEquity)
              .setMaxEquity(maxEquity)
              .setOtherBenefitsList(benefitsList)
              .setCurrency(currency)
          )
          .setJobType(content_proto.JobType[jobType])
          .setMinExperience(minExp)
          .setMaxExperience(maxExp)
          .setLocationList(locationList)
          .setPreferenceList(preferenceList)
          .setExpiryDate(new Date(expiry).getTime());

        // console.log(`jobData`, jobData.toObject());
        console.log("Role is", role)
        return new builder_proto.UpdateContentRequest()
          .setId(state?.jobView?.getId())
          .setTitle(role)
          .setVersion(state?.jobView?.getVersion())
          .setData(new content_proto.Data().setJob(jobData))
          .setMetaData(new content_proto.MetaData().setJobMeta(jobMeta))
          .setContentType(content_proto.ContentType.JOB)
          .setPublishType(content_proto.PublishType.PUBLIC)
          .setAutoPublish(true);
      }
    )
    .onSuccess(({ commit }, res) => {
      commit("setJobView", res.getContentView());
    })
    .build(),
  fetchJob: new ApiCall("fetchJob")
    .withServiceCall((r, h) => siteService.getContentView(r, h))
    .withRequest(({ url }) => {
      return new site_proto.GetContentViewRequest().setContentId(
        new content_proto.ContentId().setContentAddress(
          new content_proto.ContentAddress()
            .setUrl(url)
            .setContentType(content_proto.ContentType.JOB)
        )
      );
    })
    .onSuccess(({ commit }, res) => {
      console.log(res?.toObject());
      commit("setJobView", res.getContentView());
    })
    .build(),

  fetchJobContent: new ApiCall("getContent")
    .withServiceCall((r, h) => builderService.getContent(r, h))
    .withRequest(({ url }) => {
      return new site_proto.GetContentRequest().setContentId(
        new content_proto.ContentId().setContentAddress(
          new content_proto.ContentAddress()
            .setUrl(url)
            .setContentType(content_proto.ContentType.JOB)
        )
      );
    })
    .onSuccess(({ commit }, res) => {
      console.log(res?.toObject());
      commit("setJob", res.getContent());
      commit("setJobView", res.getContentView());
    })
    .build(),
  

  fetchJobCandidates: new ApiCall("fetchJobCandidates")
    .withServiceCall((r, h) => hiringService.getJobCandidates(r, h))
    .withRequest(({ id }) => {
      // return new user_proto.GetJobCandidatesRequest().setJobId(
      //   new content_proto.ContentId().setId(id)
      // );
      return new user_proto.GetJobCandidatesRequest().setJobId(id);
    })
    .onSuccess(({ commit }, res) => {
      commit("setAllJobCandidates", res?.getJobCandidateList());
    })
    .build(),
    
  linkJobWithTest: new ApiCall("linkJobWithTest")
    .withServiceCall((r, h) => hiringService.linkJobWithTest(r, h))
    .withRequest(({ test_id, job_id }) => {
      console.log({ test_id, job_id });
      return new user_proto.LinkJobWithTestRequest()
        .setJobId(job_id)
        .setTestId(test_id);
    })
    .onSuccess(({ commit }, res) => {})
    .build(),
};

const mutations = {
  setAllJobs(state, res) {
    state.allJobs = res;
  },
  createJobStatus(state, status) {},
  fetchCustomerAllJobsStatus(state, status) {
    state.fetchAllJobsStatus = status;
  },
  setCurrentlyViewingJob(state, job) {
    state.currentlyViewingJob = job;
  },
  setJobResponse(state, job) {
    state.createJobResponse = job;
  },
  setAllJobSkills(state, list) {
    state.jobSkills = list;
  },
  updateJobStatus(state, status) {},
  setJobView(state, res) {
    state.jobView = res;
  },
  setJob(state, job) {
    state.job = job;
  },
  setAllJobCandidates(state, candidates) {
    state.allJobCandidates = candidates;
  },
  fetchJobStatus(state, status) {
    state.fetchSingleJobStatus = status;
  },
  setJobUpdate(
    state,
    {
      about,
      skillList,
      role,
      preferenceList,
      minCtc,
      maxCtc,
      minEquity,
      maxEquity,
      benefitsList,
      currency,
      jobType,
      minExp,
      maxExp,
      locationList,
      expiry,
    }
  ) {
    state?.jobView
      ?.getMetaData()
      .getJobMeta()
      .setCtc(
        state?.jobView
          ?.getMetaData()
          ?.getJobMeta()
          ?.getCtc()
          .setMinCtc(minCtc)
          .setMaxCtc(maxCtc)
          .setMinEquity(minEquity)
          .setMaxEquity(maxEquity)
          .setOtherBenefitsList(benefitsList)
          .setCurrency(currency)
      )
      .setJobType(content_proto.JobType[jobType])
      .setMinExperience(minExp)
      .setMaxExperience(maxExp)
      .setLocationList(locationList)
      .setPreferenceList(preferenceList)
      .setExpiryDate(new Date(expiry).getTime());
    console.log(state?.jobView?.toObject(), state?.jobView?.getMetaData());
  },
  fetchJobCandidatesStatus(state, status) {},
  fetchJobSkillsStatus(state, status) {},
  linkJobWithTestStatus(state, status) {},
  saveUpdatedJobStatus(state, status) {},
  getContentStatus(state, status) {
    state.status = status;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
