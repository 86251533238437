<template>
  <page minimal title="Problems" :apiStatusList="[fetchUserContentPreviewsStatus]">
    <problemList :problems="userContentPreviews" :autopick="!!this.$route.query.autopick"></problemList>
  </page>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Page from "@/components/Page.vue"
import ProblemList from "../components/ProblemList"
import TextBlock from "@/components/TextBlock.vue"


export default {
  name: "home",

  methods: {
		...mapGetters("content", ["getContentType"]),
    ...mapActions("content", ["fetchProblems", "fetchUserContentPreviews"]),
  },
  computed: {
    ...mapState("content", ["userContentPreviews", "fetchProblemsStatus", "fetchUserContentPreviewsStatus"]),
  },
  async mounted() {
    this.fetchUserContentPreviews({"tags": ["types/interview"], "contentTypes": [this.getContentType()['PROBLEM']]});
  },
  components: {
    Page,
    ProblemList,
    TextBlock,
  },
};
</script>
<style scoped>

</style>
