<template>
	<span>
		<v-tabs :vertical="$vuetify.breakpoint.smAndDown" color="accent" v-model="tab">
			<v-tab>Description</v-tab>
			<v-tab v-if="contentView.getMetaData()?.getProblemMeta()?.getEvaluationType() === 1">SQL Schema</v-tab>
			<v-tab>Submissions</v-tab>
			<v-tab>Editorial</v-tab>
		</v-tabs>


		<v-tabs-items v-model="tab">
			<v-tab-item>
				<problem-description :parentPreview="parentPreview" :contentView="contentView"></problem-description>
			</v-tab-item>
			<v-tab-item v-if="contentView.getMetaData()?.getProblemMeta()?.getEvaluationType() === 1">
      <code-view :value="contentView.getDataView()?.getProblemView().getSqlInitView()" lang="MY_SQL_8" :availableLanguages="availableLanguages"></code-view> 
    </v-tab-item>
			<v-tab-item>
				<submission-history :parentPreview="parentPreview && parentPreview" :id="contentView.getId()" :userId="userId"></submission-history>
			</v-tab-item>
			<v-tab-item>
				<v-card outlined class="pl-5 pr-2 py-5">
					<html-view :html="view.getEditorialView()"></html-view>
				</v-card>
			</v-tab-item>
		</v-tabs-items>
	</span>
</template>

<script>
import HtmlView from "../components/HtmlView";
import ProblemDescription from "../components/ProblemDescription";
import SubmissionHistory from "../components/SubmissionHistory";
import CodeView from "../components/CodeView.vue";

import { mapGetters, mapActions } from 'vuex';

export default {
	props: {
		"contentView": {
			type: Object,
			required: true,
		},
		"parentPreview": {
			type: Object,
			required: false,
		},
	},
	data() {
			return {
				tab: null,
			};
	},
	computed: {
		...mapGetters("user", ["userId"]),
		view() {
				return this.contentView.getDataView().getProblemView();
		},
		availableLanguages() {
      return  [
        { text: 'MySQL 8', value: 'MY_SQL_8' },
      ]
    },
	},
	methods: {
		loadInfo(ncv) {
			var parent = null;
			if(this.parentPreview) {
				parent = this.parentPreview.getId()
			}
		},
		copyText () {
			navigator.clipboard.writeText(this.$refs.foo.$el.innerHTML)
		},
	},
	watch: {
		contentView:  function(ncv) {
			this.loadInfo(ncv);
		}
	},
	mounted() {
		this.loadInfo(this.contentView);
	},
	components: {
			HtmlView,
			ProblemDescription,
			SubmissionHistory,
			CodeView,
	},
};
</script>

<style scoped>

.tabs-active {
	background: #FBFBFB;
	border-radius: 5px;
	margin: 7px 0;
}

.tabs {
	width: auto;
	display: inline-block;
}

.tabs .v-tab::before {
	margin: 7px 0;

}

.v-tabs--vertical > .v-tabs-bar .v-tab {
    min-width: 100%;
}

</style>
