<template>
	<span>
  <v-app-bar flat color="white" height="75">
    <v-row v-if="$vuetify.breakpoint.smAndDown" align="center">
			<v-col :xs="1">
				<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			</v-col>
			<v-spacer></v-spacer>
			<v-col>
				<router-link to="/community">
					<img alt="[C]" height=25 width=50 :src="require('../assets/images/CodeDrillsLight.svg')" />
				</router-link>
			</v-col>
    </v-row>

		<v-row v-else >
      <v-col :md="1" :lg="1">
          <router-link to="/">
            <img alt="[C]" height=35 width=50 :src="require('../assets/images/CodeDrillsLight.svg')" />
          </router-link>
      </v-col>
			<v-spacer></v-spacer>
      <v-col :md="1" class="d-none d-md-flex">
        <span v-if="!isProd">
          [{{env}}]
        </span>
        <v-alert dense outlined type="error" v-if="user && !isEmailVerified">
          <span class="font-weight-light text-color-white"> <a href="#" @click.prevent="sendVerifyEmail">Verify your email</a></span>
				</v-alert>
			</v-col>
      <v-col :md="1" class="d-none d-md-flex">
        <span>
          <router-link class="mr-5 menu_item title text-md-subtitle-1" to="/">Companies</router-link>
        </span>
        <v-alert dense outlined type="error" v-if="user && !isEmailVerified">
          <span class="font-weight-light text-color-white"> <a href="#" @click.prevent="sendVerifyEmail">Verify your email</a></span>
				</v-alert>
			</v-col>
			<v-spacer></v-spacer>
      <v-col :md="8" :lg="8" :xl="6" class="menu_item d-flex justify-center">
        <router-link class="mr-5 menu_item title text-md-subtitle-1" to="/problems">Interview Problems</router-link>
        <router-link class="mr-5 menu_item title text-md-subtitle-1" to="/drills">Drills</router-link>
        <router-link class="mr-5 menu_item title text-md-subtitle-1" to="/competitive">Competitive Problems</router-link>
        <router-link class="mr-5 menu_item title text-md-subtitle-1" to="/contests">Contests</router-link>
        <v-menu offset-y auto bottom nudge-top>
          <template v-slot:activator="{on, attrs}">
            <span v-bind="attrs" v-on="on" class="mr-5 menu_item title text-md-subtitle-1">
              ICPC
              <v-icon>{{mdiMenuDown}}</v-icon>
            </span>
          </template>
          <v-list class="title text-md-subtitle-1">
            <!-- <v-list-item> <v-list-item-title>
                <a class="mr-5 menu_item subtitle" href="https://discuss.codedrills.io/t/indian-icpc-regionals-2020/742">Indian Regionals 2020</a>
            </v-list-item-title> </v-list-item> -->
            <v-list-item> <v-list-item-title>
                <router-link class="mr-5 menu_item title text-md-subtitle-1" to="/amritaicpc">ICPC Amritapuri</router-link>
            </v-list-item-title> </v-list-item>
            <v-list-item> <v-list-item-title>
                <router-link class="mr-5 menu_item title text-md-subtitle-1" to="/gwalioricpc">ICPC Gwalior-Pune</router-link>
            </v-list-item-title> </v-list-item>
          </v-list>
        </v-menu>

        <router-link class="mr-5 menu_item title text-md-subtitle-1" to="/community/jobs">Jobs</router-link>
        <!-- <a class="mr-5 menu_item title text-md-subtitle-1" href="https://discuss.codedrills.io">Discuss</a> -->
      </v-col>
      <v-col v-if="user" :md="1">
        <div class="text-center">
          <v-menu offset-y auto bottom nudge-top>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar color="accent" dark v-bind="attrs" v-on="on">
		<img  v-if="getProfileImage" :src=" getProfileImage" alt="User"  />
	        <span v-else class="white--text text-h5">{{profileText}}</span>
              </v-avatar>
              <v-icon v-bind="attrs" v-on="on">{{mdiMenuDown}}</v-icon>
            </template>
            <v-list>
              <v-list-item> <v-list-item-title>
                  <router-link class="mr-5 menu_item text-uppercase subtitle" to="/profile">Profile</router-link>
              </v-list-item-title> </v-list-item>
              <v-list-item> <v-list-item-title>
                  <router-link class="mr-5 menu_item text-uppercase subtitle" to="/subscription">Settings</router-link>
              </v-list-item-title> </v-list-item>
              <v-list-item> <v-list-item-title>
                  <router-link class="mr-5 menu_item text-uppercase subtitle" to="/logout">Logout</router-link>
              </v-list-item-title> </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
      <v-col v-if="(!user || user.isAnonymous)" :col="1">
        <v-btn color="accent" height="35" @click="login">Login</v-btn>
      </v-col>
    </v-row>
  </v-app-bar>
	<v-navigation-drawer
			absolute
			left
			class="grey lighten-5 px-3 py-3"
			v-model="drawer"
			:permanent="false"
      style="height:"
	>
		<v-list dense class="pt-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>CodeDrills</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
				<v-list-item v-if="user && !isEmailVerified">
          <span class="font-weight-light"> <a href="#" @click.prevent="sendVerificationEmail">Verify your email</a></span>
				</v-list-item>

        <v-divider></v-divider>
        <v-list-item> <v-list-item-title> <router-link class="mr-3 menu_item title" to="/profile">Profile</router-link> </v-list-item-title> </v-list-item>
        <v-list-item> <v-list-item-title> <router-link class="mr-3 menu_item title" to="/problems">Interview</router-link> </v-list-item-title> </v-list-item>
        <v-list-item> <v-list-item-title> <router-link class="mr-3 menu_item title" to="/competitive">Competitive</router-link> </v-list-item-title> </v-list-item>
        <v-list-item> <v-list-item-title> <router-link class="mr-3 menu_item title" to="/drills">Drills</router-link> </v-list-item-title> </v-list-item>
        <!-- <v-list-item> <v-list-item-title> <a class="mr-3 menu_item title" href="https://discuss.codedrills.io/t/indian-icpc-regionals-2020/742">ICPC</a> </v-list-item-title></v-list-item> -->
        <v-list-item> <v-list-item-title> <router-link class="mr-3 menu_item title" to="/amritaicpc">ICPC Amritapuri</router-link> </v-list-item-title></v-list-item>
        <v-list-item> <v-list-item-title> <router-link class="mr-3 menu_item title" to="/gwalioricpc">ICPC Gwalior</router-link> </v-list-item-title></v-list-item>
        <v-list-item> <v-list-item-title> <router-link class="mr-3 menu_item title" to="/contests">Contests</router-link> </v-list-item-title></v-list-item>
        <v-list-item> <v-list-item-title> <router-link class="mr-3 menu_item title" to="/">Companies</router-link> </v-list-item-title></v-list-item>
        <v-list-item> <v-list-item-title> <router-link class="mr-3 menu_item title" to="/community/jobs">Jobs</router-link> </v-list-item-title></v-list-item>
        <!-- <v-list-item> <v-list-item-title> <a class="mr-3 menu_item title" href="https://discuss.codedrills.io">Discuss</a> </v-list-item-title></v-list-item> -->
    </v-list>
    <v-divider></v-divider>
    <v-list dense class="pt-0" justify="end">
      <v-list-item> <v-list-item-title> <router-link class="mr-3 menu_item title" to="mailto:hello@codedrills.io">Contact Us</router-link> </v-list-item-title> </v-list-item>
      <v-list-item> <v-list-item-title> <router-link class="mr-3 menu_item title" to="/blogs">Blog</router-link> </v-list-item-title> </v-list-item>
      <v-list-item> <v-list-item-title> <router-link class="mr-3 menu_item title" to="/career">Career</router-link> </v-list-item-title> </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list-item> <v-list-item-title>
      <v-btn v-if="user && !user.isAnonymous" color="accent" height="35" @click="logoutAction">Logout</v-btn>
      <v-btn v-else color="accent" height="35" @click="login">Login</v-btn>
    </v-list-item-title> </v-list-item>
	</v-navigation-drawer>
	</span>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { mdiMenuDown } from '@mdi/js'

export default {
  name: "navbar",
  data: function() {
    return {
			"drawer": false,
      "mdiMenuDown": mdiMenuDown,
		};
  },
  computed: {
    ...mapState("user", ["user","profile", "initialized"]),
    isProd() {
      return process.env.NODE_ENV === 'production';
    },
    env() {
      return process.env.NODE_ENV;
    },
    getProfileImage() {
      if(this.user && this.profile && this.profile.getProfile().getImageUrl())
          return this.profile.getProfile().getImageUrl();
       else if(this.user && this.user.photoURL)
           return this.user.photoURL;
      else
        return null;
    },

    profileText() {
      if(this.user && this.user.displayName != "") {
        var str = this.user.displayName;
        var matches = str.match(/\b(\w)/g);
        if(!matches)
          return "";
        var acronym = matches.join('');
        acronym=acronym.toUpperCase();
        acronym=acronym.substring(0,2);
        return acronym;
      }
      else
        return "";
    },
		isEmailVerified() {
			console.log("Is Email verified ", this.user && this.user.emailVerified);
			return this.user && this.user.emailVerified;
		}
  },

  watch: {
    user: function(nu) {
      if(nu && !nu.displayName) {
        this.$router.push("/basicProfile");
      }
    }
  },
  async mounted() {
    console.log("user .........", this.user);
    if(this.user) this.getProfile();
  },
  methods: {
    ...mapActions("user", ["logout", "sendVerificationEmail"]),
    ...mapActions('user', ['getProfile']),
    login() {
      this.$router.push("/community/login");
    },
		logoutAction() {
			this.logout().then(__ => location.reload());
    },
		sendVerifyEmail() {
			this.sendVerificationEmail().then(__ => alert("Verification email sent"))
			.catch(ex => {
				alert("Could not send verification email. Please try again.");
				console.log("Error while sending verification mail", ex);
			})
		}
  },
};
</script>

<style scoped>
.menu_item {
    text-decoration: none;
    color: black;
}
</style>
