//import orb from '../../gen/orb_grpc_web_pb';
//import "../../gen/orb_pb";
import site from "codedrills_proto/io/codedrills/proto/site/content_view_service_grpc_web_pb";
import builder from "codedrills_proto/io/codedrills/proto/builder/content_service_grpc_web_pb";
import user from "codedrills_proto/io/codedrills/proto/user/user_service_grpc_web_pb";
import hire from "codedrills_proto/io/codedrills/proto/user/hiring_service_grpc_web_pb";

import "codedrills_proto/io/codedrills/proto/user/hiring_service_pb"
import "codedrills_proto/io/codedrills/proto/site/content_view_service_pb";
import "codedrills_proto/io/codedrills/proto/content/content_pb";
import { ApiCall } from "@/utils/api.js"

var site_proto = proto.io.codedrills.proto.site;
var content_proto = proto.io.codedrills.proto.content;
var builder_proto = proto.io.codedrills.proto.builder;
var user_proto = proto.io.codedrills.proto.user;

const siteService = new site.ContentViewServicePromiseClient(process.env.VUE_APP_SITE_API_URL + "/site", null, null);
const builderService = new builder.ContentServicePromiseClient(process.env.VUE_APP_BUILDER_API_URL + "/builder", null, null);
const userService = new user.UserServicePromiseClient(process.env.VUE_APP_USER_API_URL + "/user", null, null);
const hiringService = new hire.HiringServicePromiseClient(process.env.VUE_APP_USER_API_URL + "/user", null, null);

const state = {
    problems: null,
    contentView: null,
    contestPreviews: null,
    scoreboard: null,
    parentPreview: null,
    contentPreview: null,
		contentPreviews: null,
		content: null,
		testPreviews: null,
		allContentUserActivity: null,
		userContentPreviews: null,
    fetchProblemsStatus: 0,
    fetchContentViewStatus: 0,
    fetchContentPreviewsStatus: 0,
    fetchContestScoreboardStatus: 0,
		createContentStatus: 0,
		fetchTestPreviewsStatus: 0,
		fetchAllContentUserActivityStatus: 0,
		inviteCandidateStatus: 0,
		inviteCandidatesStatus: 0,
		fetchTestCandidatesStatus: 0,
		testCandidates: null,
		startTestStatus: 0,
		endTestStatus: 0,
	  updateTestDurationStatus: 0,
		fetchContentStatus: 0,
		updateContentStatus: 0,
		fetchUserContentPreviewsStatus: null,
		getContentPreviewStatus: null,
		userActivity: null,
		fetchUserActivityStatus: 0,
};

const actions = {
		fetchUserContentPreviews: new ApiCall("fetchUserContentPreviews")
				.authOptional()
				.withServiceCall((r, h) => siteService.userContentPreviews(r, h))
				.withRequest(({tags, contentTypes, contentIds}) => {
						var req = new site_proto.UserContentPreviewsRequest();
						if(tags) {
							req.setFilterByTags(true);
							req.setTagsList(tags);
						}
						if(contentTypes) {
							req.setFilterByContentType(true);
							req.setTypesList(contentTypes);
						}
						if(contentIds) {
							req.setFilterByIds(true);
							req.setContentIdsList(contentIds);
						}
						return req;
				})
				.onSuccess((({commit}, res) => commit("setUserContentPreviews", res)))
				.build(),

		fetchContentView: new ApiCall("fetchContentView")
			.authOptional()
			.withServiceCall((r, h) => siteService.getContentView(r, h))
			.withRequest(({ parentType, parentUrl, type, url, hideChildTags }) => {
				console.log("cv", type);
				console.log("cv", url);
				var req = new site_proto.GetContentViewRequest()
					.setContentId(
						new content_proto.ContentId()
							.setContentAddress(
								new content_proto.ContentAddress()
									.setUrl(url)
									.setContentType(content_proto.ContentType[type])
							)
					);
				if(parentType && parentUrl) {
					req.setParentId(
						new content_proto.ContentId()
							.setContentAddress(
								new content_proto.ContentAddress()
									.setUrl(parentUrl)
									.setContentType(content_proto.ContentType[parentType])
							)
					);
				}
				return req;
			})
			.onSuccess(({ commit }, res) => {
				commit("setContentView", res.getContentView())
				commit("setParentPreview", res.getParentPreview())
			})
			.build(),

		fetchContentPreview: new ApiCall("getContentPreview")
			.authOptional()
			.withServiceCall((r, h) => siteService.getContentPreview(r, h))
			.withRequest(({contentId}) => {
				return new site_proto.GetContentPreviewRequest()
				.setContentId(
						new content_proto.ContentId()
							.setId(contentId)
					);
			})
			.onSuccess(({ commit }, res) => commit("setContentPreview", res.getContentPreview()))
			.build(),

		fetchContentPreviews: new ApiCall("fetchContentPreviews")
			.authOptional()
			.withServiceCall((r, h) => siteService.listContent(r, h))
			.withRequest(({type, tags}) => {
				return new site_proto.ListContentRequest()
							.setContentType(content_proto.ContentType[type])
							.setTagsList(tags)
			})
			.onSuccess(({ commit }, res) => commit("setContentPreviews", res.getContentsList()))
			.build(),

		fetchContestScoreboard: new ApiCall("fetchContestScoreboard")
			.withServiceCall((r, h) => siteService.getContestScoreboard(r, h))
			.withRequest(({ url, paginationQuery}) => {
				console.log("pq: ", paginationQuery);
				var paginationRequest = new content_proto.PaginationQuery()
								.setItemsPerPage(paginationQuery.itemsPerPage)
								.setPageIndex(paginationQuery.pageIndex-1);
				if (paginationQuery.search) {
					paginationRequest.setSearchTermList([
						new content_proto.SearchTerm()
						.setFieldName("name")
						.setFieldValue(paginationQuery.search),
						new content_proto.SearchTerm()
						.setFieldName("org")
						.setFieldValue(paginationQuery.search),
					]);
				}
				return new site_proto.GetContestScoreboardRequest()
							.setContestId(new content_proto.ContentId().setContentAddress(new content_proto.ContentAddress()
									.setUrl(url)
									.setContentType(content_proto.ContentType.CONTEST)
								)
							)
							.setPaginationQuery(paginationRequest)
			})
			.onSuccess(({ commit }, res) => commit("setContestScoreboard", res))
			.build(),

		fetchTestPreviews: new ApiCall("fetchTestPreviews")
			.withServiceCall((r, h) => builderService.listContent(r, h))
			.withRequest(() => {
				return new builder_proto.ListContentRequest()
				.setContentType(content_proto.ContentType.TEST);
			})
			.onSuccess(({ commit }, res) => commit("setTestPreviews", res.getContentsList()))
			.build(),

		createContent: new ApiCall("createContent")
					.withServiceCall((r, h) => builderService.createContent(r, h))
					.withRequest(({title}) => {
							return new builder_proto.CreateContentRequest()
									.setTitle(title)
									.setType(content_proto.ContentType.TEST)
					})
					.onSuccess(({commit}, res) => commit("setContent", res))
					.build(),

		publishContent: new ApiCall("publishContent")
				.withServiceCall((r, h) => builderService.publishContent(r,h))
				.withRequest((param) => {
					  var id = (param && param.contentId) || state.content.getId();
						return new builder_proto.PublishContentRequest()
								.setContentId(id)
				})
				.onSuccess((__, res) => console.log("Published", res.toObject()))
				.build(),

		fetchAllContentUserActivity: new ApiCall("fetchAllContentUserActivity")
				.withServiceCall((r, h) => userService.getContentUserActivity(r, h))
				.withRequest(({contentId}) => {
						return new user_proto.GetContentUserActivityRequest()
								.setContentId(new content_proto.ContentId().setId(contentId))
				})
				.onSuccess(({commit}, res)	=> commit("setAllContentUserActivity", res.getUserActivityList()))
				.build(),

		fetchContentUserActivity: new ApiCall("fetchContentUserActivity")
				.withServiceCall((r, h) => userService.getContentUserActivity(r, h))
				.withRequest(({contentId}) => {
						return new user_proto.GetContentUserActivityRequest()
								.setContentId(new content_proto.ContentId().setId(contentId))
				})
				.onSuccess(({commit}, res)	=> commit("setContentUserActivity", res.getUserActivityList()))
				.build(),

		fetchUserActivity: new ApiCall("fetchUserActivity")
				.withServiceCall((r, h) => userService.getUserActivity(r, h))
				.withRequest(({url, userId}) => {
						return new user_proto.GetUserActivityRequest()
								 .setContentIdList([new content_proto.ContentId().setContentAddress(
									  new content_proto.ContentAddress()
											.setUrl(url)
											.setContentType(content_proto.ContentType.TEST))])
								.setUserId(userId);
				})
				.onSuccess(({commit}, res)	=> commit("setUserActivity", res.getActivityList()))
				.build(),


		inviteCandidate: new ApiCall("inviteCandidate")
				.withServiceCall((r, h) => hiringService.testInvite(r, h))
				.withRequest(({id, name, email}) => {
						return new user_proto.TestInviteRequest()
								.setTestId(id)
								.setName(name)
								.setEmail(email)
				})
				.onSuccess(({commit}, res) => {
					// commit("setAllContentUserActivity", res);
					// response is empty, so nothing to do
					// setting value might introduce bugs into some other
					// function implementation
					console.log(`setAllContentUserActivity`, res);
					
				})
				.build(),

		// not yet implemented, as of 14/01/22
		fetchTestCandidates: new ApiCall("fetchTestCandidates")
				.withServiceCall((r, h) => hiringService.getCandidatesForTest(r, h))
				.withRequest(({url}) => {
					return new user_proto.GetCandidatesForTestRequest().setContentId(
						new content_proto.ContentId().setContentAddress(
							new content_proto.ContentAddress()
									.setUrl(url)
									.setContentType(content_proto.ContentType.TEST)
						)
					);
				})
				.onSuccess(({commit}, res) => {
					console.log("fetchTestCandidates:", res);
					commit("setTestCandidates", res);
				})
				.build(),

		startTest: new ApiCall("startTest")
				.withServiceCall((r, h) => userService.startTest(r, h))
				.withRequest(({url}) => {
						return new user_proto.ChangeTestActivityRequest()
								.setContentId(
										new content_proto.ContentId().setContentAddress(
											new content_proto.ContentAddress()
													.setUrl(url)
													.setContentType(content_proto.ContentType.TEST)
										)
								);
				})
				.onSuccess()
				.build(),

		endTest: new ApiCall("endTest")
				.withServiceCall((r, h) => userService.endTest(r, h))
				.withRequest(({url}) => {
						return new user_proto.ChangeTestActivityRequest()
								.setContentId(
										new content_proto.ContentId().setContentAddress(
											new content_proto.ContentAddress()
													.setUrl(url)
													.setContentType(content_proto.ContentType.TEST)
										)
								);
				})
				.onSuccess()
				.build(),

	updateTestActivity: new ApiCall("updateTestActivity")
		.withServiceCall((r, h) => userService.updateTestActivity(r, h))
				.withRequest(({url, questionId, answer}) => {
					return new user_proto.UpdateTestActivityRequest()
					.setContentId(
						new content_proto.ContentId().setContentAddress(
							new content_proto.ContentAddress()
							.setUrl(url)
							.setContentType(content_proto.ContentType.TEST)
							)
						)
						.setQuestionId(questionId)
						.addOptionId(answer)
				})
				.onSuccess(({commit}, res) => {
					console.log("Updated ansewer ....")
				})
				.build(),

		updateTestDuration: new ApiCall("updateTestDuration")
				.withServiceCall((r, h) => builderService.updateTestDuration(r, h))
				.withRequest(({id, durationInS}) => {
						return new builder_proto.UpdateTestDurationRequest()
								.setContentId(new content_proto.ContentId().setId(id))
								.setDurationInS(durationInS)
				})
				.onSuccess(({commit}, res) => {
					commit("setContent", res.getContent());
          commit("setContentView", res.getContentView());
				})
				.build(),

				
		fetchContent: new ApiCall("fetchContent")
				.withServiceCall((r, h) => builderService.getContent(r, h))
				.withRequest(({id}) => {
						return new builder_proto.GetContentRequest()
								.setContentId(new content_proto.ContentId().setId(id))
				})
				.onSuccess(({commit}, res) => commit("setContent", res.getContent()))
				.build(),

		updateContent: new ApiCall("updateContent")
				.withServiceCall((r, h) => builderService.updateContent(r, h))
				.withRequest(({autoPublish}) => {
						return new builder_proto.UpdateContentRequest()
								.setId(state.content.getId())
								.setTitle(state.content.getTitle())
								.setVersion(state.content.getVersion())
								.setMetaData(state.content.getMetaData())
								.setData(state.content.getData())
								.setPublishType(state.content.getPublishType())
								.setContentType(state.content.getContentType())
								.setAutoPublish(autoPublish)
				})
				.onSuccess(({commit}, res) => {
					commit("setContent", res.getContent());
          commit("setContentView", res.getContentView())
				})
				.build(),
};

const getters = {
    problems: state => {
        return state.problems;
    },
		statusKeys() {
				var retStatus = {};
				for(var key in user_proto.ActivityStatus) {
					retStatus[user_proto.ActivityStatus[key]] = key;
				}
				return retStatus;
		},
		getContentType() {
			return content_proto.ContentType;
		},
		getFieldPresenceList(){
			return Object.keys(content_proto.FieldPresence);
		},
		getTestTypes(){
			return content_proto.TestMode;
		},
		getContestProblems(){
			const difficulties = ["easy", "medium", "hard"]
			const kv = {"easy": 0, "medium": 0, "hard": 0};
			if (state.content.getData() && state.content.getData().getTest() && state.content.getData().getTest().getTestSettings() && state.content.getData().getTest().getTestSettings().getRandomSelectionSettings){
				console.log('how deep do i need to go');
				const psmap = state.content.getData().getTest().getTestSettings().getRandomSelectionSettings().getProblemSelectionMap();
				difficulties.forEach((diff) => {
					if (psmap && psmap.get(diff))
						kv[diff] = psmap.get(diff).getCount();
					else
						kv[diff] = 0;
				})
			}
			return kv;
		},
		getContestMcqs(){
			const kv = {};
			if (state.content.getData() && state.content.getData().getTest() && state.content.getData().getTest().getTestSettings() && state.content.getData().getTest().getTestSettings().getRandomSelectionSettings){
				const keys = state.content.getData().getTest().getTestSettings().getRandomSelectionSettings().getMcqSelectionMap().keys();
				console.log(`mcq keys`, keys.arr_);
				keys.arr_.forEach((k) => {
					kv[k] = state.content.getData().getTest().getTestSettings().getRandomSelectionSettings().getMcqSelectionMap().get(k).getCount() || 0;
				});
			}
			return kv;
		},
};

const mutations = {
	setAllContestQuestions(state, allQuestions){
		allQuestions.forEach((val, idx) => {
			console.log(`q${idx}`, val)
			if (val.type === "Problem") {
				state.content.getData().getTest().getTestSettings().getRandomSelectionSettings()
				.getProblemSelectionMap().set(val.rating,
				new content_proto.RandomPartSelection().setCount(Number(val.count)))
			}
			else if (val.type === "MCQ") {
				// console.log(`setting mcq`, val.rating, val.count);
				state.content.getData().getTest().getTestSettings().getRandomSelectionSettings()
				.getMcqSelectionMap().set(Number(val.rating),
				new content_proto.RandomPartSelection().setCount(Number(val.count)))
			}
		})
	},
    setProblems(state, problems) {
        console.log("problems", problems);
        state.problems = problems;
    },
    setContentView(state, contentView) {
        state.contentView = contentView;
    },
    setParentPreview(state, parentPreview) {
        state.parentPreview = parentPreview;
    },
    clearContentView(state) {
        state.contentView = null;
        state.parentPreview = null;
        state.fetchContentViewStatus = 0;
    },
    setContentPreview(state, contentPreview) {
        state.contentPreview = contentPreview;
    },
    setContentPreviews(state, contents) {
				console.log("CPs ..", contents);
        state.contentPreviews = contents;
    },
    setContestScoreboard(state, res) {
				console.log("Scoreboard response .. ", res)
        state.scoreboard = res.getScoreboardSnapshot();
    },
	setDefaultContent(state){
		// call updateContent after setting default from component
		if (!(typeof state.content.getData !== 'function' &&
			state.content.getData && 
			state.content.getData().getTest && 
			state.content.getData().getTest().getTestSettings)
		){
			console.log('defaults content missing', state.content);
			state.content.getData().getTest().setTestSettings(
				new content_proto.TestSettings().setRandomSelectionSettings(
					new content_proto.RandomSelectionSettings()
				).setTestMode(
					content_proto.TestMode.TEST_MODE_UNDEFINED
				)
			)
		}
	},
	setContent(state, content) {
		state.content = content;
		console.log('setting content: ', state.content);
	},
		setTestProblems(state, problems) {
			var questions = [];
			console.log("Questions", problems);
			for(const q of state.content.getData().getTest().getTestQuestionList()) {
				console.log("q..", q);
				var id = q.getProblem() && q.getProblem().getContentId();
				if(!q.getProblem()) {
					questions.push(q);
				}
				const idx = problems.indexOf(id);
				if(idx !== -1) {
					problems.splice(idx, 1);
					questions.push(q);
				}
			}
			for(const id of problems) {
				console.log("id", id);
				questions.push(new content_proto.TestQuestion()
				.setProblem(new content_proto.TestQuestionProblem()
					.setContentId(id)
				)
				.setScore(100));
			}
			state.content.getData().getTest()
			.setTestQuestionList(questions);
		},
		removeProblem(state, id) {
			var questions = state.content.getData().getTest().getTestQuestionList();
			const index = questions.findIndex(q => q.getProblem() && q.getProblem().getContentId() === id);
			console.log("Finding ", id, index);
			if (index >= 0) questions.splice(index, 1);
			state.content.getData().getTest().setTestQuestionList(questions);
		},
		addMcq(state, mcq) {
			var options = [];
			var answers = [];
			for(var i = 0; i < mcq.options.length; ++i) {
				options.push(new content_proto.TestQuestionOption()
				.setId(i.toString())
				.setStatement(mcq.options[i].statement));
				if(options[i].isAnswer) {
					answers.push(i.toString());
				}
			}
			var mcq = new content_proto.TestQuestionMCQ()
			.setStatement(mcq.statement)
			.setOptionList(options);
			console.log("mcq", mcq.toObject());
			state.content.getData().getTest().addTestQuestion(
				new content_proto.TestQuestion()
				.setMcq(mcq)
				.setScore(1)
			);
		},
		removeMcq(state, id) {
			var questions = state.content.getData().getTest().getTestQuestionList();
			const index = questions.findIndex(q => {
				return q.getQuestionId() === id;
			});
			if(index >= 0) questions.splice(index, 1);
			state.content.getData().getTest().setTestQuestionList(questions);
		},
		updateQuestionScore(state, {id, score}) {
			var questions = state.content.getData().getTest().getTestQuestionList();
			const index = questions.findIndex(q => {
				return q.getQuestionId() === id;
			});
			if(index >= 0) {
				state.content.getData().getTest().getTestQuestionList()[index].setScore(score);
			}
		},
    setTestPreviews(state, contests) {
        state.testPreviews = contests;
    },
		setAllContentUserActivity(state, allContentUserActivity) {
				state.allContentUserActivity = allContentUserActivity;
		},
		setUserContentPreviews(state, userContentPreviews) {
				state.userContentPreviews = userContentPreviews.getContentPreviewsList();
		},
    fetchProblemsStatus(state, status) {
        state.fetchProblemsStatus = status;
    },
    fetchContentViewStatus(state, status) {
        state.fetchContentViewStatus = status;
    },
    fetchContentPreviewsStatus(state, status) {
        state.fetchContentPreviewsStatus = status;
    },
    fetchContestScoreboardStatus(state, status) {
        state.fetchContestScoreboardStatus = status;
    },
    fetchTestPreviewsStatus(state, status) {
        state.fetchTestPreviewsStatus = status;
    },
		createContentStatus(state, status) {
				state.createContentStatus = status;
		},
		fetchAllContentUserActivityStatus(state, status) {
				state.fetchAllContentUserActivityStatus = status;
		},
		publishContentStatus(state, status) {
				state.publishContentStatus = status;
		},
		fetchTestCandidatesStatus(state, status){
			state.fetchTestCandidatesStatus = status;
		},
		setTestCandidates(state, candidates){
			state.testCandidates = candidates;
		},
		startTestStatus(state, status) {
				state.startTestStatus = status;
		},
		endTestStatus(state, status) {
				state.endTestStatus = status;
		},
		updateTestDurationStatus(state, status) {
				state.updateTestDurationStatus = status;
		},
		fetchContentStatus(state, status) {
				state.fetchContentStatus = status;
		},
		updateContentStatus(state, status) {
				state.updateContentStatus = status;
		},
		fetchUserContentPreviewsStatus(state, status) {
				state.fetchUserContentPreviewsStatus = status;
		},
		getContentPreviewStatus(state, status) {
				state.getContentPreviewStatus = status;
		},
		setUserActivity(state, userActivityList) {
			if(userActivityList.length == 0) state.userActivity = null;
			state.userActivity = userActivityList[0];
		},
		fetchUserActivityStatus(state, status) {
			state.fetchUserActivityStatus = status;
		}
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
