var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-row', [_c('v-col', [_vm._v(" Stay on the top of your game, track your performance in various topics ")])], 1), _vm.completed >= 10 ? _c('v-row', [_c('v-col', [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold text-truncate text-h6"
  }, [_vm._v(" Strengths ")])], 1), _c('v-row', _vm._l(_vm.dashboardView.getStrengthsList(), function (skill) {
    return _c('v-col', {
      key: skill.getDrillPreview().getContentPreview().getId(),
      attrs: {
        "xs": 12,
        "sm": 6,
        "md": 3
      }
    }, [_c('v-card', {
      staticClass: "strength",
      attrs: {
        "id": "next",
        "outlined": "",
        "width": 270,
        "height": 100,
        "to": '/drills/' + skill.getDrillPreview().getContentPreview().getUrl()
      }
    }, [_c('div', {
      staticClass: "d-flex flex-no-wrap"
    }, [_c('v-avatar', {
      staticClass: "mt-3 ml-3",
      attrs: {
        "size": "imageWidth",
        "tile": ""
      }
    }, [_c('v-img', {
      attrs: {
        "src": skill.getDrillPreview().getContentPreview().getMetaData().getImageUrl()
      }
    })], 1), _c('div', [_c('v-card-title', {
      staticClass: "text-caption-1"
    }, [_c('v-tooltip', {
      attrs: {
        "top": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('span', _vm._g({
            staticClass: "d-inline-block text-truncate  ",
            style: {
              width: _vm.titleWidth + 'px'
            }
          }, on), [_vm._v(" " + _vm._s(skill.getDrillPreview().getContentPreview().getTitle()) + " ")])];
        }
      }], null, true)
    }, [_vm._v(" " + _vm._s(skill.getDrillPreview().getContentPreview().getTitle()) + " ")])], 1), _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.solvedCount(skill)) + "/" + _vm._s(_vm.totalContent(skill)) + " Completed "), _c('br')])], 1)], 1)])], 1);
  }), 1), _c('v-row', [_c('v-col', {
    staticClass: "mt-5 font-weight-bold text-truncate text-h6"
  }, [_vm._v(" Weaknesses ")])], 1), _c('v-row', _vm._l(_vm.dashboardView.getWeaknessesList(), function (skill) {
    return _c('v-col', {
      key: skill.getDrillPreview().getContentPreview().getId(),
      attrs: {
        "xs": 12,
        "sm": 6,
        "md": 3
      }
    }, [_c('v-card', {
      staticClass: "weakness",
      attrs: {
        "outlined": "",
        "width": 270,
        "height": 100,
        "to": '/drills/' + skill.getDrillPreview().getContentPreview().getUrl()
      }
    }, [_c('div', {
      staticClass: "d-flex flex-no-wrap"
    }, [_c('v-avatar', {
      staticClass: "mt-3 ml-3",
      attrs: {
        "size": "imageWidth",
        "tile": ""
      }
    }, [_c('v-img', {
      attrs: {
        "src": skill.getDrillPreview().getContentPreview().getMetaData().getImageUrl()
      }
    })], 1), _c('div', [_c('v-card-title', {
      staticClass: "text-caption-1"
    }, [_c('v-tooltip', {
      attrs: {
        "top": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref2) {
          var on = _ref2.on;
          return [_c('span', _vm._g({
            staticClass: "d-inline-block text-truncate",
            style: {
              width: _vm.titleWidth + 'px'
            }
          }, on), [_vm._v(" " + _vm._s(skill.getDrillPreview().getContentPreview().getTitle()) + " ")])];
        }
      }], null, true)
    }, [_vm._v(" " + _vm._s(skill.getDrillPreview().getContentPreview().getTitle()) + " ")])], 1), _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.solvedCount(skill)) + "/" + _vm._s(_vm.totalContent(skill)) + " Completed "), _c('br')])], 1)], 1)])], 1);
  }), 1)], 1)], 1) : _c('v-row', [_c('v-col', [_c('status-card', {
    attrs: {
      "startNow": "/problems",
      "height": 97,
      "width": 107,
      "title": "Skills",
      "cardText": "Solve 10 or more problems to start seeing Strengths",
      "imageName": "Strengths"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }