var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "shift-up",
    attrs: {
      "elevation": "0",
      "max-height": "800px"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('v-row', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": 6,
      "md": 4
    }
  }, [_c('v-select', {
    staticClass: "ml-0 mb-2",
    attrs: {
      "items": _vm.curLanguages,
      "outlined": "",
      "hide-details": "",
      "dense": "",
      "label": "Language"
    },
    on: {
      "change": _vm.saveEditorConfig
    },
    model: {
      value: _vm.language,
      callback: function callback($$v) {
        _vm.language = $$v;
      },
      expression: "language"
    }
  })], 1), _c('v-spacer', {
    staticClass: "hidden-sm-and-down"
  }), _c('v-tooltip', {
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "color": _vm.fullscreenColor,
            "large": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "text-right",
          on: {
            "click": _vm.toggleEditorFS
          }
        }, [_vm._v(_vm._s(_vm.mdiFullscreen))])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Fullscreen")])]), _c('v-tooltip', {
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "color": _vm.closingBracketsColor,
            "large": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "text-right",
          on: {
            "click": _vm.toggleBracket
          }
        }, [_vm._v(_vm._s(_vm.mdiCodeParentheses))])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Auto closing bracket")])]), _c('v-tooltip', {
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on,
            attrs = _ref3.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "color": "primary",
            "large": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "text-right",
          on: {
            "click": _vm.removeCode
          }
        }, [_vm._v(_vm._s(_vm.mdiCached))])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Reset Code")])]), _c('v-tooltip', {
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref4) {
        var on = _ref4.on,
            attrs = _ref4.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "color": _vm.autoCompleteColor,
            "large": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "text-right",
          on: {
            "click": _vm.toggleAutocomplete
          }
        }, [_vm._v(_vm._s(_vm.mdiToggle))])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Auto complete")])]), _c('v-tooltip', {
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref5) {
        var on = _ref5.on,
            attrs = _ref5.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "color": "primary",
            "large": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "text-right",
          on: {
            "click": _vm.toggleSettings
          }
        }, [_vm._v(_vm._s(_vm.mdiAccountCogOutline))])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Settings")])]), _c('v-dialog', {
    attrs: {
      "hide-overlay": "",
      "max-width": 350
    },
    model: {
      value: _vm.showSettings,
      callback: function callback($$v) {
        _vm.showSettings = $$v;
      },
      expression: "showSettings"
    }
  }, [_c('v-col', {
    staticClass: "code-settings",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v(" Editor Settings ")]), _c('v-select', {
    staticClass: "py-2 ml-0 font-weight-light",
    attrs: {
      "dense": "",
      "items": _vm.themes,
      "outlined": "",
      "hide-details": "",
      "label": "Theme"
    },
    on: {
      "change": _vm.saveEditorConfig
    },
    model: {
      value: _vm.theme,
      callback: function callback($$v) {
        _vm.theme = $$v;
      },
      expression: "theme"
    }
  }), _c('v-select', {
    staticClass: "py-2 ml-0 font-weight-light",
    attrs: {
      "dense": "",
      "items": _vm.fontSizes,
      "outlined": "",
      "hide-details": "",
      "label": "Font size"
    },
    on: {
      "change": _vm.saveEditorConfig
    },
    model: {
      value: _vm.fontSize,
      callback: function callback($$v) {
        _vm.fontSize = $$v;
      },
      expression: "fontSize"
    }
  }), _c('v-select', {
    staticClass: "py-2 ml-0 font-weight-light",
    attrs: {
      "dense": "",
      "items": _vm.tabSizes,
      "outlined": "",
      "hide-details": "",
      "label": "Tab size"
    },
    on: {
      "change": _vm.saveEditorConfig
    },
    model: {
      value: _vm.tabSize,
      callback: function callback($$v) {
        _vm.tabSize = $$v;
      },
      expression: "tabSize"
    }
  }), _c('v-select', {
    staticClass: "py-2 ml-0 font-weight-light",
    attrs: {
      "dense": "",
      "items": _vm.keyMaps,
      "outlined": "",
      "hide-details": "",
      "label": "Key map"
    },
    on: {
      "change": _vm.saveEditorConfig
    },
    model: {
      value: _vm.keyMap,
      callback: function callback($$v) {
        _vm.keyMap = $$v;
      },
      expression: "keyMap"
    }
  })], 1)], 1)], 1)], 1), _c('v-divider'), _c('CodeMirror', {
    ref: "hiringCm",
    "class": [_vm.fontSize, 'pb-8'],
    attrs: {
      "value": _vm.value,
      "options": _vm.cmOptions
    },
    on: {
      "ready": _vm.onCmReady,
      "input": _vm.onCodeChange
    }
  }), _c('div', {
    ref: "codeaction",
    staticClass: "px-lg-8 px-4 mb-5"
  }, [_vm._t("actions")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }