<template>
  <v-dialog v-show="dialog"
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="!rejection && cancel"
  >
    <v-card class="pa-5">
      <v-card-text v-show="!!message" class="pa-3 text-h2 text-center red--text">
        Warning!
      </v-card-text>
      <v-card-text v-show="!!message" class="pa-4 text-h6 text-center">
        {{ message }}
      </v-card-text>
      <v-card-actions class="py-4 px-8 justify-center">
        <v-btn
          v-if="rejection"
          large
          @click.native="cancel"
          class="text-none text-subtitle-1 font-weight-medium px-2"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    rejection: {
      default: true,
      required: false,
    },
  },
  data: () => ({
    dialog: false,
    message: null,
    // title: null,
    options: {
      color: "primary",
      width: 600,
      zIndex: 200,
    },
  }),
  methods: {
    open(message, options) {
      this.dialog = true;
      //   this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
    },
    cancel() {
      this.dialog = false;
    },
  },
};
</script>
