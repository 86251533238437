var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    ref: "judgingStatus",
    staticClass: "pt-2"
  }, [_c('v-col', [_vm.submissionId ? _c('div', [_vm.submissionStatus && _vm.submissionStatus.getStatus() == 0 ? _c('submission-status', {
    attrs: {
      "title": "Waiting...",
      "subtitle": "Waiting in the queue for your turn. Please be patient.",
      "status": _vm.submissionStatus && _vm.submissionStatus.getStatus()
    }
  }) : !_vm.judgingCompleted ? _c('submission-status', {
    attrs: {
      "title": "Judging...",
      "subtitle": 'We are processing your submission. ' + _vm.testsProgress,
      "status": _vm.submissionStatus && _vm.submissionStatus.getStatus()
    }
  }) : _vm.judgingCompleted && _vm.submissionStatus.getStatus() == 13 && _vm.submissionStatus.getSubmissionType() == 0 ? _c('submission-status', {
    attrs: {
      "title": _vm.correctTitle(_vm.submissionStatus),
      "subtitle": _vm.scoreSubtitle(_vm.submissionStatus),
      "status": _vm.submissionStatus && _vm.submissionStatus.getStatus()
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 6,
      "md": 4
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Time Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getTimeTaken()) + " ms ")])], 1), _c('v-col', {
    attrs: {
      "cols": 6,
      "md": 4
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Memory Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getMemoryTaken()) + " KiB ")])], 1), _c('v-spacer')], 1)], 1) : _vm.judgingCompleted && _vm.submissionStatus.getStatus() == 13 && _vm.submissionStatus.getSubmissionType() == 1 ? _c('submission-status', {
    attrs: {
      "title": "Samples Passed",
      "subtitle": "Press Submit to test against the full test suite.",
      "status": _vm.submissionStatus && _vm.submissionStatus.getStatus()
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 6,
      "md": 4
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Time Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getTimeTaken()) + " ms ")])], 1), _c('v-col', {
    attrs: {
      "cols": 6,
      "md": 4
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Memory Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getMemoryTaken()) + " KiB ")])], 1), _c('v-spacer')], 1), _c('tests-results', {
    attrs: {
      "resultViews": _vm.submissionStatus.getSampleResultViewsList()
    }
  })], 1) : _vm.judgingCompleted && _vm.submissionStatus.getStatus() == 13 && _vm.submissionStatus.getSubmissionType() == 2 ? _c('submission-status', {
    attrs: {
      "title": "Successful",
      "subtitle": "Code ran successfully for custom input.",
      "status": _vm.submissionStatus && _vm.submissionStatus.getStatus()
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 6,
      "md": 4
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Time Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getTimeTaken()) + " ms ")])], 1), _c('v-col', {
    attrs: {
      "cols": 6,
      "md": 4
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Memory Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getMemoryTaken()) + " KiB ")])], 1), _c('v-spacer')], 1), _c('tests-results', {
    attrs: {
      "resultViews": _vm.submissionStatus.getSampleResultViewsList()
    }
  })], 1) : _c('submission-status', {
    attrs: {
      "title": _vm.statusTitle(_vm.submissionStatus.getStatus()),
      "subtitle": _vm.statusSubtitle(_vm.submissionStatus),
      "status": _vm.submissionStatus && _vm.submissionStatus.getStatus()
    }
  }, [_vm.submissionStatus.getResult() ? _c('text-block', [_c('pre', [_vm._v("            " + _vm._s(_vm.submissionStatus && _vm.submissionStatus.getResult()) + "\n          ")])]) : _vm._e(), _vm.submissionStatus.getSampleResultViewsList() ? _c('tests-results', {
    attrs: {
      "resultViews": _vm.submissionStatus.getSampleResultViewsList()
    }
  }) : _vm._e()], 1)], 1) : _c('div', {
    staticClass: "pa-5"
  }, [_vm._v("Submitting ...")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }