import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";
import auth from "./utils/auth";
import * as firebaseui from "firebaseui";
import VueAnalytics from 'vue-analytics';


// Your web app's Firebase configuration

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyChiLDFW5EDgSCV4rEvR8MIEFVmFPvedfQ",
  authDomain: "codedrills.io",
  databaseURL: "https://codedrills.firebaseio.com",
  projectId: "codedrills",
  storageBucket: "codedrills.appspot.com",
  messagingSenderId: "864730259813",
  appId: "1:864730259813:web:7fe150e51205488a19418c"
};

var firebaseConfigLoad = {
	apiKey: "AIzaSyDjixsj0Fhy3bMC0MjilfWcLf65sgs8rCw",
	authDomain: "cdloadtest.firebaseapp.com",
	projectId: "cdloadtest",
	storageBucket: "cdloadtest.appspot.com",
	messagingSenderId: "891713579432",
	appId: "1:891713579432:web:b1bba9fc3ba8312e42159d"
};

var isProd = (process.env.NODE_ENV === 'production');
var isStaging = (process.env.NODE_ENV === 'development');


// Initialize Firebase
if(isProd || isStaging) firebase.initializeApp(firebaseConfig);
else firebase.initializeApp(firebaseConfigLoad);

firebase.auth().onAuthStateChanged(auth.authChange);
Vue.prototype.$firebaseUI = new firebaseui.auth.AuthUI(firebase.auth());

var isProd = (process.env.NODE_ENV === 'production');
if (isProd) {
  console.log = function(){};
}

Vue.config.productionTip = false;

Vue.use(VueAnalytics, {
  id: 'UA-157923440-1',
  router,
  debug: {
    enabled: !isProd,
    sendHitTask: isProd,
  },
});


if (firebase.messaging.isSupported()) {
  Vue.prototype.$messaging= firebase.messaging();
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

if (firebase.messaging.isSupported()) {
  navigator.serviceWorker.register("/firebase-messaging-sw.js")
  .then((registration)=> {
    Vue.prototype.$messaging.useServiceWorker(registration);
  }).catch(err => {
    console.log(err);
  })
}
