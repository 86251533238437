<template>
	<span>
		<v-row>
			<v-col>
				<upcoming-contest v-if="upcoming" :contentView="contentView"></upcoming-contest>
				<past-contest v-else-if="past" :contentView="contentView"></past-contest>
				<live-contest v-else :contentView="contentView"></live-contest>
			</v-col>
		</v-row>
	</span>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import UpcomingContest from "../components/UpcomingContest";
import LiveContest from "../components/LiveContest";
import PastContest from "../components/PastContest";
import ProblemList from "../components/ProblemList";

export default {
  props: {
    "contentView": {
      type: Object,
      required: true,
    },
  },
	methods: {
    ...mapActions("content", ["fetchContentView"]),
		...mapActions("user", ["getUserContestTeams"]),
		refreshContent() {
			this.fetchContentView({
				type: "CONTEST",
				url: this.contentView.getUrl(),
			}).then(__ => console.log("Fetched contentView"))
		},
	},
  computed: {
	...mapState("timesync", ["serverTime"]),
		upcoming() {
			return this.contentView.getMetaData().getContestMeta().getStartAt() > this.serverTime;
		},
		past() {
			return this.contentView.getMetaData().getContestMeta().getEndAt() < this.serverTime;
		},
		teamContest() {
			return this.contentView && this.contentView.getMetaData()
                .getContestMeta()
                .getContestType() == 1;
		},
  },
  components: {
		UpcomingContest,
		LiveContest,
		PastContest,
		ProblemList,
  },
	watch: {
		upcoming() {
			console.log("Watching upcoming,,,");
			this.refreshContent();	
		},
		past() {
			console.log("Watching past ,,,");
			this.refreshContent();	
		}
	},
	created() {
		if(this.contentView && this.teamContest)
		this.getUserContestTeams({"contestId": this.contentView.getId()});
	},
};
</script>

