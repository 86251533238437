<template>
  <div>
    <div class="py-5">
      <slot name="top" />
    </div>
    <v-row v-if="loadingError" align="center" justify="center">
      <div class="text-h5 text-center text-bold danger--text py-16">
        <Navbar />
        {{ errMsg }}
      </div>
    </v-row>
    <v-row v-else-if="loading"> Page Loading ... </v-row>
    <slot v-else name="content" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loader from "@/components/Loader.vue";
import Error from "@/components/Error.vue";
import { ApiCallStatus } from "@/utils/api.js";
import Navbar from "../Navbar.vue";

export default {
  name: "page",
  props: {
    title: {
      type: String,
      required: false,
    },
    apiStatusList: {
      type: Array,
      required: false,
    },
    appendImageUrl: {
      type: String,
      required: false,
    },
    loginRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    breadcrumbs: {
      type: Array,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    errMsg: {
      type: String,
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    loadingCompleted() {
      var completed = this.apiStatusList.reduce(
        (t, s) => t && (s == ApiCallStatus.SUCCESS || s == ApiCallStatus.ERROR),
        true
      );
      return completed && this.loading === false;
    },
    loadingError() {
      return this.apiStatusList.reduce(
        (t, s) => t || s == ApiCallStatus.ERROR,
        false
      );
    },
  },
  watch: {
    loginRequired: function () {
      this.checkUser();
    },
    user: function () {
      this.checkUser();
    },
  },
  methods: {
    checkUser() {
      //console.log("LoginRequired", this.loginRequired);
      if (!this.loginRequired) return;
      if (!this.user || this.user.isAnnonymous)
        return this.$router.push("/community/login");
    },
  },
  mounted() {
    this.checkUser();
    //console.log("Title link ....", this.titleLink);
  },
  components: {
    Loader,
    Error,
    Navbar,
  },
};
</script>
<style scoped>
.dashboard_link {
  color: #000000;
  text-decoration: none;
}
</style>
