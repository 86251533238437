var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-tabs', {
    attrs: {
      "vertical": _vm.$vuetify.breakpoint.smAndDown,
      "color": "accent"
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("Overview")]), _vm.teamContest ? _c('v-tab', [_vm._v("My Team")]) : _vm._e(), _vm.teamContest ? _c('v-tab', [_vm._v("All Contest Teams")]) : _vm._e()], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('v-row', [_c('v-col', [_c('problem-list', {
    attrs: {
      "live": "",
      "urlPrefix": '/contests/' + this.contentView.getUrl(),
      "problems": _vm.problems,
      "showOption": false,
      "showIds": ""
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('div', {
    staticClass: "display-1"
  }, [_vm._v("About")])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 11
    }
  }, [_c('v-card', {
    staticClass: "pl-5 py-3",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "d-flex flex-no-wrap"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('html-view', {
    attrs: {
      "html": _vm.view.getAboutHtml()
    }
  })], 1)])])], 1)], 1)], 1), _c('v-tab-item', [!_vm.user ? _c('v-row', [_c('v-col', [_vm._v(" Please "), _c('router-link', {
    attrs: {
      "to": "/community/login"
    }
  }, [_vm._v(" login ")]), _vm._v(" to create team ")], 1)], 1) : _vm.userContestTeams && _vm.userContestTeams.length == 0 ? _c('v-row', [_c('v-col', [_vm._v(" You are not member of any team ")])], 1) : !_vm.acceptedTeam ? _c('v-row', [_c('v-col', [_vm._v(" No Accepted Invitation ")])], 1) : _vm._e(), _vm.acceptedTeam ? _c('v-row', [_c('v-col', [_c('team-detail', {
    attrs: {
      "teamView": _vm.acceptedTeam
    }
  })], 1)], 1) : _vm._e()], 1), _c('v-tab-item', [_c('v-row', [_c('v-col', [_c('teams-list', {
    attrs: {
      "contestId": _vm.contentView.getId(),
      "contestUrl": _vm.contentView.getUrl()
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }