export function resolvePath(obj, path, separator = ".") {
  var properties = Array.isArray(path) ? path : path.split(separator);
  var value = properties.reduce((prev, curr) => prev && prev[curr], obj);
  return value;
}

function prettyPart(part) {
  if (part < 10) return "0" + part;
  else return part;
}
export function prettyday(diff) {
  return diff > 72 * 60 * 60
    ? prettyPart(Math.floor(diff / (24 * 60 * 60)) + " days")
    : prettyPart(
        Math.floor(diff / (60 * 60)) +
          ":" +
          prettyPart(Math.floor((diff % (60 * 60)) / 60)) +
          ":" +
          prettyPart(Math.floor(diff % 60))
      );
}

export function prettytime(sec) {
  return (
    "" +
    prettyPart(Math.floor(sec / (60 * 60))) +
    ":" +
    prettyPart(Math.floor((sec % (60 * 60)) / 60)) +
    ":" +
    prettyPart(sec % 60)
  );
}

export function isFullScreen() {
  return !(
    !document.fullscreenElement && // alternative standard method
    !document.mozFullScreenElement &&
    !document.webkitFullscreenElement &&
    !document.msFullscreenElement
  );
}

/* View in fullscreen */
export function openFullScreen() {
  var conf = confirm("Please enable fullscreen mode >_<");
  document.onclick = function (argument) {
    if (isFullScreen()) {
      return;
    }
    var conf = confirm("Please enable fullscreen mode >_<");
    var docelem = document.documentElement;

    if (conf == true) {
      if (docelem.requestFullscreen) {
        docelem.requestFullscreen();
      } else if (docelem.mozRequestFullScreen) {
        docelem.mozRequestFullScreen();
      } else if (docelem.webkitRequestFullScreen) {
        docelem.webkitRequestFullScreen();
      } else if (docelem.msRequestFullscreen) {
        docelem.msRequestFullscreen();
      }
    }
  };
}

export function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
}