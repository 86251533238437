<template>
		<div v-if="liveContestPreviewsList && liveContestPreviewsList.length > 0">
      <v-row class="mt-12">
				<v-col class="font-weight-bold text-truncate text-h5"> 
				Live Contests
				</v-col>
			</v-row>
			<contest-list :contestList="liveContestPreviewsList"></contest-list>
		</div>
       
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import ContestList from "../components/ContestList";


export default {
name: 'LiveContestList',
props: {
  },
computed: {
    ...mapState("content", ["fetchUserContentPreviewsStatus", "userContentPreviews"]),
    ...mapState("timesync", ["serverTime"]),
     liveContestPreviewsList() {
      return this.userContentPreviews &&
        this.userContentPreviews
        .filter(c => c.getContentPreview().getMetaData().getContestMeta()&& 
										 c.getContentPreview().getMetaData().getContestMeta().getStartAt() <= this.serverTime &&
                     c.getContentPreview().getMetaData().getContestMeta().getEndAt() > this.serverTime)
				.map(c => c.getContentPreview());
    },
},
methods: {
		...mapGetters("content", ["getContentType"]),
    ...mapActions("content", ["fetchUserContentPreviews"]),
  },
created() {
    this.fetchUserContentPreviews({"contentTypes": [this.getContentType()['CONTEST']]});
  },
components: {
		ContestList,
		
  }
};

</script>


