var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', [_c('v-row', [_c('v-col', [_vm.user ? _c('span', [_vm.userActivity && _vm.userActivity.getData() && _vm.userActivity.getData().getDrillReport().getTracking() ? _c('v-btn', {
    staticClass: "text-capitalize",
    attrs: {
      "color": "#BCECE0",
      "size": "35"
    },
    on: {
      "click": function click($event) {
        return _vm.updateActivityStatus(false);
      }
    }
  }, [_vm._v(" Stop tracking ")]) : _c('v-btn', {
    staticClass: "text-capitalize",
    attrs: {
      "color": "#BCECE0",
      "size": "35"
    },
    on: {
      "click": function click($event) {
        return _vm.updateActivityStatus(true);
      }
    }
  }, [_vm._v(" Start tracking ")])], 1) : _vm._e()])], 1), _c('v-row', [_c('v-col', [_vm._v(" About ")])], 1), _c('v-row', [_c('v-col', [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', [_c('html-view', {
    attrs: {
      "html": _vm.contentView.getDataView().getDrillView().getDescriptionHtml()
    }
  })], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('v-expansion-panels', {
    attrs: {
      "outlined": "",
      "accordion": "",
      "focusable": "",
      "multiple": ""
    },
    model: {
      value: _vm.panel,
      callback: function callback($$v) {
        _vm.panel = $$v;
      },
      expression: "panel"
    }
  }, _vm._l(_vm.contentView.getDataView().getDrillView().getSectionsList(), function (section) {
    return _c('v-expansion-panel', {
      key: section.getTitle(),
      staticClass: "mt-5"
    }, [_c('v-expansion-panel-header', [_c('html-view', {
      attrs: {
        "html": section.getTitle()
      }
    })], 1), _c('v-expansion-panel-content', {
      staticClass: "mt-5"
    }, [_c('html-view', {
      attrs: {
        "html": section.getDescriptionHtml()
      }
    }), _c('problem-list', {
      attrs: {
        "problems": _vm.problemList(section.getContentViewsList()),
        "urlPrefix": _vm.contentView.getUrl()
      }
    })], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }