<template>
  <v-container fluid>
    <div v-if="!this.user">
      Please login first
    </div>
    <span v-if="this.user">
      <div class="grey lighten-5 pl-12 pr-5 mx-0 my-0">
        <v-row>
          <v-col>
            <span class="display-1 font-weight-bold">
              Submission Details
            </span>
          </v-col>
        </v-row>
      </div>
      <v-container fluid class="pl-12 pr-5 mx-0 my-0">
        <v-row class="py-5 px-2">
          <Loader v-if="!submissionDetail" />
					<error-message :ex="ex"></error-message>
          <v-col v-if="submissionDetail">
            <v-row>
              <v-col>
                <v-card>
                  <v-card-title>
                    Submission: {{ id }}
                  </v-card-title>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th v-for="th in headers" :key="th.value" class="text-left">
                            {{ th.text }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {{ submissionDetail.getSubmission().getId() }}
                          </td>
                          <td>
                            {{
                              formatDate(
                                submissionDetail.getSubmission().getSubmittedOn()
                              )
                            }}
                          </td>
                          <td>
                              <router-link v-if="submissionDetail.getUserPreview().getHandle()"
                                 :to="`/profiles/${submissionDetail.getUserPreview().getHandle()}`" class="text-decoration-none router_link"
                              >
													        {{submissionDetail.getUserPreview().getHandle()}}
                              </router-link>

											        <span v-else>
													       {{submissionDetail.getUserPreview().getName()}}
											        </span>
                          </td>
                          <td>
                            <router-link
                              class="link"
                              :to="
                                '/problems/' +
                                  submissionDetail.getProblemPreview().getUrl()
                              "
                            >
                              {{ submissionDetail.getProblemPreview().getTitle() }}
                            </router-link>
                          </td>
                          <td>
                            {{
                              allLanguages[
                                languageKeys[
                                  submissionDetail.getSubmission().getLanguage()
                                ]
                              ]
                            }}
                          </td>
                          <td class="text-capitalize" v-bind:class="{ 'green--text': isCorrectSubmission , 'red--text': isIncorrectSubmission }">
                            {{
                              statusKeys[submissionDetail.getSubmission().getStatus()]
                                .split('_')
                                .join(' ')
                                .toLowerCase()
                            }}
                          </td>
                          <td>{{(100 * submissionDetail.getSubmission().getScore()).toFixed(2)}} %</td>
                          <td>
                            {{ submissionDetail.getSubmission().getTimeTakenInMs() }}
                            ms
                          </td>
                          <td>
                            {{ submissionDetail.getSubmission().getMemoryTakenInKb() }}
                            KiB
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-card>
                  <v-row class="pl-1 align-center" no-gutters>
                    <v-col>
                      <v-card-title>Code:</v-card-title>
                    </v-col>

                    <v-col :cols="1">
                      <v-btn icon @click="copyText">
                        Copy
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-divider></v-divider>
                  <CodeView
                  class="code-mirror"
                    :value="submissionDetail.getSubmission().getCode()"
                    :lang="languageKeys[submissionDetail.getSubmission().getLanguage()]"
                  />
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-card>
                  <v-card-title primary-title>
                    Test Cases
                  </v-card-title>
                  <v-data-table
                    :headers="testCaseHeaders"
                    :items="submissionDetail.getTestRunResultsList()"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td class="text-center">{{ item.getTestKey().getTestCase() }}</td>
                        <td class="text-center text-capitalize" v-bind:class="{ 'grey--text': isSkippedTest(item), 'green--text': isCorrectTest(item), 'red--text': isIncorrectTest(item) }">
                          {{
                            statusKeys[item.getStatus()]
                              .split('_')
                              .join(' ')
                              .toLowerCase()
                          }}
                        </td>
                        <td class="text-center">{{ item.getTimeTakenInMs() }} ms</td>
                        <td class="text-center">{{ item.getMemoryTakenInKb() }} KiB</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </span>
  </v-container>
</template>

<script>
import Loader from '../components/Loader';
import CodeView from '../components/CodeView';
import { getters, mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import ErrorMessage from '../components/ErrorMessage.vue';

export default {
  props: ['url', 'id'],
  data() {
    return {
      loadingSubmission: true,
			ex: null,
      search: '',
      headers: [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
        },
        {
          text: 'Submitted On',
          align: 'left',
          value: 'submittedOn',
        },
        {
          text: 'Submitted By',
          align: 'left',
          value: 'userPreview.name',
        },
        {
          text: 'Problem',
          align: 'left',
          value: 'problemPreview.title',
        },
        {
          text: 'Language',
          align: 'left',
          value: 'language',
        },
        {
          text: 'Status',
          align: 'left',
          value: 'status',
        },
        {
          text: 'Score',
          align: 'left',
          value: 'score',
        },
        {
          text: 'Time',
          align: 'left',
          value: 'timeTakenInMs',
        },
        {
          text: 'Memory',
          align: 'left',
          value: 'memoryTakenInKb',
        },
      ],
      testCaseHeaders: [
        {
          text: 'Test case ID',
          align: 'center',
        },
        {
          text: 'Status',
          align: 'center',
        },
        {
          text: 'Time',
          align: 'center',
        },
        {
          text: 'memory',
          align: 'center',
        },
      ],
      allLanguages: {
        CPP_17: 'C++',
        JAVA_11: 'Java',
        KOTLIN_1_3: 'Kotlin',
        PYTHON_3: 'Python',
        TXT: 'Text',
        JAVASCRIPT: "text/javascript",
      },
    };
  },

  components: {
    Loader,
    CodeView,
		ErrorMessage,
  },
  methods: {
		...mapActions('judge', ['fetchSubmissionDetail']),

    fetchDetail(id) {
      this.fetchSubmissionDetail({ submissionId: id }).catch(ex => this.ex = ex);
    },
    formatDate(date) {
      const d = new Date(date);
      return d.toLocaleString();
    },
    copyText() {
      navigator.clipboard.writeText(this.submissionDetail.getSubmission().getCode());
    },
    isSkippedTest(item) {
      console.log("item...", item);
       return  item.getStatus() === 17 ?  true :  false ;
    },
    isCorrectTest(item) {
       return  item.getStatus() === 13 ?  true :  false ;
    },
    isIncorrectTest(item) {
       return (item.getStatus() >= 10 && item.getStatus() != 13 && item.getStatus() != 17) ? true : false ;
    },
    getDisplayName(s) {
      if(s.getUserPreview().getHandle()) return s.getUserPreview().getHandle();
      return s.getUserPreview().getName();
    }
  },
  computed: {
    ...mapState('judge', ['submissionDetail']),
    ...mapState('user', ['user', 'hasPremiumAccess']),
    ...mapGetters('judge', ['statusKeys']),
    ...mapGetters('user', ['languageKeys']),

    isCorrectSubmission() {
       return  this.submissionDetail.getSubmission().getStatus() === 13 ?  true :  false ;
    },

    isIncorrectSubmission() {
       return this.submissionDetail.getSubmission().getStatus() >= 10 && this.submissionDetail.getSubmission().getStatus() != 13 ? true : false ;
    },
  },
  watch: {
    submissionDetail(s) {
      document.title = this.getDisplayName(s) + "'s submission for " + s.getProblemPreview().getTitle();
    }
  },
  mounted() {
    this.fetchDetail(this.id);
  },
};
</script>

<style>

.code-mirror .CodeMirror {
  height: 600px;
}

.router_link{
	color:inherit;
}

</style>
