var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page', {
    attrs: {
      "minimal": "",
      "title": "Contests",
      "apiStatusList": [_vm.fetchUserContentPreviewsStatus]
    }
  }, [_vm.liveContestPreviewsList && _vm.liveContestPreviewsList.length > 0 ? _c('div', [_c('div', {
    staticClass: "headline mt-10"
  }, [_vm._v("Live Contests")]), _c('contest-list', {
    attrs: {
      "contestList": _vm.liveContestPreviewsList
    }
  })], 1) : _vm._e(), _vm.pastContestPreviewsList && _vm.pastContestPreviewsList.length > 0 ? _c('div', [_c('div', {
    staticClass: "headline mt-10"
  }, [_vm._v("Upcoming Contests")]), _c('contest-list', {
    attrs: {
      "contestList": _vm.pastContestPreviewsList
    }
  })], 1) : _vm._e(), _vm.upcomingContestPreviewsList && _vm.upcomingContestPreviewsList.length > 0 ? _c('div', [_c('div', {
    staticClass: "headline mt-10"
  }, [_vm._v("Past Contests")]), _c('contest-list', {
    attrs: {
      "contestList": _vm.upcomingContestPreviewsList,
      "reverse": ""
    }
  })], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }