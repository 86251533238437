<template>
	<!-- <div> -->
		<v-row class="d-flex flex-sm-wrap">
			<v-col v-for="a in attributes" :key="a.name" cols="12" :md="12 / attributes.length">
				<v-combobox
					v-if="isAutoComplete(a) && a.customValueAllowed"
					dense
					:items="autoCompleteItems(a)"
					:label="addStarIfRequired(a)"
					@input="v => setValue(a.name, v)"
					:rules="rules(a)"
				 ></v-combobox>
				<v-autocomplete
					v-else-if="isAutoComplete(a) && !a.customValueAllowed"
					dense
					:items="autoCompleteItems(a)"
					:label="addStarIfRequired(a)"
					@input="v => setValue(a.name, v)"
					:rules="rules(a)"
				 ></v-autocomplete>
				<v-text-field
					v-else
					dense
					:label="addStarIfRequired(a)"
					@input="v => setValue(a.name, v)"
					:rules="rules(a)"
				 ></v-text-field>

			</v-col>
		</v-row>
	<!-- </div> -->
</template>
<script>
import { mapGetters } from 'vuex';
import autocompleteValues from '../utils/autocomplete';

export default {
	props: {
		customAttributes: {
			type: Array,
			required: true,
		},
		needsValidation: {
			type: Boolean,
			required: false,
			default: true,
		}
	},
	data(){
		return{
			validationRules: {
				required: value => !!value || 'Required',
			}
		};
	},
	computed: {
		attributes() {
			return this.customAttributes.map(x => x[1]);
		}
	},

	mounted() {
		console.log(this.customAttributes, this.getFieldPresenceList());
	},

	methods:{
		...mapGetters('content', ['getFieldPresenceList']),
		setValue(n, v) {
			this.$emit('change', {'key': n, 'value': v});
		},
		addStarIfRequired(a){
			if (this.required(a)){
				return `${a.name}*`
			}
			else {
				return `${a.name}`
			}
		},
		required(a) {
			//console.log("Attr ......", a);
			// CustomAttributeType is the type of attrib (string/number)
			// Presence -> 1 for reqd, 2 for optional

			// needs && required? from proto
			// console.log(a.name, this.getFieldPresenceList()[a.customAttributePresence]);
			return this.needsValidation && this.getFieldPresenceList()[a.customAttributePresence] === 'FIELD_PRESENCE_REQUIRED';
		},
		isAutoComplete(a) {
			var autocomplete =  a.autoCompleteSetting && a.autoCompleteSetting.valueList !== "";
			//console.log(autocomplete, a);
			return autocomplete;
		},
		autoCompleteItems(a) {
			var valueList = a.autoCompleteSetting.valueList;
			//console.log("aci", valueList, autocompleteValues);
			return autocompleteValues[valueList] || [];
		},
		rules(a) {
			const ruleSet = [];
			if(this.required(a)) {
				ruleSet.push(this.validationRules.required)
			}
			return ruleSet;
		}
	},
}
</script>

<style>
</style>
