var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.user ? _c('v-row', [_c('v-col', {
    staticClass: "pt-2 pb-1"
  }, [_vm._v(" Please "), _c('router-link', {
    attrs: {
      "to": "/community/login"
    }
  }, [_vm._v(" login ")]), _vm._v(" to submit. ")], 1)], 1) : _c('v-row', {
    staticClass: "px-8 fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('SmallConfirmation', {
    ref: "qtime"
  }), _c('v-col', {
    staticClass: "fill-height",
    attrs: {
      "cols": "12",
      "lg": "11"
    }
  }, [_vm.testTimeOver ? _c('div', {
    staticClass: "pa-16"
  }, [_c('TimeoverModal')], 1) : _vm._e(), !_vm.testTimeOver && _vm.currentQuestion ? _c('v-card', {
    staticClass: "current-question px-16",
    attrs: {
      "height": "100%",
      "elevation": "0"
    }
  }, [_c('div', {
    staticClass: "top-completion px-16 pb-8 mb-6"
  }, [_c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.currentQuestionNum) + " / " + _vm._s(_vm.totalQuestions))])]), _c('v-row', [_c('v-progress-linear', {
    attrs: {
      "value": _vm.finishLine,
      "color": "green",
      "height": "15",
      "rounded": ""
    },
    on: {
      "click": function click() {}
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-n6 mb-6 navshadow"
  }), _c('SmallConfirmation', {
    ref: "skip"
  }), _c('SmallConfirmation', {
    ref: "fs"
  }), _c('PopUpWarning', {
    ref: "tabsw"
  }), _c('TestQuestion', {
    attrs: {
      "currentQuestion": _vm.currentQuestion,
      "nextQs": _vm.nextQs,
      "skipQs": _vm.skipQs
    }
  })], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }