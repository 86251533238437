var render = function () {
  var _vm$contentView$getMe, _vm$contentView$getMe2, _vm$contentView$getMe3, _vm$contentView$getMe4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [!_vm.user ? _c('v-row', [_c('v-col', {
    staticClass: "pt-4 pb-1"
  }, [_vm._v(" Please "), _c('router-link', {
    attrs: {
      "to": "/community/login"
    }
  }, [_vm._v(" login ")]), _vm._v(" to submit. ")], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', [_c('Editor', {
    attrs: {
      "evaluationType": (_vm$contentView$getMe = _vm.contentView.getMetaData()) === null || _vm$contentView$getMe === void 0 ? void 0 : (_vm$contentView$getMe2 = _vm$contentView$getMe.getProblemMeta()) === null || _vm$contentView$getMe2 === void 0 ? void 0 : _vm$contentView$getMe2.getEvaluationType()
    },
    on: {
      "setLanguage": _vm.setLanguage,
      "resetCode": _vm.removeOldCode
    },
    model: {
      value: _vm.code,
      callback: function callback($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  })], 1)], 1), _vm.user ? _c('v-row', [_c('v-spacer'), !((_vm$contentView$getMe3 = _vm.contentView.getMetaData()) !== null && _vm$contentView$getMe3 !== void 0 && (_vm$contentView$getMe4 = _vm$contentView$getMe3.getProblemMeta()) !== null && _vm$contentView$getMe4 !== void 0 && _vm$contentView$getMe4.getEvaluationType()) === 1 ? _c('v-col', {
    "class": {
      'text-center': _vm.$vuetify.breakpoint.mobile
    },
    attrs: {
      "cols": 11,
      "lg": 2
    }
  }, [!_vm.hasWrappedIo ? _c('v-checkbox', {
    attrs: {
      "label": "Custom input"
    },
    model: {
      value: _vm.isUserInput,
      callback: function callback($$v) {
        _vm.isUserInput = $$v;
      },
      expression: "isUserInput"
    }
  }) : _vm._e()], 1) : _vm._e(), _c('v-col', {
    "class": {
      'text-center': _vm.$vuetify.breakpoint.mobile,
      'text-end': !_vm.$vuetify.breakpoint.mobile
    },
    attrs: {
      "cols": 12,
      "lg": 4
    }
  }, [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "disabled": _vm.submitting,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.testRun
    }
  }, [_vm._v("Run Code")]), _c('v-btn', {
    attrs: {
      "disabled": _vm.submitting,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.fullSubmit
    }
  }, [_vm._v("Submit")])], 1)], 1) : _c('v-row', [_c('v-spacer'), _c('v-col', {
    staticClass: "pt-2 pb-1",
    attrs: {
      "cols": 2
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/community/login"
    }
  }, [_vm._v(" Login ")]), _vm._v(" to submit. ")], 1)], 1), _vm.isUserInput ? _c('v-row', {
    staticClass: "mx-2"
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "label": "input"
    },
    model: {
      value: _vm.stdin,
      callback: function callback($$v) {
        _vm.stdin = $$v;
      },
      expression: "stdin"
    }
  })], 1) : _vm._e(), _c('error-message', {
    attrs: {
      "ex": _vm.ex
    }
  }), _c('v-row', {
    ref: "judgingStatus",
    staticClass: "mx-2"
  }, [_c('v-col', [_vm.submissionId ? _c('div', [_vm.submissionStatus && _vm.submissionStatus.getStatus() == 0 ? _c('submission-status', {
    attrs: {
      "title": "Waiting...",
      "subtitle": "Waiting in the queue for your turn. Please be patient.",
      "imageUrl": "judgingImg.svg",
      "status": _vm.submissionStatus && _vm.submissionStatus.getStatus()
    }
  }) : !_vm.judgingCompleted ? _c('submission-status', {
    attrs: {
      "title": "Judging...",
      "subtitle": 'We are processing your submission. ' + _vm.testsProgress,
      "imageUrl": "judgingImg.svg",
      "status": _vm.submissionStatus && _vm.submissionStatus.getStatus()
    }
  }) : _vm.judgingCompleted && _vm.submissionStatus.getStatus() == 13 && _vm.submissionStatus.getSubmissionType() == 0 ? _c('submission-status', {
    attrs: {
      "title": _vm.correctTitle(_vm.submissionStatus),
      "subtitle": _vm.scoreSubtitle(_vm.submissionStatus),
      "imageUrl": _vm.randomCorrectImage(),
      "status": _vm.submissionStatus && _vm.submissionStatus.getStatus()
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 6,
      "md": 3,
      "lg": 2
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Time Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getTimeTaken()) + " ms ")])], 1), _c('v-col', {
    attrs: {
      "cols": 6,
      "md": 3,
      "lg": 2
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Memory Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getMemoryTaken()) + " KiB ")])], 1), _c('v-spacer')], 1)], 1) : _vm.judgingCompleted && _vm.submissionStatus.getStatus() == 13 && _vm.submissionStatus.getSubmissionType() == 1 ? _c('submission-status', {
    attrs: {
      "title": "Samples Passed",
      "subtitle": "Great! Submit to run against full test set",
      "imageUrl": _vm.randomCorrectImage(),
      "status": _vm.submissionStatus && _vm.submissionStatus.getStatus()
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 6,
      "md": 3,
      "lg": 2
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Time Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getTimeTaken()) + " ms ")])], 1), _c('v-col', {
    attrs: {
      "cols": 6,
      "md": 3,
      "lg": 2
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Memory Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getMemoryTaken()) + " KiB ")])], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": 6,
      "md": 4,
      "lg": 3,
      "align": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "disabled": _vm.submitting,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.fullSubmit
    }
  }, [_vm._v("Submit")])], 1)], 1), _c('tests-results', {
    attrs: {
      "resultViews": _vm.submissionStatus.getSampleResultViewsList()
    }
  })], 1) : _vm.judgingCompleted && _vm.submissionStatus.getStatus() == 13 && _vm.submissionStatus.getSubmissionType() == 2 ? _c('submission-status', {
    attrs: {
      "title": "Successful",
      "subtitle": "Code ran successfully for custom input",
      "imageUrl": _vm.randomCorrectImage(),
      "status": _vm.submissionStatus && _vm.submissionStatus.getStatus()
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 6,
      "md": 3,
      "lg": 2
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Time Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getTimeTaken()) + " ms ")])], 1), _c('v-col', {
    attrs: {
      "cols": 6,
      "md": 3,
      "lg": 2
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Memory Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getMemoryTaken()) + " KiB ")])], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": 6,
      "md": 4,
      "lg": 3,
      "align": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "disabled": _vm.submitting,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.fullSubmit
    }
  }, [_vm._v("Submit")])], 1)], 1), _c('tests-results', {
    attrs: {
      "resultViews": _vm.submissionStatus.getSampleResultViewsList()
    }
  })], 1) : _c('submission-status', {
    attrs: {
      "title": _vm.statusTitle(_vm.submissionStatus.getStatus()),
      "subtitle": _vm.statusSubtitle(_vm.submissionStatus),
      "imageUrl": "Error.svg",
      "status": _vm.submissionStatus && _vm.submissionStatus.getStatus()
    }
  }, [_vm.submissionStatus.getResult() ? _c('text-block', [_c('pre', [_vm._v(_vm._s(_vm.submissionStatus && _vm.submissionStatus.getResult()) + "\n            ")])]) : _vm._e(), _vm.submissionStatus.getSampleResultViewsList() ? _c('tests-results', {
    attrs: {
      "resultViews": _vm.submissionStatus.getSampleResultViewsList()
    }
  }) : _vm._e()], 1)], 1) : _vm._e()])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }