/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import judge from "codedrills_proto/io/codedrills/proto/judge/judge_service_grpc_web_pb";
import {ApiCall} from "@/utils/api.js"

var judge_proto = proto.io.codedrills.proto.judge;

const judgeService = new judge.JudgeServicePromiseClient( process.env.VUE_APP_JUDGE_API_URL + "/judge", null, null);

const state = {
  serverTime: Date.now() / 1000, // Epoch s
  syncRequestedAt: 0,
  fetchServerTimeStatus: 0,
  serverTimeDiff: 0,
  syncInitStarted: false,
  timeSyncId: null,
};

const actions = {
  async fetchServerTimeNew({state, commit, dispatch}) {
    //console.log("FetchServerTime");
    var leastRtt = Number.MAX_VALUE;
    var bestDiff = null;
    var checkUrl = "/";
    for(var i = 0; i < 3; ++i) {
      const requestAt = new Date();

      const {headers, ok} = await fetch(checkUrl, {
        cache: 'no-store',
        method: 'HEAD',
      });
      const responseAt = new Date();

      if (ok) {
        var rtt = (responseAt - requestAt);
        var age = 0;
        if (headers.has('Age')) {
          age = parseInt(headers.get("Age")) * 1000;
        }
        //console.log("Age", age);
        const serverDate = new Date(new Date(headers.get("Date")).valueOf() + age);
        //console.log("serverDate", serverDate);
        //console.log("diff", serverDate - responseAt);
        //console.log("rtt", rtt);
        if(rtt < leastRtt) {
          leastRtt = rtt;
          bestDiff = serverDate - responseAt + rtt / 2;
        }
      }
    }
    console.log("bestDiff", bestDiff);
    if(bestDiff != null) {
      commit("setServerTimeDiff", bestDiff);
    }
    var id = setTimeout(() => dispatch("fetchServerTimeNew"), 1 * 60 * 60 * 1000);
    commit("setTimeSyncId", id);
  },
  fetchServerTime: new ApiCall("fetchServerTime")
    .authOptional()
    .withServiceCall((r,h) => judgeService.serverTime(r, h))
    .withRequest(() => new judge_proto.master.ServerTimeRequest())
    .onSuccess(({commit, dispatch}, res) => {
      commit("adjustServerTime", res);
      setTimeout(() => dispatch("fetchServerTime"), 60000);
    })
    .onError((__, {dispatch}) => {
      setTimeout((__, {dispatch}) => dispatch("fetchServerTime"), 120000);
    })
    .build(),

  async initTimeSync({state, commit, dispatch}) {
    if (state.syncInitStarted === true) return;
    console.log("Initing time sync", judge_proto.master);
    commit("syncInitStarted");
    dispatch("fetchServerTimeNew");
    dispatch("timeTick");
  },
  async timeTick({state, commit, dispatch}) {
    var serverTime = (Date.now() + state.serverTimeDiff) / 1000;
    if(Math.abs(serverTime - state.serverTime) > 10) {
      console.log("RESYNC");
      if (state.timeSyncId != null) {
        clearTimeout(state.timeSyncId);
      }
      dispatch("fetchServerTimeNew");
    }
    commit("setCurrentTime", serverTime);
    setTimeout(() => dispatch("timeTick"), 1000);
  },
}

const mutations = {
  fetchServerTimeStatus(state, value) {
    if (value == 1) {
      state.syncRequestedAt = Date.now();
    }
    state.fetchServerTimeStatus = value;
  },
  setServerTimeDiff(state, serverTimeDiff) {
    state.serverTimeDiff = serverTimeDiff;
  },
  adjustServerTime(state, res) {
    var localTime = Date.now();
    var requestDuration = Math.floor((localTime - state.syncRequestedAt) / 2);
    var returnedTime = res.getServerTime();
    state.serverTimeDiff = (returnedTime - (localTime - requestDuration)) / 1000;
    console.log("Server time difference", state.serverTimeDiff);
  },
  setCurrentTime(state, serverTime) {
    state.serverTime = serverTime;
  },
  syncInitStarted(state) {
    state.syncInitStarted = true;
  },
  setTimeSyncId(state, id) {
    state.timeSyncId = id;
  },
};

const getters = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

