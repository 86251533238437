<template>
  <span>
    <v-row v-if="!user">
      <v-col class="pt-4 pb-1">
        Please <router-link to="/community/login"> login </router-link> to submit.
      </v-col>
    </v-row>
    <v-row class="px-2">
      <v-col>
        <Editor
          v-model="code"
					:evaluationType="contentView.getMetaData()?.getProblemMeta()?.getEvaluationType()"
          @setLanguage="setLanguage"
					@resetCode="removeOldCode"
        />
      </v-col>
    </v-row>
    <v-row v-if="user" >
      <!-- <v-col :cols="12" :lg="4"  v-bind:class="{'text-center':$vuetify.breakpoint.mobile }">
        <v-btn class="mr-2" color="accent" height="35" @click="openFileInput">Load File</v-btn>
        <input
          v-show="false"
          type="file"
          ref="codeFileInput"
          accept=".c,.c99,.cxx,.cpp,.h,.java,.py,.txt,text/*"
          @change="loadFile"
        />
      </v-col> -->
      <v-spacer></v-spacer>
      <v-col v-if="!contentView.getMetaData()?.getProblemMeta()?.getEvaluationType() === 1" :cols="11" :lg="2"  v-bind:class="{ 'text-center':$vuetify.breakpoint.mobile }">
        <v-checkbox v-if="!hasWrappedIo" v-model="isUserInput" label="Custom input"></v-checkbox>
      </v-col>
       <v-col :cols="12" :lg="4"  v-bind:class="{'text-center':$vuetify.breakpoint.mobile, 'text-end':!$vuetify.breakpoint.mobile }">
           <v-btn class="mr-2" :disabled="submitting" color="accent" height="35" @click="testRun">Run Code</v-btn>
           <v-btn  :disabled="submitting" color="accent" height="35" @click="fullSubmit">Submit</v-btn>
      </v-col>

    </v-row>
    <v-row v-else>
      <v-spacer></v-spacer>
      <v-col :cols="2" class="pt-2 pb-1">
        <router-link to="/community/login"> Login </router-link> to submit.
      </v-col>
    </v-row>	

    <v-row class="mx-2" v-if="isUserInput">
      <v-textarea outlined label="input" v-model="stdin"></v-textarea>
    </v-row>

		<error-message :ex="ex"></error-message>

	  <v-row ref="judgingStatus" class="mx-2">
      <v-col>
        <div v-if="submissionId">
          <submission-status
            v-if="submissionStatus && submissionStatus.getStatus() == 0"
            title="Waiting..."
            subtitle="Waiting in the queue for your turn. Please be patient."
            imageUrl="judgingImg.svg"
            :status="submissionStatus && submissionStatus.getStatus()"
          > </submission-status>

          <submission-status
            v-else-if="!judgingCompleted"
            title="Judging..."
            :subtitle="'We are processing your submission. ' + testsProgress"
            imageUrl="judgingImg.svg"
            :status="submissionStatus && submissionStatus.getStatus()"
          > </submission-status>

          <submission-status
            v-else-if="judgingCompleted && submissionStatus.getStatus() == 13 && submissionStatus.getSubmissionType() == 0"
						:title="correctTitle(submissionStatus)"
            :subtitle="scoreSubtitle(submissionStatus)"
            :imageUrl="randomCorrectImage()"
            :status="submissionStatus && submissionStatus.getStatus()"
          >
            <v-row>
              <v-col :cols="6" :md="3" :lg="2">
                <stats-col title="Time Taken">
                  {{submissionStatus.getTimeTaken()}} ms
                </stats-col>
              </v-col>
              <v-col :cols="6" :md="3" :lg="2">
                <stats-col title="Memory Taken">
                  {{submissionStatus.getMemoryTaken()}} KiB
                </stats-col>
              </v-col>
              <v-spacer>
              </v-spacer>
            </v-row>
          </submission-status>

          <submission-status
            v-else-if="judgingCompleted && submissionStatus.getStatus() == 13 && submissionStatus.getSubmissionType() == 1"
            title="Samples Passed"
            subtitle="Great! Submit to run against full test set"
            :imageUrl="randomCorrectImage()"
            :status="submissionStatus && submissionStatus.getStatus()"
          >
            <v-row>
              <v-col :cols="6" :md="3" :lg="2">
                <stats-col title="Time Taken">
                  {{submissionStatus.getTimeTaken()}} ms
                </stats-col>
              </v-col>
              <v-col :cols="6" :md="3" :lg="2">
                <stats-col title="Memory Taken">
                  {{submissionStatus.getMemoryTaken()}} KiB
                </stats-col>
              </v-col>
              <v-spacer>
              </v-spacer>
              <v-col :cols="6" :md="4" :lg="3" align="end">
                <v-btn :disabled="submitting" color="accent" height="35" @click="fullSubmit">Submit</v-btn>
              </v-col>
            </v-row>
            <tests-results :resultViews="submissionStatus.getSampleResultViewsList()"></tests-results>
          </submission-status>

          <submission-status
            v-else-if="judgingCompleted && submissionStatus.getStatus() == 13 && submissionStatus.getSubmissionType() == 2"
            title="Successful"
            subtitle="Code ran successfully for custom input"
            :imageUrl="randomCorrectImage()"
            :status="submissionStatus && submissionStatus.getStatus()"
          >
            <v-row>
              <v-col :cols="6" :md="3" :lg="2">
                <stats-col title="Time Taken">
                  {{submissionStatus.getTimeTaken()}} ms
                </stats-col>
              </v-col>
              <v-col :cols="6" :md="3" :lg="2">
                <stats-col title="Memory Taken">
                  {{submissionStatus.getMemoryTaken()}} KiB
                </stats-col>
              </v-col>
              <v-spacer>
              </v-spacer>
              <v-col :cols="6" :md="4" :lg="3" align="end">
                <v-btn :disabled="submitting" color="accent" height="35" @click="fullSubmit">Submit</v-btn>
              </v-col>
            </v-row>
            <tests-results :resultViews="submissionStatus.getSampleResultViewsList()"></tests-results>
          </submission-status>

          <submission-status
            v-else
            :title="statusTitle(submissionStatus.getStatus())"
            :subtitle="statusSubtitle(submissionStatus)"
            imageUrl="Error.svg"
            :status="submissionStatus && submissionStatus.getStatus()"
          >
            <text-block v-if="submissionStatus.getResult()" ><pre>{{submissionStatus && submissionStatus.getResult()}}
            </pre></text-block>
            <tests-results v-if="submissionStatus.getSampleResultViewsList()" :resultViews="submissionStatus.getSampleResultViewsList()"></tests-results>
          </submission-status>
        </div>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Editor from "../components/Editor";
import StatsCol from "../components/StatsCol";
import SubmissionStatus from "../components/SubmissionStatus";
import TestsResults from "./TestsResults.vue";
import TextBlock from "./TextBlock.vue";
import ErrorMessage from "../components/ErrorMessage";

export default {

	props: {
		"contentView": {
		type: Object,
		required: true,
		},
		"parentPreview": {
		type: Object,
		required: false,
		},
	},
	data() {
		return {
		code: null,
		codeFile: null,
		language: null,
				ex: null,
		stdin: null,
		isUserInput: false,
		}
	},
	name: "codingArea",
	computed: {
		...mapState("user", ["hasPremiumAccess", "editorConfig", "user"]),
		...mapGetters("judge", [
		"submissionStatus",
		"judgingCompleted",
		"statusKeys",
		"submitStatus",
		"submitted",
		"submitting",
		]),
		...mapState("judge", ["submissionId", "submissionStatus",  "codeTemplate"]),	
		urlPrefix() {
			if(this.parentPreview) {
			return "/" + this.parentPreview.getContentType().toLowerCase() + "s/" + this.parentPreview.getUrl();
			}
			return null;
		},
		parentId() {
			if(this.parentPreview) {
			return this.parentPreview.getId();
			}
			return null;
		},
		testsProgress() {
			if(!this.submissionStatus || this.submissionStatus.getTotalTests() == 0) return "";
			return "" + this.submissionStatus.getProcessedTests() + "/" + this.submissionStatus.getTotalTests() + " tests processed.";
		},
		hasWrappedIo() {
			return this.contentView && this.contentView.getMetaData().getProblemMeta().getIoType() == 2;
		},
	},


	methods: {
		...mapActions("judge", ["submit", "fetchCodeTemplate"]),
		...mapMutations("judge", ["clearSubmission"]),
		fullSubmit() {
			//console.log("loadtesting submit"); for(var i = 0; i < 500; ++i)
			this.submitCode(true);
		},
		testRun() {
			this.submitCode(false);
		},
		submitCode(isFull) {
			console.log("Contest id", this.parentId);
			this.clearSubmission();
			console.log(isFull, this.isUserInput, this.stdin);
			this.submit({
				id: this.contentView.getId(),
				version: this.contentView.getVersion(),
				code: this.code,
				language: this.language,
				isFull: isFull,
				parentId: this.parentId,
				isUserInput: this.isUserInput,
				stdin: (this.isUserInput && !isFull)? this.stdin: "",
			}).then(__ => this.$nextTick(() => {
				this.$vuetify.goTo(this.$refs.judgingStatus)
			})).catch(ex => this.ex = ex);
		},
		autopick() {
		var url = (this.urlPrefix || "/") + "?autopick=true";
		this.$router.push(url);
		},
		statusTitle(status) {
		if(status < 10) return "Judging";
		if(status <= 18) return ["Compile Error", "Runtime Error", "Time Limit Exceeded", "Correct Answer", "Wrong Answer", "Source Limit Exceeded", "Memory Limit Exceeded", "Skipped", "Output Limit Exceeded"][status-10];
		if(status == 30) return "Judge Error"
		return "Unknown"
		},
		statusSubtitle(submissionStatus) {
			var status = submissionStatus.getStatus();
			if(status == 10) return "Your solution did not compile";
			if(status == 13) return "Congratulations, your solution passed";
			if(11 <= status && status <= 16) {
				if (submissionStatus.getFirstIncorrectTestSet() != "") {
				return "When running test #" +submissionStatus.getFirstIncorrectTestId();
				} else {
				return "There was an error when running one of the tests";
				}
			}
			if(status == 30) return "Something went wrong when running your solution. Please report to hello@codedrills.io";
			return "";
		},
    correctTitle(submissionStatus) {
      console.log("cT", submissionStatus.toObject());
      if (submissionStatus.getScore() < 1) {
        return "Partially Correct"
      } else {
        return "Correct Answer"
      }
    },
		scoreSubtitle(submissionStatus) {
			if(submissionStatus.getScore() == 1) return "Whoaa! You solved this challenge.";
			return "Score: " + (100 * submissionStatus.getScore()).toFixed(2).toString() + " %";
		},
		randomCorrectImage() {
			return ["Congratulations.svg", "Cheers.svg", "Party.svg"][this.submissionId % 3];
		},
		loadFile() {
		var file = this.$refs.codeFileInput.files.item(0);
		var ctx = this;
		file.text()
		.then(code => {
			this.code = code;
			this.codeFile = null;
		});
		},
		openFileInput() {
			this.$refs.codeFileInput.click();
		},
		setLanguage(language) {
			this.language = language;
			this.loadTemplateOrLocalCode();
		},
		loadTemplateOrLocalCode() {
			if(this.user) {
				var key = this.user.uid + '-' + this.language + '-' + this.contentView.getId();
				if(localStorage.getItem(key)) {
					var val = JSON.parse(localStorage.getItem(key));
					const now = Date.now();
					if(now > val.ttl) {
						localStorage.removeItem(key)
						this.loadTemplate();
					} else {
						this.code = val.code;
					}
				}
				else {
					this.loadTemplate();
				}
			} else {
				this.loadTemplate();
			}
		},
		removeOldCode() {
			if(this.user) {
				var key = this.user.uid + '-' + this.language + '-' + this.contentView.getId();
				if(localStorage.getItem(key)) {
						localStorage.removeItem(key)
				}
			}
			this.loadTemplate();
		},
		loadTemplate() {
			if(this.contentView.getMetaData()?.getProblemMeta()?.getEvaluationType() === 1) {
				this.code = "## Write your code here";
				return;
			}
			else if (this.contentView.getMetaData().getProblemMeta().getIoType() === 2) {
				this.fetchCodeTemplate({
					problemId: this.contentView.getId(),
					version: this.contentView.getVersion(),
					language: this.language,
				}).then(__ =>{
					this.code = this.codeTemplate;
				});
			} else {
				this.code = "";
			}
		},
	},
	components: {
		Editor,
		StatsCol,
		SubmissionStatus,
		TestsResults,
		TextBlock,
			ErrorMessage,
	},
	mounted() {
		this.clearSubmission();
		this.loadTemplateOrLocalCode();
	},
	watch: {
		code: {
			handler() {
				console.log("Code Changed !");
				const now = Date.now();
				var expiry = now + 14 * 24 * 60 * 60 * 1000;
				if(this.user) {
				localStorage.setItem(this.user.uid + '-' + this.language + '-' + this.contentView.getId(),
						JSON.stringify({"code": this.code, "language": this.language, "ttl": expiry}));
				}
			},
			deep: true,
		},
	},
};
</script>
<style scoped>
</style>
