<template>
	<v-row>
		<v-col :cols="12">
			<v-sheet class="grey lighten-5 pt-10" :height="550">
				<v-row>
					<v-col :offset="1" :md="7" :lg="5" :xl="5">
							<v-row class="">
								<v-col :cols="12">
									<span v-if="$vuetify.breakpoint.lgAndUp" class="font-weight-bold text-h3" style="line-height: 106px">
										Prepare for coding interviews
									</span>
									<span v-else class="font-weight-bold text-h3" style="line-height: 76px">
										Prepare for coding interviews
									</span>
								</v-col>
							</v-row>
							<v-row>
								<v-col :cols="12" class="text-h6">
									AI powered adaptive learning to get <br> hired at top tech companies.
								</v-col>
							</v-row>
							<v-row>
								<v-col :offset="3" :offset-md="0">
									<v-btn class="text-capitalize" color="accent" height="42" @click="redirectToDashboard"> Start Preparing</v-btn>
								</v-col>
							</v-row>
					</v-col>
					<v-col v-if="$vuetify.breakpoint.mdAndUp" :md="4" :lg="2" :xl="2" class="pt-16 mt-16">
						<img alt="" class="mt-12" :src="require('./../assets/images/home_1.jpg')"/>
					</v-col>
					<v-col :cols="2" v-if="$vuetify.breakpoint.lgAndUp">
						<v-row class="">
							<v-col>
								<img alt="" :src="require('./../assets/images/home_2.jpg')" />
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<img alt="" :src="require('./../assets/images/home_3.jpg')" />
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-sheet>

			<v-sheet v-if="$vuetify.breakpoint.mdAndUp" class="white" :height="100">
			</v-sheet>

			<v-sheet class="mt-10 white" :height="$vuetify.breakpoint.mdAndUp? 370: 3 * 370 - 150">
				<v-row>
					<v-col :offset="1" :cols="10">
							<span v-if="$vuetify.breakpoint.mdAndUp" class="font-weight-bold text-truncate display-1"> How it works </span>
							<span v-else class="font-weight-bold text-truncate text-h6"> How it works </span>
					</v-col>
				</v-row>
				<v-row>
					<v-col :offset="1" :cols="9" :md="3" :lg="3">
						<v-card flat class="pt-1">
							<v-row :height="100">
								<v-col>
									<v-img alt="" :width="119" height="100" :src="require('./../assets/images/Practice.svg')"/>
								</v-col>
							</v-row>
							<v-card-title>
								Practice
							</v-card-title>
							<v-card-text>
								Our AI powered engine will suggest problems to practice and artilces to read based
								on your experience, skills and target companies.
							</v-card-text>
						</v-card>
					</v-col>
					<v-col :offset="1" :cols="9" :offset-md="0" :md="3" :lg="3">
						<v-card flat class="pt-1">
							<v-row :height="100">
								<v-col>
									<v-img alt="" :width="131" height="83" :src="require('./../assets/images/Analyze.svg')"/>
								</v-col>
							</v-row>
							<v-card-title>
								Analyze
							</v-card-title>
							<v-card-text>
								Track your progress and get actionable feedback on your practice performance.
								<br/>
								<router-link class="ml-1 font-weight-bold dashboard_link" to="/community/dashboardSample"> See Sample Dashboard > </router-link>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col :offset="1" :cols="9" :offset-md="0" :md="4" :lg="3">
						<v-card flat class="pt-1">
							<v-row :height="100">
								<v-col>
									<v-img alt="" :width="108" height="89" :src="require('./../assets/images/GetHired.svg')"/>
								</v-col>
							</v-row>
							<v-card-title>
								Get Hired
							</v-card-title>
							<v-card-text>
								Our drills will ensure you perform well in tech interviews at top companies
								like Google, Facebook, Microsoft, Amazon etc.
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-sheet>
			<v-sheet class="mt-10 white lighten-5" :height="$vuetify.breakpoint.mdAndUp? 370: 2 * 370 - 100">
				<v-row>
					<v-col :offset="1" :cols="10">
							<span v-if="$vuetify.breakpoint.mdAndUp" class="font-weight-bold text-truncate display-1" > What's in it for you </span>
							<span v-else class="font-weight-bold text-truncate text-h6"> What's in it for you </span>
					</v-col>
				</v-row>
				<v-row>
					<v-col :offset="1" :cols="10" :sm="8" :offset-md="1" :md="5" >
						<v-card max-width="570" :height="270" outlined class="pl-2 pt-1">
							<v-card-title>
								Candidates
							</v-card-title>
							<v-card-text class="mt-n4">
									<v-row :height="90">
										<v-col :cols="12" :lg="9">
									Practice algorithm problems in any of the supported language and get analysis on
									your performance including strong/weak topics, suggestions on improving your
									implementation and many more.
										</v-col>
									</v-row>
									<v-row align="center">
										<v-col :cols="8">
											<v-btn class="text-capitalize" color="accent" size="35" @click="$router.push('/community/dashboard')">
												Start Now
											</v-btn>
										</v-col>
										<v-spacer></v-spacer>
										<v-col :cols="4" v-if="$vuetify.breakpoint.mdAndUp">
											<v-img alt="" height="95" width="150" :src="require('./../assets/images/Candidates.svg')"/>
										</v-col>
									</v-row>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col :offset="1" :cols="10" :sm="8" :offset-md="0" :md="5">
						<v-card outlined max-width="570" height="270" class="pl-2 pt-1">
							<v-card-title>
								Recruiters
							</v-card-title>
							<v-card-text class="mt-n4">
								<v-row :height="90">
									<v-col :xs="12" :lg="9">
										Use our test creation platform to easily setup online screening rounds.
									</v-col>
								</v-row>
								<v-row align="center">
									<v-col :cols="8">
										<v-btn class="text-capitalize" color="accent" size="35" to="/" rel="noopener">
											Know More
										</v-btn>
									</v-col>
									<v-spacer></v-spacer>
									<v-col :cols="4" v-if="$vuetify.breakpoint.mdAndUp">
										<v-img alt="" height="95" width="150" :src="require('./../assets/images/Recruiters.svg')"/>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-sheet>
			<v-sheet class="mt-10 white lighten-5" :height="$vuetify.breakpoint.lgAndUp? 300: ($vuetify.breakpoint.smAndUp? 2 * 300 - 200: 5 * 300 - 700)">
				<v-row>
					<v-col :offset="1" :cols="10">
							<span v-if="$vuetify.breakpoint.mdAndUp" class="font-weight-bold text-truncate display-1"> Land your dream job </span>
							<span v-else class="font-weight-bold text-truncate text-h6"> Land your dream job </span>
					</v-col>
				</v-row>
				<v-row class="mt-5">
					<v-col :offset="1" :cols="10" align="center" justify="center">
						<v-row>
							<v-col v-for="s in companies" v-bind:key="s">
								<v-card outlined flat :height="112" :width="170">
									<v-row align="center" justify="center">
										<v-col cols="6" class="mt-2">
											<v-avatar :size="66.1" tile>
												<img :alt="s" :src="require('./../assets/images/company/' + s + '@2x.png')"/>
											</v-avatar>
										</v-col>
									</v-row>
								</v-card>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-sheet>
		<!--	<v-row>
				<v-col>
					<ShareNetwork network="LinkedIn" url="https://codedrills.io">Share on LinkedIn</ShareNetwork>
				</v-col>
			</v-row> -->
		</v-col>
	</v-row>
</template>

<script>
import { mapState} from "vuex";
import user from '../store/modules/user';

export default {
	data() {
		return  {
			companies: ["Facebook", "Google", "Amazon", "Apple", "Microsoft"],
		};
	},
	computed: {
    ...mapState("user", ["user"]),
	},
	methods: {
		redirectToDashboard() {
			console.log("user is", this.user);
			if(this.user && !this.user.isAnonymous) {
				return this.$router.push('/community/dashboard');
			} else {
				return this.$router.push('/community/login')
			}
		},
		isUserLoggedIn() {
			if(this.user && !this.user.isAnonymous) this.$router.push('/community/dashboard');
		},
		redirectToNewCampaign() {
			location.href = "https://try.codedrills.io/";
		}
	},
	mounted() {
		this.isUserLoggedIn();
	},
	watch: {
		user: function(user) {
			this.isUserLoggedIn();
		}
	},
};
</script>
<style scoped>
.dashboard_link {
    color: #33A79D;
		text-decoration: none;
};
.v-btn {
		border-radius: 3px;
}
</style>
