import moment from "moment";
export default {
  methods: {
    dispHrs(timeInS) {
      const hrs = moment.duration(timeInS * 1000).hours();
      if (hrs > 0) {
        return `${hrs} hr`;
      } else {
        return ``;
      }
    },
    dispMins(timeInS) {
      const mins = moment.duration(timeInS * 1000).minutes();
      if (mins > 0) {
        return `${mins} min`;
      } else {
        return ``;
      }
    },
    dispSec(timeInS) {
      const sec = moment.duration(timeInS * 1000).seconds();
      if (sec > 0) {
        return `${sec} sec`;
      } else {
        return ``;
      }
    },
    displayTime(timeInS) {
      timeInS = timeInS ?? 0;
      return [
        this.dispHrs(timeInS),
        this.dispMins(timeInS),
        this.dispSec(timeInS),
      ].join(" ").trim();
    },
  },
};
