import customer from "codedrills_proto/io/codedrills/proto/user/customer_service_grpc_web_pb";
import user from "codedrills_proto/io/codedrills/proto/user/user_service_grpc_web_pb";
import { ApiCall } from "@/utils/api.js";

const customerService = new customer.CustomerServicePromiseClient(
  process.env.VUE_APP_USER_API_URL + "/user",
  null,
  null
);

const userService = new user.UserServicePromiseClient(
  process.env.VUE_APP_USER_API_URL + "/user",
  null,
  null
);
var user_proto = proto.io.codedrills.proto.user;

const getDefaultState = () => ({
  user: null,
  customer: null,
  customerImgUrl: "",
  fetchCustomerStatus: null,
  customerSignUpStatus: null,
});

const state = getDefaultState();

const getters = {
  hiringPlanKeys() {
    return Object.keys(user_proto.HiringPlanType);
  },
  prettyHiringPlans() {
    return {
      UNKNOWN_PLAN: "Unknown",
      FREE: "Free",
      SCALE: "Premium",
      ENTERPRISE: "Enterprise",
    };
  },
};

const actions = {
  customerSignUp: new ApiCall("customerSignUp")
    .authOptional()
    .withServiceCall((r, h) => customerService.signUpCustomer(r, h))
    .withRequest(({ email, name }, __, { commit }) => {
      return new user_proto.SignUpCustomerRequest()
        .setCreatorEmail(email)
        .setCreatorName(name)
        .setCustomerName("");
    })
    .onSuccess(({ commit }, res) => commit("setCustomer", res?.getCustomer()))
    .build(),

  fetchCustomer: new ApiCall("fetchCustomer")
    .authRequired()
    .withServiceCall((r, h) => customerService.getCustomer(r, h))
    .withRequest((_, __, { commit }) => {
      return new user_proto.GetCustomerRequest();
    })
    .onSuccess(({ commit }, res) => commit("setCustomer", res?.getCustomer()))
    .build(),
  uploadCompanyImage: new ApiCall("uploadCompanyImage")
    .authRequired()
    .withServiceCall((r, h) => userService.uploadUserImage(r, h))
    .withRequest(({ image }) => {
      // console.log('Data at API', image);
      var req = new user_proto.UploadUserImageRequest()
        .setData(image)
        .setType("image/png");
      return req;
    })
    .onSuccess(({ commit }, res) =>
      commit("setCustomerImgUrl", res?.getImageUrl())
    )
    .build(),
  updateCustomer: new ApiCall("updateCustomer")
    .authRequired()
    .withServiceCall((r, h) => customerService.updateCustomer(r, h))
    .withRequest(({ name, imgUrl }, __, { commit }) => {
      return new user_proto.UpdateCustomerRequest().setCustomer(
        new user_proto.Customer().setName(name).setCompanyLogoUrl(imgUrl)
      );
    })
    .onSuccess(({ commit }, res) => commit("setCustomer", res?.getCustomer()))
    .build(),
};

const mutations = {
  setCustomer(state, cust) {
    state.customer = cust;
    console.log(`setCustomer:`, state.customer);
  },
  setCustomerImgUrl(state, url) {
    state.customerImgUrl = url;
  },
  customerSignUpStatus(state, status) {
    state.customerSignUpStatus = status;
  },
  fetchCustomerStatus(state, status) {
    state.fetchCustomerStatus = status;
  },
  updateCustomerStatus(state, status) {
    //
  },
  uploadCompanyImageStatus(state, status) {},
  resetCustomerState(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
