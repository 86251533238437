var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-4 px-md-16 pt-16"
  }, [_c('div', {
    staticClass: "mb-6"
  }, [_c('p', [_vm._v("The fastest and easiest way to hire tech experts")]), _c('v-btn', {
    staticClass: "text-none text-body-1",
    attrs: {
      "rounded": "",
      "color": "white",
      "dark": "",
      "outlined": "",
      "to": "/signup"
    }
  }, [_vm._v(" Enroll Now ")])], 1), _c('v-row', {
    staticClass: "mx-auto mb-5"
  }, [_vm._l(_vm.footerCols, function (part) {
    return _c('v-col', {
      key: part.title,
      attrs: {
        "cols": "7",
        "md": "2"
      }
    }, [_c('div', {
      staticClass: "text-subtitle-1 font-weight-medium"
    }, [_vm._v(" " + _vm._s(part.title) + " ")]), _c('ul', {
      staticClass: "link-col text-caption text-md-left"
    }, _vm._l(part.links, function (link) {
      return _c('li', {
        key: link.text
      }, [_c('a', {
        staticClass: "footer-link",
        attrs: {
          "href": link.href
        }
      }, [_vm._v(" " + _vm._s(link.text) + " ")])]);
    }), 0)]);
  }), _vm.$vuetify.breakpoint.mdAndUp ? _c('v-col', [_c('v-img', {
    attrs: {
      "src": require("@/assets/images/recruiter/footer.png"),
      "max-width": "300px"
    }
  })], 1) : _vm._e()], 2), _c('hr'), _c('v-row', {
    staticClass: "py-8 px-4 px-md-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-flex flex-row justify-space-around justify-md-start",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "text-body-1 font-weight-medium pr-5"
  }, [_vm._v("Follow Us")]), _c('div', {
    staticClass: "icons"
  }, _vm._l(_vm.socials, function (social) {
    return _c('a', {
      key: social.link,
      staticClass: "pr-2",
      attrs: {
        "href": social.link,
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "white"
      }
    }, [_vm._v(" " + _vm._s(social.icon) + " ")])], 1);
  }), 0)]), _c('v-col', {
    staticClass: "footer-actions d-flex flex-row justify-center justify-md-end",
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-btn', {
    staticClass: "text-none text-body-2",
    attrs: {
      "to": "/signUpCustomer",
      "rounded": "",
      "color": "primary",
      "small": ""
    }
  }, [_vm._v(" Sign Up ")]), _c('v-btn', {
    staticClass: "text-none text-body-2 ml-5",
    attrs: {
      "href": "mailto:hello@codedrills.io",
      "rounded": "",
      "color": "white",
      "small": ""
    }
  }, [_vm._v(" Contact Us ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }