<template>
    <span>
        <v-card class="pl-5 pr-2 py-5">
            <html-view :html="view.getStatementView()"></html-view>
            <v-divider class="mt-5"></v-divider>
            <v-row>
                <v-col :cols="6" :md="3" :lg="2">
                    <stats-col title="Time Limit">
                        {{metadata.getConstraints().getTimeLimitInMs()}} ms
                    </stats-col>
                </v-col>
                <v-col :cols="6" :md="3" :lg="2">
                    <stats-col title="Memory Limit">
                        {{metadata.getConstraints().getMemoryLimitInKb()}} KiB
                    </stats-col>
                </v-col>
                <v-spacer> </v-spacer>
            </v-row>
        </v-card>
        <codingArea :parentPreview="parentPreview" :contentView="contentView"></codingArea>
    </span>
</template>

<script>
import HtmlView from "../components/HtmlView";
import CodingArea from "../components/CodingArea";
import StatsCol from "../components/StatsCol";

export default {
  props: {
    "contentView": {
      type: Object,
      required: true,
    },
    "parentPreview": {
      type: Object,
      required: false,
    },
  },
  name: "problemDescription",
  computed: {
    view() {
        return this.contentView.getDataView().getProblemView();
    },
    metadata() {
        return this.contentView.getMetaData().getProblemMeta();
    },
  },
  components: {
      CodingArea,
      HtmlView,
      StatsCol,
  }

};
</script>
<style scoped>
</style>
