<template>
  <v-carousel hide-delimiters cycle show-arrows-on-hover height="400px">
    <v-carousel-item v-for="(item, i) in items" :key="i">
      <v-card
        elevation="0"
        rounded="lg"
        class="pa-8"
        dark
        color="black"
        height="100%"
      >
        <v-card-title class="font-weight-bold text-h5">
          <v-row no-gutters>
            <v-col cols="3" md="2">
              <v-avatar>
                <img :src="item.src" />
              </v-avatar>
            </v-col>
            <v-col>
              <div>
                {{ item.name }}
              </div>
              <div class="text-caption">
                {{ item.subtitle }}
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-subtitle class="">
          <v-rating
            :value="item.rating"
            class="pt-5"
            size="32"
            color="primary"
            dense
            readonly
          >
          </v-rating>
        </v-card-subtitle>
        <v-card-text class="white--text text-body-1 mt-8">
          {{ item.review }}
        </v-card-text>
      </v-card>
    </v-carousel-item>
  </v-carousel>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          src: "https://media-exp1.licdn.com/dms/image/C5603AQGJa2g1bUr8LA/profile-displayphoto-shrink_800_800/0/1609038963409?e=1653523200&v=beta&t=xUIjRCDOrUQWBRf2YyVHr2jUIW70AUtvHWHKhk1kLEk",
          name: "Maheshwara Chaitanya",
          subtitle: "Contest Director, Amrita Vishwa Vidyapeetham",
          review:
            "It has been a really good experience with the codedrills.io platform while hosting the world's largest collegiate programming contest - ICPC. We hosted the 2021 contest with the highest participation of 14000+ contestants. Codedrills was the perfect platform to run the contest - to start on time and end on time without any hassles. Will use it in all the future programming contests hosted by Amrita Vishwa Vidyapeetham.",
          rating: 5,
        },
        {
          src: "https://storage.googleapis.com/frontend-common/pp.jpeg",
          name: "Pritam Thakur",
          subtitle: "Co-Founder, Ivy Homes",
          review:
            "Using CodeDrills, we set up our team of data scientists quickly. We used the customised tests to assess the candidate's skills accurately and save time",
          rating: 5,
        },
      ],
    };
  },
};
</script>