var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.liveContestPreviewsList && _vm.liveContestPreviewsList.length > 0 ? _c('div', [_c('v-row', {
    staticClass: "mt-12"
  }, [_c('v-col', {
    staticClass: "font-weight-bold text-truncate text-h5"
  }, [_vm._v(" Live Contests ")])], 1), _c('contest-list', {
    attrs: {
      "contestList": _vm.liveContestPreviewsList
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }