<template  v-slot:extension>
	<span>
		<v-tabs :vertical="$vuetify.breakpoint.smAndDown" color="accent" v-model="tab">
			<v-tab>Summary</v-tab>
			<v-tab v-if="isTeamContest">My Team</v-tab>
			<v-tab v-if="isTeamContest">All Contest Teams</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tab">
			<v-tab-item>
				<v-row class="mt-8">
					<v-col>
						<v-row>
							<v-col :cols="6" :sm="4" :lg="2">
								Total Participations
							</v-col>
							<v-col :cols="6" :sm="4" :lg="2">
								Total Submissions
							</v-col>
						</v-row>
						<v-row v-if="scoreboard">
							<v-col :cols="6" :sm="4" :lg="2">
								{{ scoreboard.getScoreboardStats().getTotalParticipants() }}
							</v-col>
							<v-col :cols="6" :sm="4" :lg="2">
								{{ scoreboard.getScoreboardStats().getTotalSubmissions() }}
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-card>
									<v-data-table :items-per-page="15" :hide-default-footer="true" :headers=headers :items=userScores>
										<template v-slot:item="{ item }">
											<tr>
												<td>
													{{ item.rank }}
												</td>
												<td>
													<router-link v-if="isTeamContest" :to="teamUrl(item)"
														class="text-decoration-none router_link">
														{{ getName(item) }}
													</router-link>
													<router-link v-else-if="item.userPreview.handle" :to="`/profiles/${item.userPreview.handle}`"
														class="text-decoration-none router_link">
														{{ item.userPreview.handle }}
													</router-link>
													<span v-else>
														{{ getName(item) }}
													</span>
												</td>
												<td v-if="isTeamContest">
													{{ teamOrganization(item) }}
												</td>
												<td>
													{{ item.score }}
												</td>
												<td>
													{{ prettyPenalty(item.penalty) }}
												</td>
											</tr>
										</template>
									</v-data-table>
								</v-card>
							</v-col>
						</v-row>
						<v-row class="mt-10">
							<v-col>
								<problem-list :urlPrefix="'/contests/' + this.contentView.getUrl()" :problems="problems" showIds>
								</problem-list>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<div class="display-1">About</div>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-card flat outlined class="pl-5 py-3">
									<div class="d-flex flex-no-wrap">
										<div class="d-flex align-center">
											<html-view :html="view.getAboutHtml()"></html-view>
										</div>
									</div>
								</v-card>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<div class="display-1">Rules</div>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-card flat outlined class="pl-5 py-3">
									<div class="d-flex flex-no-wrap">
										<div class="d-flex align-center">
											<html-view :html="view.getRulesHtml()"></html-view>
										</div>
									</div>
								</v-card>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-tab-item>
			<v-tab-item>
				<v-row v-if="!user">
					<v-col>
						Please <router-link to="/community/login"> login </router-link> to create team
					</v-col>
				</v-row>
				<v-row v-else-if="userContestTeams && userContestTeams.length == 0">
					<v-col>
						You are not member of any team
					</v-col>
				</v-row>
				<v-row v-else-if="!acceptedTeam">
					<v-col>
						No Accepted Invitation
					</v-col>
				</v-row>
				<v-row v-if="acceptedTeam">
					<v-col>
						<team-detail :teamView="acceptedTeam" :editableContest="editableContest"></team-detail>
					</v-col>
				</v-row>
			</v-tab-item>
			<v-tab-item>
				<v-row>
					<v-col>
						<teams-list :contestId="contentView.getId()" :contestUrl="contentView.getUrl()"></teams-list>
					</v-col>
				</v-row>
			</v-tab-item>
		</v-tabs-items>
	</span>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ProblemList from "../components/ProblemList"
import HtmlView from "../components/HtmlView";
import TeamsList from "../components/TeamsList";
import TeamDetail from "../components/TeamDetail";
import { prettytime } from "../utils/helper.js";

export default {
	props: {
		contentView: {
			type: Object,
			required: true
		},
	},

	data: function () {
		return {
			tab: null,
			summaryPagination: {
				pageIndex: 1,
				itemsPerPage: 15,
			},
			staticHeaders: [
				{
					text: "Rank",
					align: "left",
					value: "rank",
				},
				{
					text: "Winners",
					align: "left",
					value: "userPreview.name",
				},
				{
					text: "Score",
					align: "left",
					value: "score",
				},
				{
					text: "Penalty",
					align: "left",
					value: "score",
				},
			]
		}
	},

	computed: {
		...mapState("content", ["scoreboard", "fetchContestScoreboardStatus"]),
		...mapState('user', ['team', 'userContestTeams', 'user']),
		headers() {
			var headers = [...this.staticHeaders];
			if (!this.scoreboard) return headers;
			if (this.isTeamContest) {
				headers.splice(2, 0, {
					text: 'Organization',
					value: 'organization',
				});
			}
			return headers;
		},
		userScores() {
			if (!this.scoreboard) return [];
			var scores = this.scoreboard && this.scoreboard.getUserScoresList()
				.map((s) => {
					var us = s.toObject();
					us.userProblemScoresMap = {};
					us.userProblemScoresList.forEach(ps => { us.userProblemScoresMap[ps.problemId] = ps; });
					return us;
				});
			return scores;
		},
		view() {
			return this.contentView.getDataView().getContestView();
		},
		problems() {
			return this.contentView.getChildPreviewsList();
		},
		isTeamContest() {
			if (this.scoreboard)
				return this.scoreboard.getContestPreview().getMetaData().getContestMeta().getContestType() == 1;
		},
		acceptedTeam() {
			return this.userContestTeams && this.userContestTeams
				.find(t => {
					var isAccepted = t.getMembersList()
						.find(u => u.getEmail() === this.user.email).getMembershipState() == 2;
					console.log(t.toObject(), isAccepted);
					return isAccepted;
				});
		},
		editableContest() {
			var editableContest = this.contentView.getMetaData().getContestMeta() &&
				this.contentView.getMetaData().getContestMeta().getTeamContestConfig() &&
				this.contentView.getMetaData().getContestMeta().getTeamContestConfig().getIsEditable();
			console.log("editableContest ....", editableContest);
			return editableContest;
		},
	},

	methods: {
		...mapActions("content", ["fetchContestScoreboard"]),
		prettyPenalty(sec) {
			return prettytime(sec);
		},

		getName(item) {
			var name;
			if (this.isTeamContest)
				name = item.teamPreview && item.teamPreview.name;
			else
				name = item.userPreview && item.userPreview.name;
			return name || "Unnamed";
		},
		teamUrl(item) {
			if (this.isTeamContest && item.teamPreview) {
				var url = '/contests/' + this.contentView.getUrl() + '/teams/' + item.teamPreview.id;
				return url;
			}
		},
		teamOrganization(item) {
			if (this.isTeamContest) {
				var org = item.teamPreview.organizationsList[0];
				if (org.length > 0) {
					org = org.replace(/&\bamp\b;/g, "");
					org = org.replace(/\bamp\b;/g, "");
				}
				return org;
			}
		},
	},

	async mounted() {
		this.fetchContestScoreboard({ url: this.contentView.getUrl(), paginationQuery: this.summaryPagination });
	},

	components: {
		ProblemList,
		HtmlView,
		TeamDetail,
		TeamsList,
	}
};
</script>
<style scoped>
.router_link {
	color: inherit;
}

.v-tabs--vertical>.v-tabs-bar .v-tab {
	min-width: 100%;
}
</style>
