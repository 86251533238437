<template>
  <div class="mt-n8">
    <Navbar />
    <v-row align="center" justify="center" class="py-16">
      <v-col cols="12" md="10" lg="8">
        <v-card class="px-4 px-lg-16 py-16">
          <v-card-title class="text-h5 font-weight-medium black--text">
            {{ title }}
          </v-card-title>
          <v-card-subtitle class="text-h6 px-6 font-weight-medium black--text">
            <v-row>
              <v-col>
                Read the instructions carefully before you start the assessment!
                </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text class="px-8">
            <v-row class="text-body-2">
              <v-col class="black--text">
                <InstructionLabel
                  :iconName="mdiTimer"
                  :labelText="`Duration: ${testDuration}`"
                  :iconColor="brandColor"
                />
              </v-col>
              <InstructionLabel
                :iconName="mdiCloseBoxOutline"
                :labelText="`Screen Unchangeable`"
                iconColor="success"
                :wide="true"
              />
            </v-row>
            <!-- <v-row class="mt-n8">
              <v-col>
                <InstructionLabel
                  :iconName="mdiVideo"
                  :labelText="`Camera Required`"
                  :iconColor="brandColor"
                />
              </v-col>
              <v-col>
                <InstructionLabel
                  :iconName="mdiAlarm"
                  :labelText="`Deadline`"
                  :iconColor="brandColor"
                />
              </v-col>
              <v-col> </v-col>
            </v-row> -->
            <v-row class="px-5">
              <ul class="text-subtitle-1 black--text px-1">
                <li v-for="(rule, i) in rules" :key="`rule=${i}`">
                  {{ rule }}
                </li>
              </ul>
              <ul class="text-subtitle-1 black--text px-1">
                <li>
                  We recommend you to solve this <a href='https://codedrills.io/problems/fizz-buzz'>problem</a> before starting the assessment to familiarize yourself with the platform.
                </li>
              </ul>
            </v-row>
            <!-- <v-row>
              <v-alert
                :color="brandError"
                outlined
                class="mt-5"
                :icon="mdiVideoOff"
              >
                Please make sure to connect Web camera, if your laptop do not
                have camera to start the assessment test.
              </v-alert>
            </v-row> -->
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="agreeToTOS"
                  :error-messages="TOSError"
                  @change="validateTOS"
                  :rules="[(v) => !!v || 'You must agree to continue!']"
                >
                  <template v-slot:label>
                    I have read all the rules clearly and will follow them during the assessment. I will not use any means of cheating.
                    If found cheating during the assessment, I will be banned from the CodeDrills platform.
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-center mt-8">
            <v-btn
              :disabled="!agreeToTOS"
              @click="start"
              elevation="0"
              :color="brandColor"
              :loading="startLoading"
              class="white--text text-h6 pa-5 text-none"
            >
              Start Assessment
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import moment, { duration } from "moment";
import {
  mdiTimer,
  mdiVideoOff,
  mdiAlarm,
  mdiVideo,
  mdiCloseBoxOutline,
} from "@mdi/js";
import { toggleFullScreen } from "../utils/helper";
import InstructionLabel from "../components/assessment/InstructionLabel.vue";
import Navbar from "../components/Navbar.vue";
import formattedTimeMixin from "@/mixins/formattedTimeMixin";

export default {
  props: {
    testView: {
      type: Object,
      required: true,
    },
  },
  mixins: [formattedTimeMixin],
  data() {
    return {
      brandColor: "#2EAC68",
      brandError: "#D30001",
      mdiVideoOff,
      mdiAlarm,
      mdiVideo,
      mdiTimer,
      mdiCloseBoxOutline,
      startLoading: false,
      rules: [
       "This assessment is timed. A timer for overall assessment is shown at the top right.",
       "Each question is also timed. A timer per question is shown as well.",
        "Once you click 'Start Assessment', the timer starts for the defined duration.",
        "Once started, the timer can not be paused or restarted.",
        "During the assessment, you can only move to the next question by 'Next' button. Previous questions can not be visited again.",
        "The assessment will end automatically when the last question is submitted.",
        "Make sure you are using a chromium-based browser, e.g. Google Chrome.",
        "Your assessment may contain one or more coding problems. You can submit code multiple times using the 'Submit' button, and only the best result will be considered.",
        "There maybe SQL problems. SQL problems are considered coding problems with language restriction to only SQL languages. For such problems, you need to write SQL queries as the code.",
        "For coding problems - the language might be restricted, in case there is any restriction, you would be able to submit only in those languages.",
        "For coding problems - 'Run Code' button is only for running your code against sample test cases and it is not considered for scoring.",
        "For coding problems - 'Submit' button is for making a valid submission and it runs on hidden test cases; on success it shows score based on how many test cases passed.",
        "For coding problems - the highest scoring submission out of all the submissions made with 'Submit' button is considered as your answer.",
        "For coding problems - on clicking the 'Next' button, all the submissions made with 'Submit' button would automatically be considered for scoring.",
        "For coding problems - if there is no submission made with 'Submit' button, the current code in the code editor will not be auto-submitted and not considered for \
        scoring. On clicking 'Next' without any submissions, the problem would be considered as skipped.",
        "For MCQ problems - there maybe one or more than one correct options.",
        "For MCQ problems - on clicking the 'Next' button, chosen answers for MCQ will be submitted automatically.",
        "For MCQ problems - if the 'Next' button is clicked without selecting any options then the problem is considered as skipped.",
        "We recommend completing the assessment in one go.",
      ],
      agreeToTOS: undefined,
      TOSError: [],
      signUpForm: false,

    };
  },
  computed: {
    ...mapState("user", ["hasPremiumAccess", "editorConfig", "user"]),
    ...mapState("candidate", ["currentTestView"]),
    title() {
      return (
        this.testView?.getTestView()?.getTestPreview()?.getTitle() ??
        "Unknown Title"
      );
    },
    testDuration() {
      return this.displayTime(
        this.testView?.getTestView()?.getTestTimeInfo()?.getDuration()
      );
    },
    durationInHours() {
      return Math.floor(this.duration / 3600);
    },
    durationInMinutes() {
      return Math.floor((this.duration % 3600) / 60);
    },
    durationInSeconds() {
      return Math.round(this.duration % 60);
    },
  },
  methods: {
    ...mapActions("candidate", ["startHiringTest"]),
    validateTOS() {
      if (!this.agreeToTOS) {
        this.TOSError.push("You must agree to continue!");
        return false;
      } else {
        this.TOSError = [];
        return true;
      }
    },
    start() {
      this.startLoading = true;
      this.startHiringTest({
        test_id: this.currentTestView.getTestView().getTestPreview().getId(),
      }).then((__) => {
        this.startLoading = false;
        // window.location.reload();
        const d = document?.documentElement;
        if (d.requestFullscreen) {
          d.requestFullscreen();
        } else if (d.mozRequestFullScreen) {
          d.mozRequestFullScreen();
        } else if (d.webkitRequestFullScreen) {
          d.webkitRequestFullScreen();
        } else if (d.msRequestFullscreen) {
          d.msRequestFullscreen();
        }
      });
    },
  },
  created() {
    document.title = `${this.title} - CodeDrills`;
  },
  watch: {
    currentTestView: function (n, o) {
      console.log(`currentTestView`, n, o);
    },
  },
  components: {
    InstructionLabel,
    Navbar,
  },
};
</script>
<style scoped>
li::marker {
  color: #2eac68;
}
</style>
