<template>
<span>
	<v-tabs :vertical="$vuetify.breakpoint.smAndDown" color="accent" v-model="tab">
		<v-tab>Overview</v-tab>
		<v-tab v-if="teamContest">My Team</v-tab>
		<v-tab v-if="teamContest">All Contest Teams</v-tab>
		</v-tabs>
	<v-tabs-items v-model="tab">
		<v-tab-item>
			<v-row class="mt-8">
				<v-col>
					<div class="display-1">About</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col :cols="11">
						<v-card flat outlined class="pl-5 py-3">
							<div class="d-flex flex-no-wrap">
								<div class="d-flex align-center">
									<html-view :html="view.getAboutHtml()"></html-view>
								</div>
							</div>
						</v-card>
				</v-col>
			</v-row>
			<v-row class="mt-8">
				<v-col>
					<div class="display-1">Rules</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col :cols="11">
						<v-card flat outlined class="pl-5 py-3">
							<div class="d-flex flex-no-wrap">
								<div class="d-flex align-center">
									<html-view :html="view.getRulesHtml()"></html-view>
								</div>
							</div>
						</v-card>
				</v-col>
			</v-row>
		</v-tab-item>
		<v-tab-item>
			<v-row v-if="!user">
				<v-col>
					Please <router-link to="/community/login"> login </router-link> to create team
				</v-col>
			</v-row>
			<v-row v-else-if="userContestTeams && userContestTeams.length == 0">
				<v-col >
					<v-alert outlined border="top" color="orange" type="info" >
						You are not a member of any team
					</v-alert>
					<v-row v-if="teamContest && editableContest">
							<v-col>
								<team-dialog :error="teamCreationFailed" 
								:teamContestConfig="teamContestConfig" 
								@callCreateTeam="handleCreateTeam"></team-dialog>
								<error-message :ex="ex"></error-message>
							</v-col>
					</v-row>
					</v-col>
			</v-row>
			<v-row v-else-if="userContestTeams && !acceptedTeam">
				<v-col>
					<v-alert outlined border="top" color="orange" type="info" >
						No accepted invitation. Accept an invitation or create a new team
					</v-alert>
					<v-row v-if="teamContest && editableContest">
						<v-col>
							<team-dialog :error="teamCreationFailed" :teamContestConfig="teamContestConfig" @callCreateTeam="handleCreateTeam"></team-dialog>
						</v-col>
					</v-row>
					<div v-for="team of userContestTeams" :key="team.getId()">
						<team-detail :teamView="team"></team-detail>
					</div>
				</v-col>
			</v-row>
			<v-row v-else-if="acceptedTeam">
				<v-col>
					<!-- delete only if team exists -->
					<team-detail :teamView="acceptedTeam" :editableContest="editableContest" :contestUrl="contentView.getUrl()"></team-detail>
				</v-col>
			</v-row>
		</v-tab-item>
		<v-tab-item>
				<v-row>
					<v-col>
						<teams-list :contestId="contentView.getId()" :contestUrl="contentView.getUrl()"></teams-list>
					</v-col>
				</v-row>
		</v-tab-item>
	</v-tabs-items>
</span>
</template>

<script>
import HtmlView from "../components/HtmlView";
import { mapActions, mapState} from "vuex";
import TeamDialog from "../components/TeamDialog";
import TeamsList from './TeamsList.vue';
import TeamDetail from "../components/TeamDetail";
import ErrorMessage from "../components/ErrorMessage";

export default {
  props: {
    contentView: {
      type: Object,
      required: true
    }
  },
	data: function() {
		return {
			ex: null,
			tab: null,
      teamCreationFailed: false,
		}
	},
	methods: {
    ...mapActions("content", ["fetchContentView"]),
		...mapActions("user", ["createTeam", "getUserContestTeams"]),
		handleCreateTeam(details) {
			console.log("Team details ...", details.toObject());
			details.setContentId(this.contentView.getId());
			console.log("Team details ...?", details.toObject());
      this.createTeam({teamDetails: details}).then(() => {
        this.$router.push(`/contests/${this.team.getTeamView()
                    .getContentPreview()
                    .getUrl()}/teams/${this.team.getTeamView().getId()}`);
					}).catch(ex => {
                      this.teamCreationFailed = true;
					  this.ex = ex;
                    });
    },
	},
  computed: {
    ...mapState("timesync", ["serverTime"]),
    ...mapState('user', ['team', 'userContestTeams', 'user']),
    view() {
      return this.contentView.getDataView().getContestView();
    },
		teamContestConfig() {
      return this.contentView.getMetaData()
					.getContestMeta()
					.getTeamContestConfig();
		},
		teamContest() {
			return this.contentView.getMetaData()
                .getContestMeta()
                .getContestType() == 1;
		},
		editableContest() {
			var editableContest = this.contentView.getMetaData().getContestMeta() &&
			 this.contentView.getMetaData().getContestMeta().getTeamContestConfig() &&
				this.contentView.getMetaData().getContestMeta().getTeamContestConfig().getIsEditable();
				console.log("editableContest ....", editableContest);
				return editableContest;
		},
		acceptedTeam() {
			console.info("User contest teams ...", this.userContestTeams);
			return this.userContestTeams && this.userContestTeams
                      .find(t => {
												var member = t.getMembersList()
																.find(u => u.getEmail() === this.user.email);
												if(member) {
													var isAccepted = member.getMembershipState() == 2;
																return isAccepted;
												}
											});
		},
  },

  components: {
	  HtmlView,
		TeamDialog,
    TeamsList,
	    TeamDetail,
	ErrorMessage,
  }
};
</script>
<style scoped>
.v-tabs--vertical > .v-tabs-bar .v-tab {
    min-width: 100%;
}
</style>
