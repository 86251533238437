var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.initialized ? _c('v-app', [_c('List'), _c('BaseLayout', [_c('router-view', {
    key: _vm.$route.fullPath
  })], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }