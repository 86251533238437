var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-3"
  }, [_c('v-dialog', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "accent",
            "dark": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" Create Team ")])];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-form', {
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card-title', [_vm.contestBannerUrl ? _c('img', {
    staticStyle: {
      "max-width": "100%"
    },
    attrs: {
      "src": _vm.contestBannerUrl,
      "alt": "contest banner"
    }
  }) : _c('div', [_vm._v("New Team")])]), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "color": "accent",
      "label": "Team name*",
      "required": "",
      "rules": [_vm.rules.required]
    },
    on: {
      "input": _vm.editTeamName
    },
    model: {
      value: _vm.teamName,
      callback: function callback($$v) {
        _vm.teamName = $$v;
      },
      expression: "teamName"
    }
  })], 1)], 1), _c('custom-attributes', {
    attrs: {
      "customAttributes": _vm.teamContestConfig.toObject().teamCustomAttributeMap
    },
    on: {
      "change": function change(attributeValue) {
        return _vm.addTeamValue(attributeValue);
      }
    }
  }), _vm.coachEnabled ? _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "color": "accent",
      "label": "Coach email".concat(_vm.coachFieldRequired() ? '*' : '', " "),
      "rules": _vm.coachFieldRequired() ? _vm.emailRules : [],
      "required": _vm.coachFieldRequired()
    },
    on: {
      "input": function input(e) {
        return _vm.setCoachEmail(e);
      }
    }
  }), _c('span', {
    staticClass: "text-caption mb-4 pb-4"
  }, [_vm._v(" A mentor can be anyone who can motivate and support the students(eg: Teacher, Counsellor) ")])], 1)], 1) : _vm._e(), _vm.coachEnabled ? _c('custom-attributes', {
    attrs: {
      "customAttributes": _vm.teamContestConfig.toObject().coachSetting.coachCustomAttributeMap
    },
    on: {
      "change": function change(attributeValue) {
        return _vm.addCoachAttributes(attributeValue);
      }
    }
  }) : _vm._e(), _vm._l(_vm.teamContestConfig.getMaxTeamSize(), function (n) {
    return _c('span', {
      key: n
    }, [_c('v-row', {}, [_c('v-col', {
      attrs: {
        "cols": "10",
        "lg": 6
      }
    }, [_c('v-text-field', {
      attrs: {
        "color": "accent",
        "label": _vm.memberLabel(n),
        "rules": _vm.emailRules
      },
      on: {
        "input": function input(e) {
          return _vm.setMemberEmail(n, e);
        }
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "10",
        "lg": 6
      }
    }, [_c('custom-attributes', {
      attrs: {
        "needsValidation": _vm.customPropRevalidate[n],
        "customAttributes": _vm.teamContestConfig.toObject().memberCustomAttributeMap
      },
      on: {
        "change": function change(attributeValue) {
          return _vm.addMemberAttributes(n, attributeValue);
        }
      }
    })], 1)], 1)], 1);
  }), _c('v-row', [_c('v-col', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [!_vm.hasSchoolAttribute ? _c('v-text-field', {
    attrs: {
      "color": "accent",
      "label": "Organization"
    },
    model: {
      value: _vm.organization,
      callback: function callback($$v) {
        _vm.organization = $$v;
      },
      expression: "organization"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 2), _c('small', [_vm._v("*indicates required field")])], 1), _vm.isTeamNameEdited ? _c('v-card-actions', [_c('v-checkbox', {
    attrs: {
      "label": "I confirm that the team name is not inappropriate. I understand that I may be banned from the platform if it is inappropriate"
    },
    model: {
      value: _vm.checked,
      callback: function callback($$v) {
        _vm.checked = $$v;
      },
      expression: "checked"
    }
  })], 1) : _vm._e(), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "grey darken-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" Close ")]), _c('v-btn', {
    attrs: {
      "disabled": !_vm.checked,
      "color": "grey darken-1",
      "text": ""
    },
    on: {
      "click": _vm.createTeam
    }
  }, [_vm._v(" Create ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }