<template>
  <v-row ref="judgingStatus" class="pt-2">
    <v-col>
      <div v-if="submissionId">
        <submission-status
          v-if="submissionStatus && submissionStatus.getStatus() == 0"
          title="Waiting..."
          subtitle="Waiting in the queue for your turn. Please be patient."
          :status="submissionStatus && submissionStatus.getStatus()"
        >
        </submission-status>

        <submission-status
          v-else-if="!judgingCompleted"
          title="Judging..."
          :subtitle="'We are processing your submission. ' + testsProgress"
          :status="submissionStatus && submissionStatus.getStatus()"
        >
        </submission-status>

        <submission-status
          v-else-if="
            judgingCompleted &&
            submissionStatus.getStatus() == 13 &&
            submissionStatus.getSubmissionType() == 0
          "
          title="Correct Answer"
          :subtitle="scoreSubtitle(submissionStatus)"
          :status="submissionStatus && submissionStatus.getStatus()"
        >
          <v-row>
            <v-col :cols="6" :md="4">
              <stats-col title="Time Taken">
                {{ submissionStatus.getTimeTaken() }} ms
              </stats-col>
            </v-col>
            <v-col :cols="6" :md="4">
              <stats-col title="Memory Taken">
                {{ submissionStatus.getMemoryTaken() }} KiB
              </stats-col>
            </v-col>
            <v-spacer> </v-spacer>
          </v-row>
        </submission-status>

        <submission-status
          v-else-if="
            judgingCompleted &&
            submissionStatus.getStatus() == 13 &&
            submissionStatus.getSubmissionType() == 1
          "
          title="Samples Passed"
          subtitle="Press Submit to test against the full test suite."
          :status="submissionStatus && submissionStatus.getStatus()"
        >
          <v-row>
            <v-col :cols="6" :md="4">
              <stats-col title="Time Taken">
                {{ submissionStatus.getTimeTaken() }} ms
              </stats-col>
            </v-col>
            <v-col :cols="6" :md="4">
              <stats-col title="Memory Taken">
                {{ submissionStatus.getMemoryTaken() }} KiB
              </stats-col>
            </v-col>
            <v-spacer> </v-spacer>
          </v-row>
          <tests-results
            :resultViews="submissionStatus.getSampleResultViewsList()"
          ></tests-results>
        </submission-status>

        <custom-submission-status
          v-else-if="
            judgingCompleted &&
            submissionStatus.getStatus() == 13 &&
            submissionStatus.getSubmissionType() == 2
          "
          title="Successful"
          subtitle="Code ran successfully for custom input."
          :status="submissionStatus && submissionStatus.getStatus()"
        >
          <v-row>
            <v-col :cols="6" :md="4">
              <stats-col title="Time Taken">
                {{ submissionStatus.getTimeTaken() }} ms
              </stats-col>
            </v-col>
            <v-col :cols="6" :md="4">
              <stats-col title="Memory Taken">
                {{ submissionStatus.getMemoryTaken() }} KiB
              </stats-col>
            </v-col>
            <v-spacer> </v-spacer>
          </v-row>
          <custom-tests-results
            :resultViews="submissionStatus.getSampleResultViewsList()"
          ></custom-tests-results>
        </custom-submission-status>

        <custom-submission-status
          v-else
          :title="statusTitle(submissionStatus.getStatus())"
          :subtitle="statusSubtitle(submissionStatus)"
          :status="submissionStatus && submissionStatus.getStatus()"
        >
          <text-block v-if="submissionStatus.getResult()">
            <pre>
              {{ submissionStatus && submissionStatus.getResult() }}
            </pre>
          </text-block>
          <custom-tests-results
            v-if="submissionStatus.getSampleResultViewsList()"
            :resultViews="submissionStatus.getSampleResultViewsList()"
          ></custom-tests-results>
        </custom-submission-status>
      </div>
      <div v-else class="pa-5">Submitting ...</div>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import SubmissionStatus from "../../SubmissionStatus.vue";
import TestsResults from "../../TestsResults.vue";
import TextBlock from "../../TextBlock.vue";
import StatsCol from "../../StatsCol.vue";
import CustomTestsResults from "../../CustomTestsResults.vue";
import CustomSubmissionStatus from "./CustomSubmissionStatus.vue";
export default {
  components: {
    TestsResults,
    SubmissionStatus,
    TextBlock,
    StatsCol,
    CustomTestsResults,
    CustomSubmissionStatus
  },
  computed: {
    ...mapState("judge", ["submissionId", "submissionStatus", "codeTemplate"]),
    ...mapGetters("judge", [
      "submissionStatus",
      "judgingCompleted",
      "statusKeys",
      "submitStatus",
      "submitted",
      "submitting",
    ]),
    testsProgress() {
      if (!this.submissionStatus || this.submissionStatus?.getTotalTests() == 0)
        return "";
      return `${this.submissionStatus.getProcessedTests()}/${this.submissionStatus.getTotalTests()} tests processed`;
    },
  },
  methods: {
    statusTitle(status) {
      if (status < 10) return "Judging";
      if (status <= 18)
        return [
          "Compile Error",
          "Runtime Error",
          "Time Limited Exceeded",
          "Correct Answer",
          "Wrong Answer",
          "Source Limit Exceeded",
          "Memory Limit Exceeded",
          "Skipped",
          "Output Limit Exceeded",
        ][status - 10];
      if (status == 30) return "Judge Error";
      return "Unknown";
    },
    statusSubtitle(submissionStatus) {
      var status = submissionStatus.getStatus();
      if (status == 10) return "Your solution did not compile";
      if (status == 13) return "Congratulations, your solution passed";
      if (11 <= status && status <= 16) {
        if (submissionStatus.getFirstIncorrectTestSet() != "") {
          return (
            "When running test #" + submissionStatus.getFirstIncorrectTestId()
          );
        } else {
          return "There was an error when running one of the tests";
        }
      }
      if (status == 30)
        return "Something went wrong when running your solution. Please report to hello@codedrills.io";
      return "";
    },
    scoreSubtitle(submissionStatus) {
      if (submissionStatus.getScore() == 1)
        return "Whoaa! You solved this challenge.";
      return (
        "Score: " +
        (100 * submissionStatus.getScore()).toFixed(2).toString() +
        " %"
      );
    },
  },
};
</script>
<style scoped>
pre {
  white-space: pre-wrap;
}
</style>
