<template>
  <main>
    <nav class="px-12 py-8">
      <v-row class="d-flex flex-md-col" justify="center" align="center">
        <router-link to="/" class="logo">
          <!-- <span class="text-h5">CodeDrills</span> -->
          <v-img
            src="~@/assets/images/recruiter/nav.png"
            height="64"
            width="180"
            alt="CodeDrills Logo"
            contain
          ></v-img>
        </router-link>
        <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
        <v-btn
          text
          class="text-none mr-3"
          color="success"
          to="/community/dashboard"
          :outlined="!$vuetify.breakpoint.mdAndUp"
        >
          Community
        </v-btn>
        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          text
          class="text-none mr-3"
          href="https://info.codedrills.io/blog"
        >
          Blog
        </v-btn>
        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          text
          class="text-none mr-3"
          href="mailto:hello@codedrills.io"
        >
          Contact Us
        </v-btn>
        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          text
          class="text-none mr-3"
          to="/login"
        >
          Login
        </v-btn>
      </v-row>
    </nav>
    <div class="hero mb-8">
      <v-img src="@/assets/images/recruiter/home_banner.png" max-height="600px">
      </v-img>
      <span class="py-5 hero-btns" v-if="$vuetify.breakpoint.lgAndUp">
        <v-btn
          to="/login"
          class="text-none text-body-1 mx-5 font-weight-medium"
          text
          color="primary"
          large
        >
          Login
        </v-btn>
        <v-btn
          to="/signup"
          class="text-none text-body-1 mx-5 font-weight-medium"
          color="primary"
          elevation="0"
          x-large
        >
          Sign Up
        </v-btn>
      </span>
      <div
        class="py-5 d-flex justify-space-around"
        v-if="$vuetify.breakpoint.mdAndDown"
      >
        <v-btn
          to="/login"
          class="text-none text-body-2 mx-5 font-weight-medium"
          text
          color="primary"
        >
          Login
        </v-btn>
        <v-btn
          to="/signup"
          class="text-none text-body-2 mx-5 font-weight-medium"
          color="primary"
          elevation="0"
        >
          Sign Up
        </v-btn>
      </div>
    </div>
    <div class="reason-container mx-auto">
      <v-card
        elevation="0"
        rounded="0"
        dark
        color="black"
        class="reason-section mx-auto text-center"
      >
        <v-card-title class="justify-center font-weight-bold text-h5 pt-8">
          Why CodeDrills?
        </v-card-title>
        <v-card-text class="pa-0 ma-0">
          <v-row class="px-4 my-16">
            <v-col
              v-for="r in reasons"
              :key="r.title"
              cols="12"
              sm="6"
              lg="3"
              class="px-4"
            >
              <ReasonCard :reason="r"></ReasonCard>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <div class="reviews mx-auto">
      <div class="text-h5 font-weight-bold text-center mb-8">
        What our customers say about us
      </div>
      <Reviews />
    </div>
    <div class="pricing mx-auto">
      <Pricing />
    </div>
    <div class="faqs mx-auto">
      <Faqs />
    </div>
    <!-- <div class="subs mx-auto mb-16">
      <v-card elevation="0" outlined rounded="md" class="py-8">
        <v-card-title class="font-weight-medium text-h6 justify-center">
          Stay updated with CodeDrills
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="subEmail"
                dense
                outlined
                placeholder="Enter email"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
              class="d-flex justify-center align-self-middle"
            >
              <v-btn
                elevation="0"
                class="text-none text-body-1 font-weight-medium"
                color="success"
                @click="subscribeToCd"
              >
                Subscribe
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div> -->
    <footer class="my-5">
      <HomeFooter />
    </footer>
  </main>
</template>
<script>
import ReasonCard from "@/components/campaign/home/ReasonCard.vue";
import Faqs from "@/components/campaign/home/Faqs.vue";
import HomeFooter from "@/components/campaign/home/HomeFooter.vue";
import Pricing from "@/components/campaign/home/Pricing.vue";
import Reviews from "@/components/campaign/home/Reviews.vue";

export default {
  components: { ReasonCard, Faqs, HomeFooter, Pricing, Reviews },
  data() {
    return {
      reasons: [
        { title: "Make assessments in just 2 minutes", image: "/fast.png" },
        {
          title: "Find the best candidate efficiently",
          image: "/best.png",
        },
        { title: "Track your candidates from acquisition to offer", image: "/track.png" },
        { title: "Affordable for every company", image: "/economical.png" },
      ],
      subEmail: "",
    };
  },
  methods: {
    subscribeToCd() {
      console.log({ email: this.subEmail });
    },
  },
};
</script>
<style scoped>
nav {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.hero {
  position: relative;
}
.hero-btns {
  position: absolute;
  left: 48%;
  bottom: 15%;
}
.reason-container,
footer {
  background-color: #122333;
  color: white;
}
.faqs,
.reason-section {
  width: 70%;
}
.subs,
.pricing,
.reviews {
  width: 50%;
}

@media screen and (max-width: 700px) {
  .faqs,
  .reason-section,
  .pricing,
  .reviews,
  .subs {
    width: 90%;
  }
}
</style>