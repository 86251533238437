var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-tabs', {
    attrs: {
      "vertical": _vm.$vuetify.breakpoint.smAndDown,
      "color": "accent"
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("Overview")]), _vm.teamContest ? _c('v-tab', [_vm._v("My Team")]) : _vm._e(), _vm.teamContest ? _c('v-tab', [_vm._v("All Contest Teams")]) : _vm._e()], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('v-row', {
    staticClass: "mt-8"
  }, [_c('v-col', [_c('div', {
    staticClass: "display-1"
  }, [_vm._v("About")])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 11
    }
  }, [_c('v-card', {
    staticClass: "pl-5 py-3",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "d-flex flex-no-wrap"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('html-view', {
    attrs: {
      "html": _vm.view.getAboutHtml()
    }
  })], 1)])])], 1)], 1), _c('v-row', {
    staticClass: "mt-8"
  }, [_c('v-col', [_c('div', {
    staticClass: "display-1"
  }, [_vm._v("Rules")])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 11
    }
  }, [_c('v-card', {
    staticClass: "pl-5 py-3",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "d-flex flex-no-wrap"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('html-view', {
    attrs: {
      "html": _vm.view.getRulesHtml()
    }
  })], 1)])])], 1)], 1)], 1), _c('v-tab-item', [!_vm.user ? _c('v-row', [_c('v-col', [_vm._v(" Please "), _c('router-link', {
    attrs: {
      "to": "/community/login"
    }
  }, [_vm._v(" login ")]), _vm._v(" to create team ")], 1)], 1) : _vm.userContestTeams && _vm.userContestTeams.length == 0 ? _c('v-row', [_c('v-col', [_c('v-alert', {
    attrs: {
      "outlined": "",
      "border": "top",
      "color": "orange",
      "type": "info"
    }
  }, [_vm._v(" You are not a member of any team ")]), _vm.teamContest && _vm.editableContest ? _c('v-row', [_c('v-col', [_c('team-dialog', {
    attrs: {
      "error": _vm.teamCreationFailed,
      "teamContestConfig": _vm.teamContestConfig
    },
    on: {
      "callCreateTeam": _vm.handleCreateTeam
    }
  }), _c('error-message', {
    attrs: {
      "ex": _vm.ex
    }
  })], 1)], 1) : _vm._e()], 1)], 1) : _vm.userContestTeams && !_vm.acceptedTeam ? _c('v-row', [_c('v-col', [_c('v-alert', {
    attrs: {
      "outlined": "",
      "border": "top",
      "color": "orange",
      "type": "info"
    }
  }, [_vm._v(" No accepted invitation. Accept an invitation or create a new team ")]), _vm.teamContest && _vm.editableContest ? _c('v-row', [_c('v-col', [_c('team-dialog', {
    attrs: {
      "error": _vm.teamCreationFailed,
      "teamContestConfig": _vm.teamContestConfig
    },
    on: {
      "callCreateTeam": _vm.handleCreateTeam
    }
  })], 1)], 1) : _vm._e(), _vm._l(_vm.userContestTeams, function (team) {
    return _c('div', {
      key: team.getId()
    }, [_c('team-detail', {
      attrs: {
        "teamView": team
      }
    })], 1);
  })], 2)], 1) : _vm.acceptedTeam ? _c('v-row', [_c('v-col', [_c('team-detail', {
    attrs: {
      "teamView": _vm.acceptedTeam,
      "editableContest": _vm.editableContest,
      "contestUrl": _vm.contentView.getUrl()
    }
  })], 1)], 1) : _vm._e()], 1), _c('v-tab-item', [_c('v-row', [_c('v-col', [_c('teams-list', {
    attrs: {
      "contestId": _vm.contentView.getId(),
      "contestUrl": _vm.contentView.getUrl()
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }