var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "notifications-list"
  }, _vm._l(_vm.notifications, function (notif) {
    return _c('Notification', {
      key: notif.id,
      attrs: {
        "id": notif.id,
        "type": notif.type,
        "text": notif.text,
        "duration": notif.duration
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }