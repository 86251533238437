<template>
  <v-row v-if="!user">
    <v-col class="pt-2 pb-1">
      Please <router-link to="/community/login"> login </router-link> to submit.
    </v-col>
  </v-row>
  <v-row class="px-8 fill-height" v-else align="center" justify="center">
    <SmallConfirmation ref="qtime" />
    <v-col cols="12" lg="11" class="fill-height">
      <div class="pa-16" v-if="testTimeOver">
        <TimeoverModal />
      </div>
      <v-card
        class="current-question px-16"
        height="100%"
        elevation="0"
        v-if="!testTimeOver && currentQuestion"
      >
        <div class="top-completion px-16 pb-8 mb-6">
          <v-row justify="center" align="center">
            <div>{{ currentQuestionNum }} / {{ totalQuestions }}</div>
          </v-row>
          <v-row>
            <v-progress-linear
              :value="finishLine"
              @click="() => {}"
              color="green"
              height="15"
              rounded
            >
            </v-progress-linear>
          </v-row>
        </div>
        <div class="mt-n6 mb-6 navshadow" />
        <SmallConfirmation ref="skip" />
        <SmallConfirmation ref="fs" />
        <PopUpWarning ref="tabsw" />
        <TestQuestion
          :currentQuestion="currentQuestion"
          :nextQs="nextQs"
          :skipQs="skipQs"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { mdiLock, mdiTimer } from "@mdi/js";
import TestQuestion from "../components/assessment/TestQuestion.vue";
import SmallConfirmation from "../components/assessment/SmallConfirmation.vue";
import PopUpWarning from "../components/assessment/PopUpWarning.vue";
import TimeoverModal from "../components/assessment/TimeoverModal.vue";
import { isFullScreen } from "../utils/helper";

export default {
  props: {
    testView: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mdiTimer,
      mdiLock,
      submitLoading: false,
      currentQuestionLoading: true,
      tabSwitchingFalseFlag: false,
      questions: [],
      testTimeOver: false,
    };
  },
  methods: {
    ...mapMutations("candidate", [
      "setGoToTestEnd",
      "setTestEndedLocally",
      "setProblemTab",
      "setCurrentAnswer",
    ]),
    ...mapMutations("judge", ["submissionsClear"]),
    ...mapActions("candidate", [
      "startHiringTest",
      "submitAnswer",
      "skipQuestion",
      "logProctoringEvent",
    ]),
    ...mapActions("timesync", ["initTimeSync"]),
    async skipQs() {
      this.setProblemTab(0);
      if (
        await this.$refs.skip.open(
          "Are you sure you want to skip this question? If skipped, you cannot go back again."
        )
      ) {
        const test_id = this.testView.getTestView()?.getTestPreview()?.getId();
        const qnum = this.currentQuestionNum;
        this.skipQuestion({
          test_id,
          qnum,
        }).then((r) => {
          console.log(r);
        });
        if (this.goToTestEnd) {
          this.setTestEndedLocally(true);
        }
      }
    },
    async nextQs() {
      this.setProblemTab(0);
      if (
        await this.$refs.skip.open(
          "Are you sure you want to submit this question? Once submitted, you cannot go back again."
        )
      ) {
        this.submit();
        console.log(`goToTestEnd: `, this.goToTestEnd);
        if (this.goToTestEnd) {
          this.setTestEndedLocally(true);
        }
      }
    },
    submit() {
      this.submitLoading = true;
      const test_id = this.testView.getTestView()?.getTestPreview()?.getId();
      const qnum = this.currentQuestionNum;
      const qid = this.testView.getTestView()?.getQuestionView()?.getId();
      const qTypeId = this.testView.getTestView()?.getQuestionView()?.getType();
      const qType = this.getContentTypes[qTypeId];
      const answer = this.getCurrentAnswer;
      const candidate_id = this.user?.uid;
      console.log(this.user.uid);
      if (qType !== "PROBLEM") {
        this.submitAnswer({
          test_id,
          qnum,
          qid,
          qType,
          candidate_id,
          qTypeId,
          answer,
        }).then((r) => {
          // console.log(r);
          this.setCurrentAnswer(null);
        });
      } else {
        this.skipQuestion({
          test_id,
          qnum,
        }).then((r) => {
          // clear submission on problem submit
          this.submissionsClear();
        });
      }
    },
    detectFocusOut() {
      const onWindowFocusChange = (e) => {
        if (this.testEndedLocally) {
          return;
        }
        if (!document.hasFocus()) {
          if (this.tabSwitchingFalseFlag) {
            this.tabSwitchingFalseFlag = false;
            return;
          }
          console.log(this.$refs.tabsw.open("Please :sadge:"));
          this.$refs.tabsw.open("You cannot switch tabs during the test");
          console.log("User switched to other tab");
          const test_id = this.testView
            .getTestView()
            ?.getTestPreview()
            ?.getId();
          const qid = this.testView.getTestView()?.getQuestionView()?.getId();
          const candidate_id = this.user?.uid;
          this.logProctoringEvent({
            test_id,
            candidate_id,
            qid,
            proctoring_event: 1,
          }).then((r) => {
            // console.log(r);
          });
        }
      };
      window.addEventListener("focus", onWindowFocusChange);
      window.addEventListener("blur", onWindowFocusChange);
      window.addEventListener("pageshow", onWindowFocusChange);
      window.addEventListener("pagehide", onWindowFocusChange);
    },
    detectFullScreenOut() {
      window.addEventListener("fullscreenchange", () => {
        if (this.testEndedLocally) {
          return;
        }
        if (!isFullScreen()) {
          this.logRejectOrExitFS();
          this.fullScreenReq();
        }
      });
    },
    fullScreenReq() {
      if (this.testEndedLocally) {
        return;
      }
      setTimeout(async () => {
        if (isFullScreen()) {
          return;
        }
        var docelem = document.documentElement;
        if (
          await this.$refs.fs.open("Please turn on fullscreen to take the test")
        ) {
          if (docelem.requestFullscreen) {
            docelem.requestFullscreen();
          } else if (docelem.mozRequestFullScreen) {
            docelem.mozRequestFullScreen();
          } else if (docelem.webkitRequestFullScreen) {
            docelem.webkitRequestFullScreen();
          } else if (docelem.msRequestFullscreen) {
            docelem.msRequestFullscreen();
          }
        } else {
          this.logRejectOrExitFS();
        }
      }, 80);
    },
    enforceFullScreen() {
      window.addEventListener("click", this.fullScreenReq);
    },
    logRejectOrExitFS() {
      console.log("User exited fs / reject fs");
      const test_id = this.testView.getTestView()?.getTestPreview()?.getId();
      const qid = this.testView.getTestView()?.getQuestionView()?.getId();
      const candidate_id = this.user?.uid;
      this.logProctoringEvent({
        test_id,
        candidate_id,
        qid,
        proctoring_event: 3,
      }).then((r) => {
        console.log(r);
      });
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["userId"]),
    ...mapState("timesync", ["serverTime"]),
    ...mapState("candidate", ["goToTestEnd", "testEndedLocally"]),
    ...mapGetters("candidate", ["getContentTypes", "getCurrentAnswer"]),
    questionType() {
      return;
    },
    finishLine() {
      return Math.floor(
        ((this.currentQuestionNum - 1) * 100) / this.totalQuestions
      );
    },
    currentQuestionNum() {
      return this.testView?.getTestView()?.getCurrentQuestionNumber();
    },
    totalQuestions() {
      return this.testView?.getTestView()?.getTotalQuestions();
    },
    currentQuestion() {
      return this.testView.getTestView()?.getQuestionView();
    },
    alreadyStarted() {
      return this.testView.getTestView()?.getTestTimeInfo()?.getStartedAt() > 0;
    },
    testDuration() {
      return this.currentTestView
        ?.getTestView()
        ?.getTestTimeInfo()
        ?.getDuration();
    },
    testStartEpoch() {
      return this.testView?.getTestView()?.getTestTimeInfo()?.getStartedAt();
    },
    testEndEpoch() {
      return this.testStartEpoch + this.testDuration * 1000;
    },
    questionStartEpoch() {
      return this.testView
        ?.getTestView()
        ?.getQuestionTimeInfo()
        ?.getStartedAt();
    },
    questionEndEpoch() {
      // in ms
      return (
        this.questionStartEpoch +
        this.testView?.getTestView()?.getQuestionTimeInfo()?.getDuration() *
          1000
      );
    },
  },
  created() {
    this.initTimeSync();
    if (this.alreadyStarted) {
      if (this.currentQuestionNum === 0) {
        console.log(`set Flag, now next button will go to end screen`);
        this.setGoToTestEnd(true);
        this.setTestEndedLocally(true);
      }
    } else {
      this.startHiringTest({
        test_id: this.testView.getTestView().getTestPreview().getId(),
      }).then((__) => {
        this.startLoading = false;
        // window.location.reload();
      });
    }
    this.detectFocusOut();
    this.enforceFullScreen();
    this.detectFullScreenOut();
    if (this.currentQuestionNum === this.totalQuestions) {
      console.log(`last ques, next end`);
      this.setGoToTestEnd(true);
    }
  },
  components: {
    TestQuestion,
    SmallConfirmation,
    TimeoverModal,
    PopUpWarning,
  },
  watch: {
    currentQuestionNum: function (n, o) {
      console.log(n, o);
      if (this.currentQuestionNum === this.totalQuestions) {
        console.log(`last ques, next end`);
        this.setGoToTestEnd(true);
      }
    },
    serverTime: async function (n, o) {
      const testEnd = this.testEndEpoch / 1000;
      const questionEnd = this.questionEndEpoch / 1000;

      if (this.alreadyStarted) {
        if (this.serverTime > testEnd) {
          this.setTestEndedLocally(true);
          this.submit();
        } else {
          // console.log("Good Luck for test :thumbsup:");
        }
        // this.serverTime > questionEnd
        if (this.serverTime > questionEnd) {
          if (this.currentQuestionNum === this.totalQuestions) {
            console.log(`Last question, time over`);
            this.setGoToTestEnd(true);
            this.submit();
            this.setTestEndedLocally(true);
          }
          if (
            await this.$refs.qtime.open(
              "Time over for this question. Click Yes to continue"
            )
          ) {
            this.submit();
          }
        } else {
          // console.log("Good Luck for question :thumbsup:");
        }
      }
    },
  },
};
</script>
<style scoped>
.navshadow {
  position: absolute;
  width: 120vw;
  left: -10rem;
  box-shadow: 1px 1px 1px 1px #c4c4c4;
}
</style>
