import Vue from "vue";
import Router from "vue-router";
import CompetitiveHome from "./views/CompetitiveHome.vue";
import Login from "./views/Login.vue";
import Logout from "./views/Logout.vue";
import ContestListPage from "./views/ContestListPage.vue";
import Homepage from "./views/recruiter/Homepage";
import InterviewProblems from "./views/InterviewProblems";
import InterviewHome from "./views/InterviewHome.vue";
import Dashboard from "./views/Dashboard.vue";
import Drill from "./views/Drill";
import Content from "./views/Content";
import Scoreboard from "./views/Scoreboard";
import BasicProfile from "./views/BasicProfile";
import SubmissionDetails from "./views/SubmissionDetails";
import Settings from "./views/Settings";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/campus-chapter-program",
      name: "Campus Chapter Program",
      component: Content,
      props: { types: "articles", url: "campus-chapter-program" },
    },
    {
      path: "/amritaicpc",
      name: "ICPC Amritapuri",
      component: Content,
      props: { types: "articles", url: "icpc-amritapuri-2020" },
    },
    {
      path: "/gwalioricpc",
      name: "ICPC Gwalior-Pune",
      component: Content,
      props: { types: "articles", url: "icpc-gwalior-pune-2020" },
    },
    {
      path: "/competitive",
      name: "home",
      component: CompetitiveHome,
      meta: { title: "Competitive programming problems - CodeDrills" },
    },
    {
      path: "/",
      name: "RecruiterHome",
      component: Homepage,
      meta: { title: "CodeDrills - Hire Fast", layout: "RecruiterAuth" },
    },
    {
      path: "/community",
      name: "InterviewHome",
      component: InterviewHome,
      meta: { title: "CodeDrills - Candidates" },
    },
    {
      path: "/problems",
      name: "Interview Problems",
      component: InterviewProblems,
      meta: { title: "Interview Problems - CodeDrills" },
    },
    {
      path: "/community/jobs",
      name: "Job Applications",
      component: () =>
        import(
          /* webpackChunkName: "communityJobs" */ "./views/CommunityJobs.vue"
        ),
      meta: { title: "Jobs - CodeDrills" },
    },
    {
      path: "/community/jobs/:jobUrl",
      name: "Community Jobs",
      component: () => import("./components/jobs/JobView.vue"),
      meta: { title: "Jobs - CodeDrills" },
      props: true,
    },
    {
      path: "/community/dashboard",
      name: "Dashboard",
      component: Dashboard,
      meta: { title: "Candidate Dashboard - CodeDrills" },
    },
    {
      path: "/subscription",
      name: "Settings",
      component: Settings,
      meta: { title: "Settings - CodeDrills" },
    },

    {
      path: "/drills",
      name: "Drills",
      component: Drill,
      meta: { title: "Drills - CodeDrills" },
    },
    {
      path: "/community/login",
      name: "login",
      component: Login,
      meta: { title: "Login - CodeDrills" },
    },
    {
      path: "/logout",
      name: "logout",
      component: Logout,
      meta: { title: "Logout - CodeDrills" },
    },
    {
      path: "/basicProfile",
      name: "basicProfile",
      component: BasicProfile,
      meta: { title: "Basic Profile - CodeDrills" },
    },
    {
      path: "/blogs",
      name: "blogs",
      component: () =>
        import(/* webpackChunkName: "blogs" */ "./views/Blogs.vue"),
      meta: { title: "Blogs - CodeDrills" },
    },
    {
      path: "/careers",
      name: "career",
      component: () =>
        import(/* webpackChunkName: "career" */ "./views/Career.vue"),
      meta: { title: "Career- CodeDrills" },
    },
    {
      path: "/terms-of-service",
      name: "terms-of-service",
      component: () => import(/* webpackChunkName: "tos" */ "./views/TOS.vue"),
      meta: { title: "Terms of Service - CodeDrills" },
    },
    {
      path: "/try",
      name: "try",
      component: () =>
        import(
          /* webpackChunkName: "landing-campaign" */ "./views/campaign/Try.vue"
        ),
      meta: {
        title: "CodeDrills - Hire Great Talent",
        layout: "RecruiterAuth",
      },
    },
    {
      path: "/forgotPassword",
      name: "forgotPassword",
      component: () =>
        import(
          /* webpackChunkName: "forgotPassword" */ "./views/campaign/ForgotPass.vue"
        ),
      meta: { title: "Forgot Password - CodeDrills", layout: "RecruiterAuth" },
    },
    {
      path: "/confirmPassword",
      name: "confirmPassword",
      component: () =>
        import(
          /* webpackChunkName: "confirmPassword" */ "./views/campaign/ConfirmPass.vue"
        ),
      meta: { title: "Confirm Password - CodeDrills", layout: "RecruiterAuth" },
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: () =>
        import(/* webpackChunkName: "info" */ "./views/Privacy.vue"),
      meta: { title: "Privacy Policy - CodeDrills" },
    },
    {
      path: "/signup/:testurl/testInvite",
      name: "TestInvite",
      component: () =>
        import(
          /* webpackChunkName: "customerSignUp" */ "./views/TestLinkInvite.vue"
        ),
      meta: { title: "Test Sign Up - CodeDrills" },
    },
    {
      path: "/signup",
      name: "signUpCustomer",
      component: () =>
        import(
          /* webpackChunkName: "customerSignUp" */ "./views/campaign/SignUp.vue"
        ),
      meta: { title: "Customer Sign Up - CodeDrills", layout: "RecruiterAuth" },
    },
    {
      path: "/signUpCustomer",
      name: "signUpCustomer_old",
      component: () =>
        import(
          /* webpackChunkName: "customerSignUp_old" */ "./views/campaign/SignUp.vue"
        ),
      meta: { title: "Customer Sign Up - CodeDrills", layout: "RecruiterAuth" },
      beforeEnter(to, from, next) {
        next("/signup");
      },
    },
    {
      path: "/login",
      name: "loginCustomer",
      component: () =>
        import(
          /* webpackChunkName: "recruiterSignIn" */ "./views/campaign/LogIn.vue"
        ),
      meta: { title: "Recruiter Login - CodeDrills", layout: "RecruiterAuth" },
    },
    {
      path: "/loginCustomer",
      name: "loginCustomer_old",
      component: () =>
        import(
          /* webpackChunkName: "recruiterSignIn_old" */ "./views/campaign/LogIn.vue"
        ),
      meta: { title: "Recruiter Login - CodeDrills", layout: "RecruiterAuth" },
      beforeEnter(to, from, next) {
        next("/login");
      },
    },
    {
      path: "/community/dashboardSample",
      name: "DashboardSample",
      component: () =>
        import(
          /* webpackChunkName: "sampleDashboard" */ "./views/DashboardSample.vue"
        ),
      meta: { title: "Dashboard Sample - CodeDrills" },
    },
    {
      path: "/dashboard",
      name: "Recruiter Dashboard",
      component: () =>
        import(
          /* webpackChunkName: "recruiterDash" */ "./views/recruiter/Dashboard.vue"
        ),
      props: true,
      meta: { title: "Recruiter Dashboard | CodeDrills", layout: "Recruiter" },
    },
    {
      path: "/assessments",
      name: "Assessments | Recruiter Dashboard",
      component: () =>
        import(
          /*webpackChunkName: "hiringDashboardassessment"*/ "./views/recruiter/Assessment.vue"
        ),
      props: true,
      meta: { title: "Recruiter Dashboard | CodeDrills", layout: "Recruiter" },
    },
    {
      path: "/assessments/create/:jobId?",
      name: "Create Assessment | Recruiter Dashboard",
      component: () =>
        import(
          /*webpackChunkName: "createAssessment"*/ "./views/recruiter/CreateAssessment.vue"
        ),
      props: true,
      meta: { title: "Recruiter Dashboard | CodeDrills", layout: "Recruiter" },
    },
    {
      path: "/assessments/:url",
      name: "Single Assessment",
      component: () =>
        import(
          /*webpackChunkName: "singleAssessment"*/ "./views/recruiter/SingleAssessment.vue"
        ),
      props: true,
      meta: { title: "Assessment | Recruiter", layout: "Recruiter" },
    },
    // {
    //   path: "/dashboard/candidates",
    //   name: "Candidates | Recruiter Dashboard",
    //   component: () => import(/*webpackChunkName: "hiringDashboardcandidates"*/'./views/recruiter/Candidates.vue'),
    //   props: true,
    //   meta: {title: "Candidates | Recruiter Dashboard"},
    // },
    {
      path: "/assessments/:url/:userId",
      name: "Candidate | Recruiter Dashboard",
      component: () =>
        import(
          /*webpackChunkName: "singleCandidate"*/ "./views/recruiter/SingleCandidate.vue"
        ),
      props: true,
      meta: { title: "Candidate | Recruiter Dashboard", layout: "Recruiter" },
    },
    {
      path: "/jobs",
      name: "Jobs | Recruiter Dashboard",
      component: () =>
        import(
          /*webpackChunkName: "jobsDashboard"*/ "./views/recruiter/Jobs.vue"
        ),
      props: true,
      meta: { title: "Job List | CodeDrills", layout: "Recruiter" },
    },
    {
      path: "/jobs/create",
      name: "Create Job | Recruiter Dashboard",
      component: () =>
        import(
          /*webpackChunkName: "jobsCreate"*/ "./views/recruiter/CreateJob.vue"
        ),
      props: true,
      meta: { title: "Job | CodeDrills", layout: "Recruiter" },
    },
    {
      path: "/jobs/:url",
      name: "Job | Recruiter Dashboard",
      component: () =>
        import(
          /*webpackChunkName: "singleJob"*/ "./views/recruiter/SingleJob.vue"
        ),
      props: true,
      meta: { title: "Job | CodeDrills", layout: "Recruiter" },
    },
    {
      path: "/jobs/:url/edit",
      name: "Edit Job | Recruiter Dashboard",
      component: () =>
        import(
          /*webpackChunkName: "singleJob"*/ "./views/recruiter/EditJob.vue"
        ),
      props: true,
      meta: { title: "Job | CodeDrills", layout: "Recruiter" },
    },
    // {
    //   path: "/dashboard/skillpool",
    //   name: "Skill Pool | Recruiter Dashboard",
    //   component: () => import(/*webpackChunkName: "hiringDashboardskillpool"*/'./views/recruiter/SkillPool.vue'),
    //   props: true,
    //   meta: {title: "Skill Pool | Recruiter Dashboard"},
    // },
    {
      path: "/billing",
      name: "Plans and Billing",
      component: () =>
        import(
          /*webpackChunkName: "hiringDashboardbilling"*/ "./views/recruiter/Billing.vue"
        ),
      props: true,
      meta: {
        title: "Plans and Billing | Recruiter Dashboard",
        layout: "Recruiter",
      },
    },
    {
      path: "/help",
      name: "Help | Recruiter Dashboard",
      component: () =>
        import(
          /*webpackChunkName: "hiringDashboardhelp"*/ "./views/recruiter/Help.vue"
        ),
      props: true,
      meta: { title: "Help | Recruiter Dashboard", layout: "Recruiter" },
    },
    {
      path: "/team",
      name: "Teams | Recruiter Dashboard",
      component: () =>
        import(
          /*webpackChunkName: "hiringDashboardteams"*/ "./views/recruiter/Team.vue"
        ),
      props: true,
      meta: { title: "Teams | Recruiter Dashboard", layout: "Recruiter" },
    },
    {
      path: "/settings",
      name: "Settings | Recruiter Dashboard",
      component: () =>
        import(
          /*webpackChunkName: "hiringDashboardsettings"*/ "./views/recruiter/Settings.vue"
        ),
      props: true,
      meta: { title: "Settings | Recruiter Dashboard", layout: "Recruiter" },
    },
    {
      path: "/hiring/tests/:url",
      name: "Hiring Test View",
      component: () =>
        import(/* webpackChunkName: "hiringTest" */ "./views/TestDetails.vue"),
      props: true,
      meta: { title: "Hiring Test" },
    },
    {
      path: "/hiring/tests/:url/reports/:userId",
      name: "UserActivity",
      component: () =>
        import(
          /* webpackChunkName: "userActivity" */ "./views/UserActivity.vue"
        ),
      props: true,
      meta: { title: "Candidate Report" },
    },
    {
      path: "/profile",
      name: "profile",
      component: () =>
        import(/* webpackChunkName: "profile" */ "./views/Profile.vue"),
      props: true,
      meta: { title: "Profile - CodeDrills" },
    },
    {
      path: "/profiles/:userHandle",
      name: "profiles",
      component: () =>
        import(
          /* webpackChunkName: "publicProfile" */ "./views/PublicProfile.vue"
        ),
      props: true,
      meta: { title: "Profiles - CodeDrills" },
    },

    {
      path: "/profile/edit",
      name: "editProfile",
      component: () =>
        import(/* webpackChunkName: "editProfile" */ "./views/EditProfile.vue"),
      props: true,
      meta: { title: "Edit Profile - CodeDrills" },
    },
    {
      path: "/submissions/:id",
      name: "Submission Details",
      component: SubmissionDetails,
      props: true,
      meta: { title: "Submission Details - CodeDrills" },
    },
    {
      path: "/contests/:contestUrl/teams/:teamId",
      name: "TeamView",
      props: true,
      component: () =>
        import(/* webpackChunkName: "teamid" */ "./views/TeamView.vue"),
      meta: { title: "Team - CodeDrills" },
    },
    {
      path: "/:parentTypes/:parentUrl/:types/:url",
      name: "ContentWithParent",
      component: Content,
      props: true,
      meta: { title: "CodeDrills" },
    },
    {
      path: "/tests/:url",
      name: "Test",
      component: () =>
        import(
          /* webpackChunkName: "testView" */ "./components/hiring/TestView"
        ),
      props: true,
      meta: { title: "Test - CodeDrills", layout: "TestLayout" },
    },
    {
      path: "/:types/:url",
      name: "Content",
      component: Content,
      props: true,
      meta: { title: "CodeDrills" },
    },
    {
      path: "/contests",
      name: "Contests",
      component: ContestListPage,
      meta: { title: "Contests - CodeDrills" },
    },
    {
      path: "/contests/:url/scoreboard",
      name: "Scoreboard",
      component: Scoreboard,
      props: true,
      meta: { title: "Scoreboard" },
    },
    {
      path: "*",
      name: "404",
      component: () => import(/* webpackChunkName: "info" */ "./views/404.vue"),
      meta: { title: "Not Found - CodeDrills" },
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
