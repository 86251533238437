var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page', {
    attrs: {
      "minimal": "",
      "title": "Problems",
      "apiStatusList": [_vm.fetchUserContentPreviewsStatus]
    }
  }, [_c('problemList', {
    attrs: {
      "problems": _vm.userContentPreviews,
      "autopick": !!this.$route.query.autopick
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }