var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.teams ? _c('v-row', [_c('v-col', [_c('v-card', {
    staticClass: "mt-5",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-data-table', {
    staticClass: "mt-5 px-5",
    attrs: {
      "server-items-length": _vm.totalItems,
      "no-data-text": "No teams ceated.",
      "headers": _vm.headers,
      "items": _vm.teams,
      "footer-props": _vm.footerOptions,
      "loading": _vm.pageLoading,
      "options": _vm.pagination
    },
    on: {
      "update:footerProps": function updateFooterProps($event) {
        _vm.footerOptions = $event;
      },
      "update:footer-props": function updateFooterProps($event) {
        _vm.footerOptions = $event;
      },
      "update:options": function updateOptions($event) {
        _vm.pagination = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('tr', [_c('td', [_c('router-link', {
          staticClass: "text-decoration-none router_link",
          attrs: {
            "to": "/contests/".concat(_vm.contestUrl, "/teams/").concat(item.getId())
          }
        }, [_vm._v(" " + _vm._s(item.getName()) + " ")])], 1), _c('td', _vm._l(item.getMemberPreviewsList(), function (member, index) {
          return _c('v-chip', {
            key: index,
            staticClass: "ma-1",
            attrs: {
              "outlined": "",
              "color": "grey accent",
              "pill": ""
            }
          }, [member.getHandle() ? _c('router-link', {
            staticClass: "text-decoration-none router_link",
            attrs: {
              "to": "/profiles/".concat(member.getHandle())
            }
          }, [_vm._v(" " + _vm._s(member.getHandle()) + " ")]) : member.getName() ? _c('span', [_vm._v(" " + _vm._s(member.getName()) + " ")]) : _c('span', [_vm._v(" Unknown ")])], 1);
        }), 1)])];
      }
    }], null, false, 92729062)
  })], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }