<template>
  <v-card>
    <v-card-title class="justify-space-between text-h5 font-weight-medium">
      <span>
        {{ submission.problemPreview.title }}
      </span>
      <v-btn icon @click="closeSubModal">
        <v-icon>
          {{ mdiClose }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <pre
        >{{ submission.submission.code }}
        </pre
      >
    </v-card-text>
  </v-card>
</template>
<script>
import { mdiClose } from "@mdi/js";
export default {
  data() {
    return {
      mdiClose,
    };
  },
  props: {
    submission: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeSubModal() {
      this.$emit("closesubmodal");
    },
  },
  created() {
    console.log(`sbm`, this.submission);
  },
};
</script>