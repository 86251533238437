import content from "./content";
import user from "./user";
import judge from "./judge";
import timesync from "./timesync";
import hiring from "./hiring";
import customer from "./customer";
import candidate from "./candidate";
import recruiter from "./recruiter";
import notifs from "./notifs";
import jobs from "./jobs";

export default {
  user,
  content,
  judge,
  timesync,
  hiring,
  customer,
  candidate,
  recruiter,
  notifs,
  jobs,
};
