<template>
  <div class="ma-2 text-subtitle-1 black--text" color="white">
    <v-icon v-if="!wide" large left :color="iconColor">
      {{ iconName }}
    </v-icon>
    <v-icon v-else :color="iconColor" class="pr-2">
      {{ cancelPres }}
    </v-icon>
    <span class="black--text font-weight-bold">
    {{ labelText }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      required: true,
    },
    labelText: {
      type: String,
      required: true,
    },
    iconColor: {
      type: String,
      required: true,
    },
    wide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cancelPres:
        "M21 19.1H3V5H21V19.1ZM21 3H3C1.9 3 1 3.9 1 5V19C1 20.1 1.9 21 3 21H21C22.1 21 23 20.1 23 19V5C23 3.9 22.1 3 21 3ZM14.59 8L12 10.59L9.41 8L8 9.41L10.59 12L8 14.59L9.41 16L12 13.41L14.59 16L16 14.59L13.41 12L16 9.41L14.59 8Z",
    };
  },
};
</script>
