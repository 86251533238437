<script>
import RenderHtml from "./RenderHtml.vue";
import {VSimpleTable} from "vuetify/lib";
import TextBlock from "./TextBlock.vue";
import Solution from "./Solution.vue";

export default {
  components: {
    VSimpleTable,
    TextBlock,
		Solution,
  },
  ...RenderHtml,
};
</script>

<style scoped>
</style>
