var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "py-8 text-h5 font-weight-bold text-center"
  }, [_vm._v("Pricing")]), _c('v-tabs', {
    staticClass: "text-body-1",
    model: {
      value: _vm.pricingTab,
      callback: function callback($$v) {
        _vm.pricingTab = $$v;
      },
      expression: "pricingTab"
    }
  }, [_c('v-tab', [_vm._v(" Free ")]), _c('v-tab', [_vm._v(" Premium ")])], 1), _c('v-tabs-items', {
    staticClass: "pa-5",
    model: {
      value: _vm.pricingTab,
      callback: function callback($$v) {
        _vm.pricingTab = $$v;
      },
      expression: "pricingTab"
    }
  }, [_c('v-tab-item', {
    staticClass: "mb-5"
  }, [_c('v-row', {
    staticClass: "mb-5"
  }, [_c('div', {
    staticClass: "text-h5 font-weight-medium py-3"
  }, [_vm._v(" Test Our Platform with our Free Tier ")])]), _c('v-row', {
    staticClass: "text-body-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('ul', _vm._l(_vm.freeFeatures, function (f) {
    return _c('li', {
      key: f,
      staticClass: "py-2"
    }, [_vm._v(" " + _vm._s(f) + " ")]);
  }), 0)])], 1)], 1), _c('v-tab-item', [_c('v-row', {
    staticClass: "mb-5"
  }, [_c('div', {
    staticClass: "text-h5 font-weight-medium py-3"
  }, [_vm._v(" Start Hiring Today (from "), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(" $99 "), _c('span', {
    staticClass: "text-caption discounted red--text"
  }, [_vm._v(" $199 ")])]), _vm._v(" ) ")])]), _c('v-row', {
    staticClass: "text-h6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-3 mx-1 full__border text-center",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" Monthly ( "), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(" $" + _vm._s(_vm.totalPrice) + " "), _c('span', {
    staticClass: "text-caption discounted red--text"
  }, [_vm._v(" $" + _vm._s(_vm.nonDiscountedPrice) + " ")])]), _vm._v(" ) ")]), _c('v-col', {
    staticClass: "py-3 mx-1 full__border text-center",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" Annually ( "), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(" $" + _vm._s(_vm.annualTotalPrice) + " "), _c('span', {
    staticClass: "text-caption discounted red--text"
  }, [_vm._v(" $" + _vm._s(_vm.annualNonDiscountedPrice) + " ")])]), _vm._v(" ) ")])], 1), _c('v-row', {
    staticClass: "mb-5 text-body-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('ul', _vm._l(_vm.features, function (f) {
    return _c('li', {
      key: f,
      staticClass: "py-2"
    }, [_vm._v(" " + _vm._s(f) + " ")]);
  }), 0)]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('ul', {
    staticStyle: {
      "list-style": "none"
    }
  }, [_c('li', {
    staticClass: "text-body-1 font-weight-medium pb-3"
  }, [_vm._v("Addons")]), _vm._l(_vm.addons, function (addon, idx) {
    return _c('li', {
      key: addon.label,
      staticClass: "pa-0 ma-0 text-body-2"
    }, [_c('v-checkbox', {
      staticClass: "pa-0 ma-0",
      attrs: {
        "value": idx,
        "label": addon.label
      },
      model: {
        value: _vm.selectedAddons,
        callback: function callback($$v) {
          _vm.selectedAddons = $$v;
        },
        expression: "selectedAddons"
      }
    })], 1);
  })], 2)])], 1)], 1), _c('hr')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }