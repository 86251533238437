<template>
  <v-row class="ma-3">
    <v-dialog v-model="dialog">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="accent" dark v-bind="attrs" v-on="on">
          Create Team
        </v-btn>
      </template>
      <v-card>
        <v-form v-model="valid">
        <v-card-title>
          <img v-if="contestBannerUrl" style="max-width: 100%" :src="contestBannerUrl" alt="contest banner">
          <div v-else>New Team</div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  color="accent"
                  label="Team name*"
                  required
									:rules="[rules.required]"
                  v-model="teamName"
                  @input="editTeamName"
                ></v-text-field>
              </v-col>
            </v-row>
            <custom-attributes :customAttributes="teamContestConfig.toObject().teamCustomAttributeMap"
              @change="attributeValue => addTeamValue(attributeValue)"
            ></custom-attributes>
            <!-- Coach Details -->
            <v-row v-if="coachEnabled">
              <v-col>
                <v-text-field
                  color="accent"
                  :label="`Coach email${coachFieldRequired() ? '*' : ''} `"
                  :rules="coachFieldRequired() ? emailRules : []"
                  :required="coachFieldRequired()"
                  @input="e => setCoachEmail(e)">
                </v-text-field>
                <span class="text-caption mb-4 pb-4">
                  A mentor can be anyone who can motivate and support the students(eg: Teacher, Counsellor)
                </span>
              </v-col>
            </v-row>
            <custom-attributes v-if="coachEnabled" :customAttributes="teamContestConfig.toObject().coachSetting.coachCustomAttributeMap"
              @change="attributeValue => addCoachAttributes(attributeValue)"
            ></custom-attributes>
            <span v-for="n in teamContestConfig.getMaxTeamSize()" :key="n">
              <v-row class="">
                <v-col cols="10" :lg="6">
                  <v-text-field color="accent" :label="memberLabel(n)" :rules="emailRules"
                    @input="e => setMemberEmail(n, e)">
                  </v-text-field>
                </v-col>
                <v-col cols="10" :lg="6">
                  <!-- member attribs not to be validated if no email -->
                  <custom-attributes
                    :needsValidation="customPropRevalidate[n]"
                    :customAttributes="teamContestConfig.toObject().memberCustomAttributeMap"
                    @change="attributeValue => addMemberAttributes(n, attributeValue)"
                  ></custom-attributes>
                </v-col>
              </v-row>
            </span>
						<v-row>
							<v-col>
               <v-row>
								<v-col cols="12">
                <v-text-field
                    v-if="!hasSchoolAttribute"
										color="accent"
										label="Organization"
										v-model="organization"
									></v-text-field>
								</v-col>
							</v-row>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions v-if="isTeamNameEdited">
						<v-checkbox v-model="checked" label="I confirm that the team name is not inappropriate. I understand that I may be banned from the platform if it is inappropriate"></v-checkbox>
				</v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn :disabled="!checked" color="grey darken-1" text @click="createTeam">
            Create
          </v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import Loader from '@/components/Loader';
import CustomAttributes from './CustomAttributes.vue';
import user from "codedrills_proto/io/codedrills/proto/user/user_service_grpc_web_pb";
var user_proto = proto.io.codedrills.proto.user;

export default {
  props: {
    error: {
      type: Boolean,
      required: true,
    },
		teamContestConfig: {
			type: Object,
			required: false,
		},
  },
  name: 'TeamDialog',
  data: function() {
    return {
			emailRules: [
				v => (v == "" || /.+@.+/.test(v)) || 'E-mail must be valid',
			],
			rules: {
        required: v => !!v || "Required",
      },
      toggleloader: false,
      valid: true,
      dialog: false,
      email: null,
      teamName: "",
      organization: "",
      teamDetails: new user_proto.TeamDetails(),
      membersList: [],
      customPropRevalidate: [],
      // teamDetails: {
      //   teamName: null,
      //   emailIDs: [],
      //   contentID: null,
			// 	organization: null,
      // },
      isTeamNameEdited:false,
      checked:false,
      contestBannerUrl: null,
    };
  },

  computed: {
    ...mapState('content', ['contentView', 'userContentPreviews']),
    ...mapState('user', ['team']),
    ...mapState('timesync', ['serverTime']),
    upcomingContestSelectItems() {
      return (
        this.userContentPreviews &&
        this.userContentPreviews
          .filter(
            c =>
              c
                .getContentPreview()
                .getMetaData()
                .getContestMeta() &&
              c
								.getContentPreview()
                .getMetaData()
                .getContestMeta()
                .getContestType() == 1 &&
              c
                .getContentPreview()
                .getMetaData()
                .getContestMeta()
                .getStartAt() > this.serverTime &&
              c
                .getContentPreview()
                .getMetaData()
                .getContestMeta()
                .getEndAt() > this.serverTime
          )
          .map(c => c.getContentPreview())
          .map(e => {
            return { text: e.getTitle(), value: e.getId() };
          })
      );
    },
    hasSchoolAttribute() {
      if(!this.teamContestConfig) return;
      //console.log("hasSchoolAttribute", this.teamContestConfig.toObject().teamCustomAttributeMap);
      return this.teamContestConfig &&
        this.teamContestConfig.toObject().teamCustomAttributeMap.findIndex(ca => ca[0] == 'School') >= 0;
    },
  },
  created() {
    this.membersList = [];
    this.customPropRevalidate = [];
    if(this.coachEnabled) {
      this.membersList.push(new user_proto.Member().setMemberRole(2));
      this.customPropRevalidate.push(true);
    }
    for(var i = 0; i < this.teamContestConfig.getMaxTeamSize(); ++i) {
      this.membersList.push(new user_proto.Member().setMemberRole(1));
      this.customPropRevalidate.push(true);
    }
    this.contestBannerUrl = this.contentView.getMetaData().getContestMeta().getBannerUrl() || ""
  },
  components: {
    Loader,
    CustomAttributes,
  },
  methods: {
    createTeam() {
      this.toggleloader = true;
      this.isTeamNameEdited = false;
      this.valid = true;
      this.teamDetails.setName(this.teamName);
      var org = this.hasSchoolAttribute? this.schoolAttributeValue(): this.organization;
      this.teamDetails.setOrganizations(new user_proto.Organizations().addOrganizations(org))
      this.teamDetails.setMembersList(
        this.membersList.filter(m => m.getEmail() && m.getEmail() !== "")
      );
      console.log("td sent", this.teamDetails.toObject());
			console.log("Orgs .. ", this.teamDetails.organization);
      this.$emit('callCreateTeam', this.teamDetails);
    },
    schoolAttributeValue() {
      //console.log("schoolAttributeValue", this.teamDetails.toObject());
      return this.teamDetails.toObject().customAttribute.customAttributeMap.find(ca => ca[0] == "School")[1].stringValue;
    },
    ...mapGetters('content', ['getContentType', 'getFieldPresenceList']),
    ...mapActions('content', ['fetchUserContentPreviews']),
    setInnerMemberEmail(index, email) {
      console.log("Setting inner mail at ..", index, email);
      this.membersList[index].setEmail(email);
      // make sure revalidation is done if email is deleted after entering
      // by checking for empty string
      this.$set(this.customPropRevalidate, index, email === '');
      console.log("member list after setting ..", this.membersList);
    },
    addInnerMemberAttributes(index, attribute) {
      console.log("Member at index ,", index, attribute);
      if(!this.membersList[index].getCustomAttribute()) {
        this.membersList[index].setCustomAttribute(new user_proto.CustomAttributeMap());
      }
      this.membersList[index].getCustomAttribute().getCustomAttributeMap()
        .set(attribute.key, new user_proto.CustomAttributeValue().setStringValue(attribute.value));
      console.log("Member set at index ,", index, this.membersList[index].toObject());
    },
    //TODO:
    removeEmail(ID) {
      this.teamDetails.emailIDs.splice(this.teamDetails.emailIDs.indexOf(ID), 1);
      this.teamDetails.emailIDs = [...this.teamDetails.emailIDs];
    },
    editTeamName()
		{
			this.isTeamNameEdited = true;
		},
    addTeamValue(attribute) {
      console.log("Values are ..", attribute, this.teamDetails.toObject());
      if(!this.teamDetails.getCustomAttribute()) {
        this.teamDetails.setCustomAttribute(new user_proto.CustomAttributeMap());
      }
      console.log("Team Attribute ..", this.teamDetails.toObject());
      this.teamDetails.getCustomAttribute().getCustomAttributeMap()
      .set(attribute.key, new user_proto.CustomAttributeValue().setStringValue(attribute.value));
    },
    memberLabel(n) {
      return "Member " + n + " email*";
    },
    coachEnabled() {
      return this.teamContestConfig.getCoachSetting().getCoachEnabled();
    },
    coachFieldRequired(){
      const presenceIndex = this.teamContestConfig.getCoachSetting().getCoachPresence();
      return this.getFieldPresenceList()[presenceIndex] === 'FIELD_PRESENCE_REQUIRED';
    },
    addCoachAttributes(attributes) {
      console.log("Coach attributes ..", attributes);
      this.addInnerMemberAttributes(0, attributes);
    },
    setCoachEmail(email) {
      console.log("Coach Email outer ..", email)
      this.setInnerMemberEmail(0, email);
    },
    addMemberAttributes(idx, attributes) {
      if(this.coachEnabled) {
        this.addInnerMemberAttributes(idx, attributes);
      } else {
        this.addInnerMemberAttributes(idx-1, attributes);
      }
    },
    setMemberEmail(idx, email) {
      // console.log(`presidx`, this.teamContestConfig.getCoachSetting().toObject(), this.getFieldPresenceList()[2]);
      console.log("Email on idx is ", idx, email);
      if(this.coachEnabled) {
        console.log("Coach Email on idx is ", idx, email);
        this.setInnerMemberEmail(idx, email);
      } else {
        console.log("Member Email on idx is ", idx, email);
        this.setInnerMemberEmail(idx-1, email);
      }
    },
  },
    watch: {
      error: function(n, o) {
        console.log('team created', n);
				this.toggleloader = !n;
      },
      valid(v) {
        console.log("valid", v);
      },
      checked(c) {
        console.log("checked", c);
      }
    },

};
</script>
