<template>
	<v-container grid-list-xs :key="renderCount">
		<v-row v-if="teamView" justify="center">
			<v-col cols="12">
				<v-card class="mx-auto" max-width="444" tile elevation="1">
					<v-card-title> 
						<v-row>
							<v-col>
							{{ teamView.getName() }}
							</v-col>
						</v-row>
						<v-row>
							<v-col>
							{{teamView.getOrganizationsList()[0]}}
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<span v-for="item in teamAttributeValues" :key="item[0]">
									{{item[0]}} : {{item[1].stringValue}} |
								</span>
							</v-col>
						</v-row>
					</v-card-title>
					<v-card-text>
						<v-list dense>
							<v-list-item
								v-for="(m, idx) in teamView.getMembersList()"
								:key="idx"
							>
								<v-list-item-content>

								<v-list-item-title>
									<router-link v-if="m.getHandle()" class="text-decoration-none router_link"
									     :to="`/profiles/${m.getHandle()}`">
									     {{m.getHandle()}}
									</router-link>
									<span v-else-if="isCoach(m)"> {{memberName(m)}} Coach  </span>
									<span v-else> {{memberName(m)}}  </span>
								</v-list-item-title>
								<v-list-item-subtitle v-if="isTeamMember">
									<span v-for="item in memberAttributeValues(m)" :key="item[0] + ':' + idx">
										{{item[0]}} : {{item[1].stringValue}} |
									</span>
								</v-list-item-subtitle>

								</v-list-item-content>

								<v-list-item-action>
									<v-tooltip top>
										<template v-slot:activator="{ on, attrs }">
											<v-icon
												:color="memberShipStatus[m.getMembershipState()].color"
												v-bind="attrs"
												v-on="on"
											>
												{{ memberShipStatus[m.getMembershipState()].icon }}
											</v-icon>
										</template>
										<span>{{ memberShipStatus[m.getMembershipState()].status }}</span>
									</v-tooltip>
								</v-list-item-action>
							</v-list-item>
						</v-list> 
						<v-row> </v-row>
					</v-card-text>
					<confirm-dialog ref="confirm"></confirm-dialog>
					<v-card-actions v-if="user && isFutureContest && editableContest">
						<v-row class="mx-1">
							<v-btn v-if="userStatus && !isTeamCreator && (userStatus === 1)"
								plain
								text
								color="green"
								@click="handleTeamInvite('MEMBERSHIP_ACCEPTED')"
							>
								{{memberShipStatus[userStatus].message}}
							</v-btn>

							<v-btn v-if="userStatus && !isTeamCreator && (userStatus === 1 || userStatus === 2)"
								plain
								text
								color="red"
								@click="handleTeamInvite('MEMBERSHIP_DECLINED')"
							>
								Decline Invite
							</v-btn>

							<v-btn v-if="isTeamCreator" @click="handleDeleteTeam" plain text color="danger">Delete</v-btn>
							<error-message :ex="ex"></error-message>
						</v-row>
					</v-card-actions>
					<br>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions, mapState, mapGetters} from 'vuex';
import ConfirmDialog from '../components/ConfirmDialog.vue';
import ErrorMessage from "../components/ErrorMessage";
import { mdiAccountAlertOutline, mdiAccountPlusOutline, mdiAccountCheckOutline, mdiAccountCancelOutline } from '@mdi/js'

export default {
	props: {
		teamView: {
			type: Object,
			required: true,
		},
		editableContest: {
			type: Boolean,
			required: false,
			default: true,
		},
		contestUrl: {
			type: String,
			required: false,
			default: ""
		}
	},
  name: 'teamView',
	components: {
    ConfirmDialog,
		ErrorMessage,
	},
  data: () => ({
		ex: null,
		teamAccepStatus: null,
		postDeleteRedir: null,
    memberShipStatus: {
      0: {
        icon: mdiAccountAlertOutline,
        status: 'UNKNOWN',
        color: 'warning',
      },
      1: {
        icon: mdiAccountPlusOutline,
        status: 'Invited',
        color: 'accent',
				message: 'Accept Invite',
      },
      2: {
        icon: mdiAccountCheckOutline,
        status: 'Accepted',
        color: 'success',
				message: 'Decline Invite',
      },
      3: {
        icon: mdiAccountCancelOutline,
        status: 'Declined',
        color: 'danger',
				message: 'Accept Invite',
      },
    },
    loading: true,
		renderCount: 0,
  }),
  methods: {
    ...mapActions('user', ['deleteTeam', 'updateTeamInvite']),
    handleDeleteTeam() {
			if (this.contestUrl !== ""){
				this.postDeleteRedir = `/contests/${this.contestUrl}`;
			}
			else {
				this.postDeleteRedir = `/contests`;
			}
			console.log('deleting', this.postDeleteRedir, this.$route.params.teamId, !!this.$route.params.teamId);
			this.$refs.confirm.open('Delete', "Are you sure you want to delete this team?",{color: 'red'}).then(confirm => {
				if(confirm) this.deleteTeam({ 'teamId': this.teamView.getId() })
				.then(__ => {
					// was breaking from team page
					// checks if in nested team page, then pushes
					// otherwise .go() to curr page refreshes
					if(!!this.$route.params.teamId){
						this.$router.push(this.postDeleteRedir);
					}
					else {
						this.$router.go(this.postDeleteRedir);
					}
				}).catch(ex => this.ex = ex);
			});
    },
    handleTeamInvite(state) {
			console.log("teamId ..", this.teamView.getId());
      this.updateTeamInvite({
        teamId: this.teamView.getId(),
        membershipState: this.teamMembership[state],
      }).then(__ => this.forceUpdate()).catch(ex => this.ex = ex);
    },
		forceUpdate() {
			console.log("Force udpate");
			this.$forceUpdate();
			this.renderCount += 1;
		},
		memberName(m) {
				if(this.isTeamMember) return m.getName() ? m.getName() : m.getEmail();
				else return m.getName() ? m.getName() : "UnNamed";
		},
		isCoach(m) {
			console.log("m...", m.toObject());
			return this.memberRoleKeys[m.getMemberRole()] === 'MEMBER_ROLE_COACH';
		},
		memberAttributeValues(m) {
			return m.getCustomAttribute().toObject().customAttributeMap;
		},
  },
  computed: {
    ...mapState('user', ['getTeamStatus', 'deleteTeamStatus']),
    ...mapState('timesync', ['serverTime']),
    ...mapGetters('user', ['user', 'teamMembership', 'teamMembershipKeys', 'memberRoleKeys']),
    isFutureContest() {
      var fc = (
        this.teamView
          .getContentPreview()
          .getMetaData()
          .getContestMeta()
          .getStartAt() > this.serverTime
      );
			// console.log("Future contest", fc);
			return fc;
    },
		isTeamMember() {
			if(this.teamView && this.user) {
				return this.teamView
                 	.getMembersList()
                  .find(u => u.getEmail() === this.user.email);
			}
		},
		isTeamCreator() {
			return this.teamView && this.user && this.teamView.getCreatorId() === this.user.uid;
		},
		userStatus() {
			if(this.teamView && this.user)
				var member = this.teamView.getMembersList()
										.find(u => u.getEmail() === this.user.email);
				if(member) return member.getMembershipState();
		},
		teamAttributeValues() {
			return this.teamView.getCustomAttribute().toObject().customAttributeMap;
		},
 },
};
</script>

<style scoped>
.router_link{
	color:inherit;
}
</style>

