var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-card', {
    staticClass: "pl-5 pr-2 py-5"
  }, [_c('html-view', {
    attrs: {
      "html": _vm.view.getStatementView()
    }
  }), _c('v-divider', {
    staticClass: "mt-5"
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 6,
      "md": 3,
      "lg": 2
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Time Limit"
    }
  }, [_vm._v(" " + _vm._s(_vm.metadata.getConstraints().getTimeLimitInMs()) + " ms ")])], 1), _c('v-col', {
    attrs: {
      "cols": 6,
      "md": 3,
      "lg": 2
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Memory Limit"
    }
  }, [_vm._v(" " + _vm._s(_vm.metadata.getConstraints().getMemoryLimitInKb()) + " KiB ")])], 1), _c('v-spacer')], 1)], 1), _c('codingArea', {
    attrs: {
      "parentPreview": _vm.parentPreview,
      "contentView": _vm.contentView
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }