var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list-item', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "subtitle-2"
  }, [_vm._v(_vm._s(_vm.title))]), _c('v-list-item-subtitle', {
    staticClass: "body-1"
  }, [_vm._t("default")], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }