var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "elevation": "0",
      "rounded": "0"
    }
  }, [_c('v-card-title', {
    staticClass: "justify-center"
  }, [_c('div', {
    staticClass: "text-h5 font-weight-bold"
  }, [_vm._v("FAQs")])]), _c('v-row', {
    staticClass: "px-8 py-8",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-expansion-panels', _vm._l(_vm.faqs, function (item, i) {
    return _c('v-expansion-panel', {
      key: i,
      staticClass: "my-2"
    }, [_c('v-expansion-panel-header', {
      staticClass: "font-weight-medium text-body-1"
    }, [_vm._v(" " + _vm._s(item.question) + " ")]), _c('v-expansion-panel-content', {
      staticClass: "text-subtitle-1"
    }, [_vm._v(" " + _vm._s(item.answer) + " ")])], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }