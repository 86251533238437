var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "d-flex flex-sm-wrap"
  }, _vm._l(_vm.attributes, function (a) {
    return _c('v-col', {
      key: a.name,
      attrs: {
        "cols": "12",
        "md": 12 / _vm.attributes.length
      }
    }, [_vm.isAutoComplete(a) && a.customValueAllowed ? _c('v-combobox', {
      attrs: {
        "dense": "",
        "items": _vm.autoCompleteItems(a),
        "label": _vm.addStarIfRequired(a),
        "rules": _vm.rules(a)
      },
      on: {
        "input": function input(v) {
          return _vm.setValue(a.name, v);
        }
      }
    }) : _vm.isAutoComplete(a) && !a.customValueAllowed ? _c('v-autocomplete', {
      attrs: {
        "dense": "",
        "items": _vm.autoCompleteItems(a),
        "label": _vm.addStarIfRequired(a),
        "rules": _vm.rules(a)
      },
      on: {
        "input": function input(v) {
          return _vm.setValue(a.name, v);
        }
      }
    }) : _c('v-text-field', {
      attrs: {
        "dense": "",
        "label": _vm.addStarIfRequired(a),
        "rules": _vm.rules(a)
      },
      on: {
        "input": function input(v) {
          return _vm.setValue(a.name, v);
        }
      }
    })], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }