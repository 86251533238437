var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-row', [_c('v-col', [_vm._v(" Continue where you left off, track your progress on all the active drills you are practicing ")])], 1), _vm.activeDrills ? _c('v-row', _vm._l(_vm.dashboardView.getInProgressDrillPreviewsList(), function (drill) {
    return _c('v-col', {
      key: drill.getContentPreview().getId(),
      attrs: {
        "xs": 12,
        "sm": 6,
        "md": 3
      }
    }, [_c('v-card', {
      attrs: {
        "id": "prev",
        "outlined": "",
        "width": "270",
        "height": "100",
        "to": '/drills/' + drill.getContentPreview().getUrl()
      }
    }, [_c('div', {
      staticClass: "d-flex flex-no-wrap"
    }, [_c('v-avatar', {
      staticClass: "mt-3 ml-3",
      attrs: {
        "size": "imageWidth",
        "tile": ""
      }
    }, [_c('v-img', {
      attrs: {
        "src": drill.getContentPreview().getMetaData().getImageUrl()
      }
    })], 1), _c('div', [_c('v-card-title', {
      staticClass: "text-caption-1"
    }, [_c('v-tooltip', {
      attrs: {
        "top": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('span', _vm._g({
            staticClass: "d-inline-block text-truncate  ",
            style: {
              width: _vm.titleWidth + 'px'
            }
          }, on), [_vm._v(" " + _vm._s(drill.getContentPreview().getTitle()) + " ")])];
        }
      }], null, true)
    }, [_vm._v(" " + _vm._s(drill.getContentPreview().getTitle()) + " ")])], 1), _c('v-card-subtitle', [_vm._v(" " + _vm._s(drill.getUserActivity() && drill.getUserActivity().getData().getDrillReport().getCompletedContentCount()) + "/" + _vm._s(_vm.totalContent(drill)) + " Completed "), _c('br')])], 1)], 1), _c('v-progress-linear', {
      staticClass: "mt-5",
      attrs: {
        "bottom": "",
        "absolute": "",
        "color": "accent",
        "value": _vm.progress(drill),
        "height": "10"
      }
    })], 1)], 1);
  }), 1) : _c('v-row', [_c('v-col', [_c('status-card', {
    attrs: {
      "startNow": "/drills",
      "height": 100,
      "width": 119,
      "title": "Drills",
      "cardText": "Start a drill to track progress here",
      "imageName": "Practice"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }