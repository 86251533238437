<template>
	<v-card max-width="570" height="195" outlined class="pl-2">
		<v-card-title>
			{{title}}
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col :cols="8">
					{{cardText}} <br/><br/>
					<v-btn class="text-capitalize" color="accent" size="35" @click="$router.push(startNow)">
						Start Now
					</v-btn>
				</v-col>
				<v-spacer></v-spacer>
				<v-col :cols="4" :md="3" :sm="3" >
					<v-img height="height" width="width" :src="require('./../assets/images/' + imageName + '.svg')"/>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>
<script>
export default {
	props: {
		title: {
			type: String,
			required: true,
		},
		startNow: {
			type: String,
			required: false,
		},
		cardText: {
			type: String,
			required: true,
		},
		imageName: {
			type: String,
			required: true,
		},
		height: {
			type: Number,
			required: true,
		},
		width: {
			type: Number,
			required: true,
		},
	}
}
</script>