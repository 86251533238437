<template>
  <v-row>
    <v-col>
      <v-card class="ml-5 mr-6">
				<v-row class="pl-0 align-center" no-gutters>
					<v-col :cols="8" :md="2" class="py-0">
						<v-select
							:items="languages"
							outlined
							class="ml-0"
							hide-details
							v-model="language"
						>
						</v-select>
					</v-col>
					<v-spacer></v-spacer>
					<v-col :cols="1">
						<v-btn v-if="!copied" icon @click="copyText">
							Copy
						</v-btn>
					</v-col>
				</v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col class="py-0">
            <codemirror
              ref="myCm"
              :value="value"
              @input="onCodeChange"
              :options="cmOptions"
              :class="fontSizeDisplay"
            ></codemirror>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { codemirror as Codemirror } from "vue-codemirror";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/python/python.js";
import "codemirror/mode/clike/clike.js";
import "codemirror/mode/sql/sql.js";
import "codemirror/theme/dracula.css";
import "codemirror/theme/solarized.css";
import "codemirror/theme/idea.css";
import "codemirror/keymap/emacs.js";
import "codemirror/keymap/vim.js";
import "codemirror/keymap/sublime.js";
import "codemirror/lib/codemirror";

export default {
  data() {
    return {
      showHint: false,
      tabSize: 4,
      theme: "default",
      keyMap: "default",
      fontSize: "small",
			readOnly: "nocursor",
      allLanguages: [
        { text: "C++", value: "CPP_17" },
        { text: "Java", value: "JAVA_11" },
        { text: "Kotlin", value: "KOTLIN_1_3" },
        { text: "Python", value: "PYTHON_3" },
        { text: "MySQL 8", value: "MY_SQL_8" },
        { text: "JavaScript", value: "JAVASCRIPT" },

      ],
			cmModeMap: {
        "CPP_17": "text/x-c++src",
        "JAVA_11": "text/x-java",
        "KOTLIN_1_3": "text/x-kotlin",
        "PYTHON_3": "text/x-python",
        "javascript": "text/javascript",
        "MY_SQL_8": "text/x-sql",
      },
			copied: false,
    };
  },
  props: {
    value: {
      type: String,
      required: false
    },
		availableLanguages: {
			type: Array,
			required: false,
		},
    language: {
      type: String,
      required: false,
      default: "CPP_17",
    }
  },
  components: {
    Codemirror
  },
  computed: {
    ...mapGetters("user", ["languageKeys"]),
		fontSizeDisplay() {
			return this.fontSize + " display";
		},
    codemirror() {
      return this.$refs.myCm.codemirror;
    },
		languages() {
			console.log("lang", this.availableLanguages);
			return this.allLanguages.filter(lang => this.availableLanguages.indexOf(lang.value) != -1);
		},
    cmOptions() {
      return {
        tabSize: this.tabSize,
        /*indentUnit: 4,*/
        indentWithTabs: true,
        mode: this.cmModeMap[this.language],
        lineNumbers: true,
        line: true,
        styleActiveLine: true,
        matchBrackets: true,
        lineWrapping: true,
        theme: this.theme,
        foldGutter: true,
        keyMap: this.keyMap,
				readOnly: this.readOnly,
      };
    },
  },
  watch: {
    defaultCode: function(newDefaultCode) {
      this.value = newDefaultCode;
    },
    language: function(newLanguage) {
      this.$emit("setLanguage", newLanguage);
    },
  },
  methods: {
    onCodeChange(newValue) {
      this.$emit('input', newValue);
    },
		copyText () {
			navigator.clipboard.writeText(this.value);
    },
  },
  mounted() {
    this.$emit("setLanguage", this.language);
	}
};
</script>
<style scoped>
   @import '../assets/css/codemirror.css';
</style>
