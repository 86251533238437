<template>
  <div class="fill-height">
    <test-page :apiStatusList="[getHiringTestStatus]" :errMsg="errMsg">
      <template v-slot:top>
        <v-row
          class="align-center text-center"
          v-if="started & !testEndedLocally"
        >
          <v-col cols="3">
            <router-link to="/">
              <img
                alt="[C]"
                height="35"
                width="50"
                :src="require('../../assets/images/CodeDrillsLight.svg')"
              />
            </router-link>
          </v-col>
          <v-col cols="3">
            <v-icon>
              {{ mdiTimer }}
            </v-icon>
            {{ timeTillQuestionEnd }}/
            {{ questionDuration }}
          </v-col>

          <v-col cols="3">
            <b>Total Time:</b> {{ timeTillTestEnd }} / {{ testDuration }}
          </v-col>
          <v-col cols="3">
            <v-btn
              class="white--text text-none font-weight-medium text-body-1"
              color="danger"
              @click="endTestNavigation"
              elevation="0"
            >
              End Test
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:content>
        <v-row style="height: 100%">
          <v-col v-if="!loading" class="fill-height">
            <test-start-page
              v-if="!started"
              :testView="currentTestView"
            ></test-start-page>
            <test-end-page
              v-if="testEndedLocally"
              :testView="currentTestView"
            ></test-end-page>
            <test
              v-if="started && !testEndedLocally"
              :testView="currentTestView"
            >
            </test>
          </v-col>
        </v-row>
      </template>
    </test-page>
    <BigConfirmation
      imageUrl="images/ConfirmTestEnd.png"
      ref="end"
    ></BigConfirmation>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import TestStartPage from "../../views/TestStartPage";
import TestEndPage from "../../views/TestEndPage";
import Test from "../../views/Test";
import TestPage from "../assessment/TestPage.vue";
import SmallConfirmation from "../assessment/SmallConfirmation.vue";
import BigConfirmation from "../assessment/BigConfirmation.vue";
import { mdiTimer } from "@mdi/js";
import moment from "moment";

export default {
  props: {},
  data() {
    return {
      loading: true,
      mdiTimer,
      errMsg: "",
    };
  },
  methods: {
    ...mapActions("candidate", ["getHiringTest"]),
    ...mapActions("timesync", ["initTimeSync"]),
    ...mapMutations("candidate", ["setTestEndedLocally"]),
    ...mapActions("user", ["getUserContestTeams", "fetchUserActivity"]),
    async endTestNavigation() {
      console.log(`this.$refs:`, this.$refs);
      if (
        await this.$refs.end.open("Are you sure you want to STOP the test?", {
          color: "primary",
        })
      ) {
        this.setTestEndedLocally(true);
      }
    },
  },
  computed: {
    ...mapState("timesync", ["serverTime"]),
    ...mapState("user", ["userActivity"]),
    ...mapState("candidate", [
      "currentTestView",
      "testEndedLocally",
      "getHiringTestStatus",
    ]),
    testDuration() {
      return moment
        .utc(
          this.currentTestView
            ?.getTestView()
            ?.getTestTimeInfo()
            ?.getDuration() * 1000
        )
        .format("HH:mm:ss");
    },
    started() {
      return (
        this.currentTestView?.getTestView()?.getTestTimeInfo()?.getStartedAt() >
        0
      );
    },
    timeTillQuestionEnd() {
      let timerem = this.questionEndEpoch - this.serverTime * 1000;
      timerem = timerem > 0 ? timerem : 0;
      return moment.utc(timerem).format("HH:mm:ss");
    },
    testStartEpoch() {
      return this.currentTestView
        ?.getTestView()
        ?.getTestTimeInfo()
        ?.getStartedAt();
    },
    testDurationEpoch() {
      return (
        this.currentTestView?.getTestView()?.getTestTimeInfo()?.getDuration() *
        1000
      );
    },
    timeTillTestEnd() {
      let timerem =
        this.testStartEpoch + this.testDurationEpoch - this.serverTime * 1000;
      timerem = timerem > 0 ? timerem : 0;
      return moment.utc(timerem).format("HH:mm:ss");
    },
    questionStartEpoch() {
      return this.currentTestView
        ?.getTestView()
        ?.getQuestionTimeInfo()
        ?.getStartedAt();
    },
    questionEndEpoch() {
      // in ms
      return (
        this.questionStartEpoch +
        this.currentTestView
          ?.getTestView()
          ?.getQuestionTimeInfo()
          ?.getDuration() *
          1000
      );
    },
    questionDuration() {
      return moment
        .utc(
          this.currentTestView
            ?.getTestView()
            ?.getQuestionTimeInfo()
            ?.getDuration() * 1000
        )
        .format("HH:mm:ss");
    },
  },
  components: {
    TestStartPage,
    TestEndPage,
    Test,
    TestPage,
    SmallConfirmation,
    BigConfirmation,
  },
  created() {
    this.initTimeSync();
  },
  watch: {},
  mounted() {
    this.getHiringTest({
      url: this.$route.params.url,
    })
      .then((_) => {
        console.log("Fetched hiringTest:", this.currentTestView);
        this.loading = false;
      })
      .catch((err) => {
        this.errMsg = err?.message || "Error loading test, please try again!";
      });
  },
};
</script>
