var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "py-5"
  }, [_vm._t("top")], 2), _vm.loadingError ? _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('div', {
    staticClass: "text-h5 text-center text-bold danger--text py-16"
  }, [_c('Navbar'), _vm._v(" " + _vm._s(_vm.errMsg) + " ")], 1)]) : _vm.loading ? _c('v-row', [_vm._v(" Page Loading ... ")]) : _vm._t("content")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }