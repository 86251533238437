var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-snackbar', {
    attrs: {
      "value": _vm.visible,
      "color": _vm.type,
      "elevation": "0"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-icon', {
    staticClass: "mr-5",
    attrs: {
      "large": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.notifIcon) + " ")]), _c('div', {
    staticClass: "text-h6 font-weight-medium text-center"
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }