import store from "../store";

function authChange(user) {
    console.log("---Auth change", user);
    store.commit("user/initialized");
    if(user) {
        store.dispatch("user/userLoggedIn", {user: user});
        store.dispatch("user/register");
        console.log("Dispatching to checkSSO")
        store.dispatch("user/checkSSO");
    } else {
        store.dispatch("user/userLoggedOut");
    }
}

function signInSuccess(authResult) {
    console.log("New user?", authResult.additionalUserInfo.isNewUser, authResult.user.emailVerified);
    if(authResult.additionalUserInfo.isNewUser && !authResult.user.emailVerified) {
        console.log("New signin");
        authResult.user.sendEmailVerification().catch(err => console.log("Error while sending email verification", err));
    }

    return true;
}

export default {
    authChange,
    signInSuccess
};
