var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-row', [_c('v-col', [_vm.upcoming ? _c('upcoming-contest', {
    attrs: {
      "contentView": _vm.contentView
    }
  }) : _vm.past ? _c('past-contest', {
    attrs: {
      "contentView": _vm.contentView
    }
  }) : _c('live-contest', {
    attrs: {
      "contentView": _vm.contentView
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }