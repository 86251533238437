var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-n8"
  }, [_c('Navbar'), _c('v-row', {
    staticClass: "py-16",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10",
      "lg": "8"
    }
  }, [_c('v-card', {
    staticClass: "px-4 px-lg-16 py-16"
  }, [_c('v-card-title', {
    staticClass: "text-h5 font-weight-medium black--text"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('v-card-subtitle', {
    staticClass: "text-h6 px-6 font-weight-medium black--text"
  }, [_c('v-row', [_c('v-col', [_vm._v(" Read the instructions carefully before you start the assessment! ")])], 1)], 1), _c('v-card-text', {
    staticClass: "px-8"
  }, [_c('v-row', {
    staticClass: "text-body-2"
  }, [_c('v-col', {
    staticClass: "black--text"
  }, [_c('InstructionLabel', {
    attrs: {
      "iconName": _vm.mdiTimer,
      "labelText": "Duration: ".concat(_vm.testDuration),
      "iconColor": _vm.brandColor
    }
  })], 1), _c('InstructionLabel', {
    attrs: {
      "iconName": _vm.mdiCloseBoxOutline,
      "labelText": "Screen Unchangeable",
      "iconColor": "success",
      "wide": true
    }
  })], 1), _c('v-row', {
    staticClass: "px-5"
  }, [_c('ul', {
    staticClass: "text-subtitle-1 black--text px-1"
  }, _vm._l(_vm.rules, function (rule, i) {
    return _c('li', {
      key: "rule=".concat(i)
    }, [_vm._v(" " + _vm._s(rule) + " ")]);
  }), 0), _c('ul', {
    staticClass: "text-subtitle-1 black--text px-1"
  }, [_c('li', [_vm._v(" We recommend you to solve this "), _c('a', {
    attrs: {
      "href": "https://codedrills.io/problems/fizz-buzz"
    }
  }, [_vm._v("problem")]), _vm._v(" before starting the assessment to familiarize yourself with the platform. ")])])]), _c('v-row', [_c('v-col', [_c('v-checkbox', {
    attrs: {
      "error-messages": _vm.TOSError,
      "rules": [function (v) {
        return !!v || 'You must agree to continue!';
      }]
    },
    on: {
      "change": _vm.validateTOS
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" I have read all the rules clearly and will follow them during the assessment. I will not use any means of cheating. If found cheating during the assessment, I will be banned from the CodeDrills platform. ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.agreeToTOS,
      callback: function callback($$v) {
        _vm.agreeToTOS = $$v;
      },
      expression: "agreeToTOS"
    }
  })], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-center mt-8"
  }, [_c('v-btn', {
    staticClass: "white--text text-h6 pa-5 text-none",
    attrs: {
      "disabled": !_vm.agreeToTOS,
      "elevation": "0",
      "color": _vm.brandColor,
      "loading": _vm.startLoading
    },
    on: {
      "click": _vm.start
    }
  }, [_vm._v(" Start Assessment ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }