var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Page', {
    attrs: {
      "minimal": "",
      "title": "Drills",
      "apiStatusList": [_vm.fetchUserContentPreviewsStatus]
    }
  }, [_c('v-row', {
    staticClass: "mt-8"
  }, [_c('v-col', [_c('span', {
    staticClass: "font-weight-bold text-truncate text-h5"
  }, [_vm._v(" Current Drills ")])])], 1), _vm.isUserLoggedIn ? _c('v-row', [!_vm.activeDrills || _vm.activeDrills.length == 0 ? _c('v-col', [_vm._v(" Solve a problem in a drill to start tracking. ")]) : _vm._e(), _vm._l(_vm.activeDrills, function (drill) {
    return _c('v-col', {
      key: drill.getContentPreview().getId(),
      attrs: {
        "xs": 12,
        "sm": 6,
        "md": 3
      }
    }, [_c('drill-card', {
      attrs: {
        "drill": drill
      }
    })], 1);
  })], 2) : _c('v-row', [_c('v-col', [_vm._v(" Please "), _c('router-link', {
    staticClass: "ml-1 font-weight-bold dashboard_link",
    attrs: {
      "to": "/community/login"
    }
  }, [_vm._v(" login ")]), _vm._v(" to see current Drills ")], 1)], 1), _c('v-row', {
    staticClass: "mt-8"
  }, [_c('v-col', [_c('span', {
    staticClass: "font-weight-bold text-truncate text-h5"
  }, [_vm._v(" Company Drills ")])])], 1), _c('v-row', _vm._l(_vm.companyDrills, function (drill) {
    return _c('v-col', {
      key: drill.getContentPreview().getId(),
      attrs: {
        "xs": 12,
        "sm": 6,
        "md": 3
      }
    }, [_c('drill-card', {
      attrs: {
        "drill": drill
      }
    })], 1);
  }), 1), _c('v-row', {
    staticClass: "mt-8"
  }, [_c('v-col', [_c('span', {
    staticClass: "font-weight-bold text-truncate text-h5"
  }, [_vm._v(" Topic Drills ")])])], 1), _c('v-row', _vm._l(_vm.topicDrills, function (drill) {
    return _c('v-col', {
      key: drill.getContentPreview().getId(),
      attrs: {
        "xs": 12,
        "sm": 6,
        "md": 3
      }
    }, [_c('drill-card', {
      attrs: {
        "drill": drill
      }
    })], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }