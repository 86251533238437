<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card>
          <v-card-title class="body-1 accent white--text">Basic profile info</v-card-title>
          <v-card-text class="pt-2">
            <v-row>
              <v-col>
                <v-text-field
                  label="Name"
                  placeholder="Name or handle"
                  outlined
                  :rules="[rules.required]"
                  v-model="displayName"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row  v-bind:class="{'text-center':$vuetify.breakpoint.mobile, 'text-end':!$vuetify.breakpoint.mobile}" class="d-flex justify-end">
              <v-spacer></v-spacer>
              <v-col :cols="12">
                <v-btn :disabled="!displayName" color="accent" height="35"  :width="$vuetify.breakpoint.mdAndUp? 75: 90" @click="saveProfile">Save</v-btn>
              </v-col>
            </v-row>
            
          </v-card-text>
        </v-card>
     </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
import * as firebaseui from "firebaseui";
import auth from "../utils/auth"

export default {
  data: function() {
    return {
      rules: {
        required: v => !!v || "Required",
      },
      displayName: null,
    }
  },
  computed: {
    ...mapState("user", ["loginError", "user", "authInstance"]),
  },
  methods: {
    ...mapActions("user", ["userLoggedIn", "register"]),
    saveProfile() {
      firebase.auth().currentUser.updateProfile({
        displayName: this.displayName,
      }).then(() => {
        this.userLoggedIn({
          user: firebase.auth().currentUser,
        });
        this.register();
        this.$router.push("/")
      });
    },
  },
  mounted() {
    this.displayName = this.user.displayName;
  }
};
</script>
<style scoped>
</style>