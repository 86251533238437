<template>
  <v-row>
    <v-col class="px-0">
      <v-card outlined>
        <v-sheet class="d-flex py-2">
          <v-row class="pl-1 align-center" no-gutters>
            <v-col :cols="8" :md="2" class="py-0">
              <v-select
                :items="curLanguages"
                outlined
                class="ml-0 mb-2"
                hide-details
                v-model="language"
                @change="saveEditorConfig"
                label="Language"
              >
              </v-select>
            </v-col>
            <v-spacer class="hidden-sm-and-down"></v-spacer>
            <v-col :cols="12" :md="7" :lg="6" class="py-0 pr-1" v-if="showSettings">
              <v-row no-gutters>
                <v-col :cols="6" :md="3" class="py-0">
                  <v-select
                    :items="themes"
                    outlined
                    class="ml-0 font-weight-light"
                    hide-details
                    v-model="theme"
                    @change="saveEditorConfig"
                    label="Theme"
                  >
                  </v-select>
                </v-col>
                <v-col :cols="6" :md="3" class="py-0 pl-1">
                  <v-select
                    :items="keyMaps"
                    outlined
                    class="ml-0 font-weight-light"
                    hide-details
                    v-model="keyMap"
                    @change="saveEditorConfig"
                    label="Key map"
                  >
                  </v-select>
                </v-col>
                <v-col :cols="3" class="py-0 pl-1 hidden-sm-and-down">
                  <v-select
                    :items="fontSizes"
                    outlined
                    class="ml-0 font-weight-light"
                    hide-details
                    v-model="fontSize"
                    @change="saveEditorConfig"
                    label="Font size"
                  >
                  </v-select>
                </v-col>
                <v-col :cols="3" class="py-0 pl-1 hidden-sm-and-down">
                  <v-select
                    :items="tabSizes"
                    outlined
                    class="ml-0 font-weight-light"
                    hide-details
                    v-model="tabSize"
                    @change="saveEditorConfig"
                    label="Tab size"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
						<v-col :cols="2" align="right">
							<v-tooltip left>
								<template v-slot:activator="{on, attrs}">
									<v-btn icon color="accent" large v-bind="attrs" v-on="on">
										<v-icon class="text-right" @click="removeCode">{{mdiCached}}</v-icon>
									</v-btn>
								</template>
								<span>Reset Code</span>
							</v-tooltip>
              <v-tooltip left>
								<template v-slot:activator="{on, attrs}">
									<v-btn icon :color="autoCompleteColor" large v-bind="attrs" v-on="on">
										<v-icon class="text-right" @click="toggleAutocomplete">{{mdiToggle}}</v-icon>
									</v-btn>
								</template>
								<span>Auto complete</span>
							</v-tooltip>
              <v-tooltip left>
								<template v-slot:activator="{on, attrs}">
									<v-btn icon :color="closingBracketsColor" large v-bind="attrs" v-on="on">
										<v-icon class="text-right" @click="toggleBracket">{{mdiCodeParentheses}}</v-icon>
									</v-btn>
								</template>
								<span>Auto closing bracket</span>
							</v-tooltip>
              <v-tooltip left>
								<template v-slot:activator="{on, attrs}">
									<v-btn icon color="gray" large v-bind="attrs" v-on="on">
										<v-icon class="text-right" @click="toggleSettings">{{mdiAccountCogOutline}}</v-icon>
									</v-btn>
								</template>
								<span>Settings</span>
							</v-tooltip>
						</v-col>
          </v-row>
        </v-sheet>
        <v-divider></v-divider>
        <v-row>
          <v-col class="py-0 mt-1" >
            <CodeMirror
              ref="myCm"
              :value="value"
              @ready="onCmReady"
              @input="onCodeChange"
              :options="cmOptions"
              :class="fontSize"
            ></CodeMirror>
          </v-col>
        </v-row>
      </v-card>
      <v-card>
        <v-snackbar v-model="snackbar">
          Please stay only on the coding tab during the test !
          <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { CodeMirror as CodeMirrorObject} from "vue-codemirror";
import { codemirror as CodeMirror } from "vue-codemirror";
import "codemirror/mode/python/python.js";
import "codemirror/mode/clike/clike.js";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/theme/dracula.css";
import "codemirror/theme/solarized.css";
import "codemirror/theme/idea.css";
import "codemirror/keymap/emacs.js";
import "codemirror/keymap/vim.js";
import "codemirror/keymap/sublime.js";
import "codemirror/lib/codemirror";
import "codemirror/addon/comment/comment.js";
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/search/search';
import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/edit/closebrackets';
import "codemirror/mode/sql/sql.js";

import '@/addons/cpp-hint';
import '@/addons/java-hint';
import '@/addons/kotlin-hint';
import '@/addons/python-hint';

import { mdiCached, mdiLightbulb, mdiAccountCogOutline, mdiCodeParentheses } from '@mdi/js'

export default {
  data() {
    return {
      mdiCached: mdiCached,
      mdiToggle: mdiLightbulb,
      mdiAccountCogOutline: mdiAccountCogOutline,
      mdiCodeParentheses: mdiCodeParentheses,
      tempClipboardData: undefined,
      autoCompleteColor: "yellow",
      closingBracketsColor: "yellow",
      enableAutocomplete: true,
      autoCloseBrackets: true,
      showSettings: false,
      tabSize: 4,
      theme: "default",
      language: null,
      keyMap: "default",
      fontSize: "normal",
      snackbar: false,
      languages: [
        { text: "C++", value: "CPP_17" },
        { text: "Java", value: "JAVA_11" },
        { text: "Kotlin", value: "KOTLIN_1_3" },
        { text: "Python", value: "PYTHON_3" },
        { text: "MySQL 8", value: "MY_SQL_8" },
        { text: "JavaScript", value: "JAVASCRIPT" },
      ],
      allowedLanguages: [
        { text: "C++", value: "CPP_17" },
        { text: "Java", value: "JAVA_11" },
        { text: "Kotlin", value: "KOTLIN_1_3" },
        { text: "Python", value: "PYTHON_3" },
        { text: "JavaScript", value: "JAVASCRIPT" },
      ],
      curLanguages: [],
      themes: [
        { text: "Idea", value: "idea" },
        { text: "Dracula", value: "dracula" },
        { text: "Solarized Dark", value: "solarized dark" },
        { text: "Solarized Light", value: "solarized light" },
        { text: "Default", value: "default" },
      ],
      keyMaps: [
        { text: "Vim", value: "vim" },
        { text: "Emacs", value: "emacs" },
        { text: "Sublime", value: "sublime" },
        { text: "Basic", value: "default" },
      ],
      fontSizes: [
        { text: "Small", value: "small" },
        { text: "Normal", value: "normal" },
        { text: "Large", value: "large" },
      ],
      tabSizes: [
        { text: "2 Spaces", value: 2 },
        { text: "4 Spaces", value: 4 },
      ],
      cmModeMap: {
        "CPP_17": "text/x-c++src",
        "JAVA_11": "text/x-java",
        "KOTLIN_1_3": "text/x-kotlin",
        "PYTHON_3": "text/x-python",
        "MY_SQL_8": "text/x-mysql",
        "JAVASCRIPT": "text/javascript",
      },
      cmHintModeMap: {
        "CPP_17": CodeMirrorObject.hint.cpp,
        "JAVA_11": CodeMirrorObject.hint.java,
        "KOTLIN_1_3": CodeMirrorObject.hint.kotlin,
        "PYTHON_3": CodeMirrorObject.hint.python,
      }
    };
  },
  props: {
    value: {
      type: String,
      required: false
    },
    // Enable this will trigger the procter
    hiringTestMode: {
      type: Boolean,
      required: false,
      default: false
    },
    evaluationType: {
      type: Number,
      required: false,
    }
  },
  components: {
    CodeMirror
  },
  created() {
    if (this.hiringTestMode) {
      this.detectFocusOut();
    }
  },
  computed: {
    ...mapState("user", ["userPreference", "user"]),
    ...mapGetters("user", ["languageKeys"]),
    codemirror() {
      return this.$refs.myCm.codemirror;
    },
    editorConfig() {
      return this.userPreference && this.userPreference.getEditorConfig();
    },
    cmOptions() {
      console.log("cmOptions change...", this.language);
      return {
        tabSize: this.tabSize,
        indentUnit: this.tabSize,
        indentWithTabs: true,
				smartIndent: true,
        mode: this.cmModeMap[this.language],
        autoCloseBrackets: this.autoCloseBrackets,
        lineNumbers: true,
        line: true,
        styleActiveLine: true,
        matchBrackets: true,
        lineWrapping: true,
        theme: this.theme,
        foldGutter: true,
        keyMap: this.keyMap,
        extraKeys: {
          'Ctrl-/': 'toggleComment',
          'Cmd-/' : 'toggleComment'
        }
      };
    },
  },
  watch: {
    editorConfig: function(config) {
      this.syncConfig(config);
    },
    defaultCode: function(newDefaultCode) {
      this.value = newDefaultCode;
    },
    language: function(newLanguage) {
      this.$emit("setLanguage", newLanguage);
    },
    evaluationType: function(newEvType) {
      console.log("EV type ...///", newEvType)
      if(newEvType === 1) return [this.languages[4]];
      return this.allowedLanguages;  
    },
    curLanguages: function(newList) {
      console.log("curLanguages...", newList);
      if(newList && newList.length > 0) {
        this.language = newList[0].value;
      }
    }
  },
  methods: {
    ...mapMutations("user", ["setEditorConfig"]),
    ...mapActions("user", ["saveUserPreference"]),
    syncConfig(config) {
      if(!config) return;
      if(this.evaluationType === 1) {
        this.language = this.languages[4].value;
      }
      else {
       this.language = config.getLanguage() != 0? this.languageKeys[config.getLanguage()] : "CPP_17";
      }
      this.tabSize = config.getTabSize() || 4;
      this.theme = config.getTheme() || "default";
      this.keyMap = config.getKeyMap() || "default";
      this.fontSize = config.getFontSize()  || "normal";
    },
    onCmReady(cm) {
      console.log("Codemirror is ready !");
      cm.on('keypress', (cm, e) => {
        if (this.enableAutocomplete) {
          cm.showHint({completeSingle: false, hint: this.cmHintModeMap[this.language]});
        }
      });
      cm.on('copy', (cm, event) => {
        this.tempClipboardData = window.getSelection().toString();
      });
      cm.on('paste', (cm, event) => {
        if (this.blockExternalPaste) {
          if (this.tempClipboardData == "empty" || this.tempClipboardData == event.clipboardData.getData("text")) {
            console.log("Legit copy");
          } else {
            console.log("Illegal copy from external");
            event.preventDefault();
          }
        }
      });
      cm.on('copy', (cm, e) => {
        this.tempClipboardData = window.getSelection().toString();
      });
      cm.on("paste", (cm, e) => {
        let pasteContent = e.clipboardData.getData("text");
        pasteContent = pasteContent.replaceAll('\r','');
        if (this.hiringTestMode && pasteContent !== this.tempClipboardData) {
          console.log("Pasting from external source is prohibited");
          e.preventDefault();
        }
      })
    },
    onCodeChange(newValue) {
      this.$emit('input', newValue);
    },
    saveEditorConfig() {
      this.setEditorConfig({
        language: this.language,
        tabSize: this.tabSize,
        theme: this.theme,
        keyMap: this.keyMap,
        fontSize: this.fontSize,
      });
      if (this.user) {
        this.saveUserPreference();
      }
    },
		removeCode() {
			this.$emit("resetCode");
		},
    toggleAutocomplete() {
      this.enableAutocomplete = !this.enableAutocomplete;
      this.autoCompleteColor = (this.enableAutocomplete ? "yellow" : "gray");
    },
    toggleSettings() {
      this.showSettings = !this.showSettings;  
    },
    toggleBracket() {
      this.autoCloseBrackets = !this.autoCloseBrackets;
      this.closingBracketsColor = (this.autoCloseBrackets ? "yellow" : "gray");
    },
    detectFocusOut() {
      const onWindowFocusChange = (e) => {
        if (!document.hasFocus()) {
          this.snackbar = true;
        }
      };
      window.addEventListener('focus', onWindowFocusChange);
      window.addEventListener('blur', onWindowFocusChange);
      window.addEventListener('pageshow', onWindowFocusChange);
      window.addEventListener('pagehide', onWindowFocusChange);
    },
    
    languageGuard() {
      console.log("Eva type is ..", this.evaluationType)
      if(this.evaluationType === 1) {
         return [this.languages[4]];
      }
      return this.allowedLanguages;  
    }
  },
  mounted() {
    this.$emit("setLanguage", this.language);
    this.syncConfig(this.editorConfig);
    this.curLanguages = this.languageGuard();
  }
};

</script>
<style module>
   @import '../assets/css/codemirror.css';
</style>
