<template>
  <v-card class="pl-5 pr-2 py-5">
    <html-view :html="view.getStatementView()"></html-view> 
  </v-card>
</template>

<script>
import HtmlView from "../components/HtmlView";

export default {
  props: {
    "contentView": {
      type: Object,
      required: true,
    },
  },
  name: "articleView",
  computed: {
    view() {
      return this.contentView.getDataView().getArticleView();
    },
  },
  components: {
    HtmlView,
  }
};
</script>
<style scoped>
</style>
