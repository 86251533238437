var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-row', [_c('v-col', [_vm._v(" See insights into your code and tutorials to beat the errors ")])], 1), _c('v-row', _vm._l(_vm.dashboardView.getCodeAnalysisList(), function (analysis) {
    return _c('v-col', {
      key: analysis,
      attrs: {
        "cols": "3"
      }
    }, [_c('v-card', {
      attrs: {
        "outlined": "",
        "width": 270,
        "height": 100
      }
    }, [_c('div', {
      staticClass: "d-flex flex-no-wrap"
    }, [_c('v-avatar', {
      attrs: {
        "size": "50",
        "tile": ""
      }
    }, [_c('v-img', {
      attrs: {
        "src": require('./../assets/images/drill/' + analysis.getDrillPreview().getContentPreview().getTitle() + '.svg')
      }
    })], 1), _c('div', [_c('v-card-title', {
      staticClass: "text-caption-1"
    }, [_vm._v(" " + _vm._s(analysis.getDrillPreview().getContentPreview().getTitle()))]), _c('v-card-subtitle', [_vm._v(" " + _vm._s(analysis.getUserLevel()) + " ")])], 1)], 1)])], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }