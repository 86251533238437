var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pl-5 pr-2 py-5"
  }, [_c('html-view', {
    attrs: {
      "html": _vm.view.getStatementView()
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }