<template>
<span>
	<v-tabs :vertical="$vuetify.breakpoint.smAndDown"  color="accent" v-model="tab">
		<v-tab>Overview</v-tab>
		<v-tab v-if="teamContest">My Team</v-tab>
		<v-tab v-if="teamContest">All Contest Teams</v-tab>
	</v-tabs>

	<v-tabs-items v-model="tab">
		<v-tab-item>
			<v-row>
				<v-col>
					<problem-list live :urlPrefix="'/contests/' + this.contentView.getUrl()" :problems="problems" :showOption="false" showIds></problem-list>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<div class="display-1">About</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col :cols="11">
						<v-card flat outlined class="pl-5 py-3">
							<div class="d-flex flex-no-wrap">
								<div class="d-flex align-center">
									<html-view :html="view.getAboutHtml()"></html-view>
								</div>
							</div>
						</v-card>
				</v-col>
			</v-row>
		</v-tab-item>
		<v-tab-item>
			<v-row v-if="!user">
				<v-col>
					Please <router-link to="/community/login"> login </router-link> to create team
				</v-col>
			</v-row>
			<v-row v-else-if="userContestTeams && userContestTeams.length == 0">
				<v-col>
					You are not member of any team
				</v-col>
			</v-row>
			<v-row v-else-if="!acceptedTeam">
				<v-col>
					No Accepted Invitation
				</v-col>
			</v-row>
			<v-row v-if="acceptedTeam">
				<v-col>
					<team-detail :teamView="acceptedTeam"></team-detail>
				</v-col>
			</v-row>
		</v-tab-item>
		<v-tab-item>
			<v-row>
				<v-col>
					<teams-list :contestId="contentView.getId()" :contestUrl="contentView.getUrl()"></teams-list>
				</v-col>
			</v-row>
		</v-tab-item>
	</v-tabs-items>
	</span>
</template>

<script>
import HtmlView from "../components/HtmlView";
import ProblemList from "../components/ProblemList"
import TeamsList from "../components/TeamsList";
import TeamDetail from "../components/TeamDetail";
import { mapActions, mapState} from "vuex";

export default {
	data: function() {
		return {
			tab: null,
		}
	},
  props: {
    contentView: {
      type: Object,
      required: true
    },
  },

 methods: {
    ...mapActions("content", ["fetchContentView"]),
	},
	computed: {
    ...mapState("timesync", ["serverTime"]),
    ...mapState('user', ['team', 'userContestTeams', 'user']),
		view() {
			return this.contentView.getDataView().getContestView();
		},
		problems() {
			return this.contentView.getChildPreviewsList();
		},
		teamContest() {
			return this.contentView.getMetaData()
                .getContestMeta()
                .getContestType() == 1;
		},
		acceptedTeam() {
			return this.userContestTeams && this.userContestTeams
                      .find(t => {
												var isAccepted = t.getMembersList()
																.find(u => u.getEmail() === this.user.email).getMembershipState() == 2;
																console.log(t.toObject(), isAccepted);
																return isAccepted;
											});
		},
	},

	components: {
    HtmlView,
	  ProblemList,
		TeamsList,
		TeamDetail
	}
};
</script>
TeamsView
<style scoped>
.v-tabs--vertical > .v-tabs-bar .v-tab {
    min-width: 100%;
}
</style>
