<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card>
          <v-card-title class="body-1 accent white--text">
            Login / Signup
          </v-card-title>
          <v-card-text class="pt-2">
            <div id="firebase-ui"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "logout",
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("recruiter", ["recruiter"]),
    ...mapState("customer", ["customer"]),
  },
  methods: {
    ...mapActions("user", ["logout"]),
    ...mapMutations("recruiter", ["resetRecruiterState"]),
    ...mapMutations("customer", ["resetCustomerState"]),
  },
  mounted() {
    if (this.customer || this.recruiter) {
      this.resetCustomerState();
      this.resetRecruiterState();
      this.logout().then((__) => this.$router.push("/"));
    }
    if (this.user) {
      this.logout().then((__) => this.$router.push("/community"));
    } else {
      this.$router.push("/");
    }
  },
};
</script>

<style scoped></style>
